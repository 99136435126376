import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { KcBackendService } from '../konva-canvas/kc-backend.service';
import { PackService } from '../services/PackService';
import { PdfAnnotationService } from '../services/PdfAnnotationService';
import { PageContext } from './PageAnnotationResolver';

@Injectable()
export class IntroductionViewResolver {
  packData: PRM.IpadPackage;
  annotation: string;
  constructor(protected packService: PackService, protected kcService: KcBackendService, private pageAnnotationService: PdfAnnotationService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.packData = await this.packService.getCurrentPackage();
    const selectedDocObject = this.packData.introDocs.find(doc => doc._id === route.params.selecteddoc);
    const pageContext: PageContext = {
      page: 0,
      docid: selectedDocObject._id,
      timeblockid: "introduction",
      participantid: "-1"
    }

    const docResolverResult = {
      selectedDocObject: selectedDocObject,
      pageContexts: [],
      konvaJsons: [],
      annotations: [],
      pack: this.packData
    }

    if (pageContext.docid != null) {
      const availableJsonPages: any = await this.kcService.getAvailableJsonOverlayPageNumbers(pageContext);
      const availableAnnotationPages: any = await this.pageAnnotationService.getAvailableAnnotationTextPageNumbers(pageContext.docid, pageContext.timeblockid, pageContext.participantid);
      for (let i = 0; i < docResolverResult.selectedDocObject.metadata.pagescount; i++) {
        pageContext.page = i

        docResolverResult.pageContexts.push({
          page: i,
          docid: pageContext.docid,
          timeblockid: "introduction",
          participantid: "-1"
        });

        if (availableJsonPages.indexOf(i.toString()) > -1) {
          const konvaJson: any = await this.kcService.loadJson(pageContext);
          docResolverResult.konvaJsons.push(konvaJson);
        } else {
          docResolverResult.konvaJsons.push({});
        }

        if (availableAnnotationPages.indexOf(i.toString()) > -1) {
          const annotationresult: any = await this.pageAnnotationService.getAnnotationText(pageContext.docid, i, pageContext.timeblockid, pageContext.participantid);
          docResolverResult.annotations.push(annotationresult.value);
        } else {
          docResolverResult.annotations.push('');
        }
      }
    }

    return docResolverResult;
  }
}