import { Component, Input } from '@angular/core';
import { PRM } from '@reflact/prmacweb';
import { AuthService } from '../../auth/auth.service';
import { LayoutService } from '../../shared/services/layout.service';

@Component({
  selector: 'app-summary-timeblock',
  templateUrl: './summary-timeblock.component.html',
  styleUrls: ['./summary-timeblock.component.css']
})
export class SummaryTimeblockComponent {
  @Input() public event: any;
  @Input() public epanded = true;
  @Input() public mycomments;


  constructor(public layoutService: LayoutService, public authService: AuthService) { }

  counter(i: number) {
    return new Array(i);
  }

  public getRouterLinkToSingleDocView(event: PRM.Event, doc: PRM.Document) {
    const timeblockId = event._id;
    const participantId = event.resourceId;
    const timeblockDay = event.day;
    const r = ['/observer', 'documentview', timeblockDay, timeblockId, 'docs', doc._id, '0'];
    if (doc.metadata.participantInstanceEnabled) {
      r.push(participantId);
    }
    return r;
  }
}