import { Component, Input } from '@angular/core';
import { PRM } from '@reflact/prmacweb';
import { LayoutService } from '../../../shared/services/layout.service';
import Participant = PRM.Participant;

@Component({
  selector: 'app-user-image-and-name-display',
  templateUrl: './user-image-and-name-display.component.html',
  styleUrls: ['./user-image-and-name-display.component.css']
})
export class UserImageAndNameDisplayComponent {
  @Input() public participant: PRM.IpadParticipant;
  @Input() public showName = true;

  constructor(public layoutService: LayoutService) { }
}