import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UploadState, UploadxOptions, UploadxService } from 'ngx-uploadx';
import { Observable, firstValueFrom } from 'rxjs';
import { AuthService } from '../../auth/auth.service';
import { SocketService } from '../SocketService';

@Injectable({
  providedIn: 'root'
})
export class VideoService {
  userroleType: string;
  public events: Observable<UploadState>;

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private socketService: SocketService,
    private uploadService: UploadxService,
    private router: Router
  ) {
    if (router.url.includes('observer')) {
      this.userroleType = 'observer';
    } else if (router.url.includes('participant')) {
      this.userroleType = 'participant';
    }
    this.userroleType = 'participant';

    this.events = this.uploadService.events;
  }

  public uploadParticipantVideo(video, videotype, videolength, timeblockid) {
    const options: UploadxOptions = {
      endpoint: '/api/upload/' + this.userroleType + 'video',
      autoUpload: true,
      metadata: {
        ipadid: this.authService.getAuthData().iPadId,
        participantpin: this.authService.getAuthData().pin,
        timeblockid,
        videotype,
        videolength
      }
    };

    this.uploadService.handleFiles(video, options);
  }

  public deleteParticipantVideo(videoid: string) {
    const url = '/api/' + this.userroleType + 'video/' + videoid;
    return firstValueFrom(this.http.delete(url));
  }

  public inviteAndDeleteParticipantForFeedback(videoid: string, peerparticipantid: string, inviting: boolean): Promise<any> {
    const url = '/api/' + this.userroleType + 'video/peerparticipant/' + videoid + '/' + peerparticipantid;
    const body = '';
    if (inviting) {
      return firstValueFrom(this.http.post(url, body));
    } else if (!inviting) {
      return firstValueFrom(this.http.delete(url));
    }
  }

  public getAllParticipantVideos() {
    const url = '/api/' + this.userroleType + 'video/myvideos';
    return firstValueFrom(this.http.get(url));
  }

  public getAllFeedbackVideos() {
    const url = '/api/' + this.userroleType + 'video/myfeedbacks';
    return firstValueFrom(this.http.get(url));
  }

  public playVideo(videoid: string) {
    const url = '/api/' + this.userroleType + 'video/getvideo/' + videoid + "?webapptoken=" + this.authService.getWebAppToken();
    return url;
  }

  public saveRatingForVideoId(videoid: string, value: number, ratingComment: string) {
    const url = '/api/' + this.userroleType + 'result/videofeedback';
    const body = { videoid, ratingComment };
    return firstValueFrom(this.http.post(url, body));
  }

  public updateInvitationMessage(videoId: string, message: string) {
    const url = '/api/participantvideo/updateinvitationmessage';
    const body = {
      videoId,
      message
    };

    return firstValueFrom(this.http.post(url, body));
  }

}
