import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { KcBackendService } from '../konva-canvas/kc-backend.service';
import { PackService } from '../services/PackService';
import { PdfAnnotationService } from '../services/PdfAnnotationService';
import { LayoutService } from '../services/layout.service';
import { DocumentviewResolver } from './DocumentviewResolver';

export interface PageContext {
  page: number;
  docid: string;
  timeblockid: string;
  participantid?: string;
}

@Injectable()
export class PageAnnotationResolver extends DocumentviewResolver implements Resolve<any> {

  constructor(protected packService: PackService,
    private pageAnnotationService: PdfAnnotationService,
    private kcService: KcBackendService,
    layoutService: LayoutService) {
    super(packService, layoutService);
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const docResolverResult: any = await super.resolve(route, state);
    let timeblock;
    if (docResolverResult.selectedeventobject) {
      timeblock = docResolverResult.selectedeventobject.timeblock._id;
    }
    let doc;
    if (docResolverResult.selectedDocObject) {
      doc = docResolverResult.selectedDocObject._id;
    }

    const pageContext: PageContext = {
      page: 0,
      docid: doc,
      timeblockid: timeblock,
      participantid: route.params.userid
    }
    if (!route.params.userid) {
      pageContext.participantid = '-1';
    }

    if (docResolverResult.selectedDocObject) {
      if (!docResolverResult.selectedDocObject.metadata.participantInstanceEnabled) {
        pageContext.participantid = null;
      }
    }

    docResolverResult.pageContexts = [];
    docResolverResult.konvaJsons = [];
    docResolverResult.annotations = [];

    if (!!pageContext.docid && !!pageContext.timeblockid) {
      const availableJsonPages: any = await this.kcService.getAvailableJsonOverlayPageNumbers(pageContext);
      const availableAnnotationPages: any = await this.pageAnnotationService.getAvailableAnnotationTextPageNumbers(pageContext.docid, pageContext.timeblockid, pageContext.participantid);
      for (let i = 0; i < docResolverResult.selectedDocObject.metadata.pagescount; i++) {
        pageContext.page = i

        docResolverResult.pageContexts.push({
          page: i,
          docid: pageContext.docid,
          timeblockid: pageContext.timeblockid,
          participantid: pageContext.participantid
        });

        if (availableJsonPages.indexOf(i.toString()) > -1) {
          const konvaJson: any = await this.kcService.loadJson(pageContext);
          docResolverResult.konvaJsons.push(konvaJson);
        } else {
          docResolverResult.konvaJsons.push({});
        }

        if (availableAnnotationPages.indexOf(i.toString()) > -1) {
          const annotationresult: any = await this.pageAnnotationService.getAnnotationText(pageContext.docid, i, pageContext.timeblockid, pageContext.participantid);
          docResolverResult.annotations.push(annotationresult.value);
        } else {
          docResolverResult.annotations.push('');
        }
      }
    }

    return docResolverResult;
  }
}