import { ViewportScroller } from '@angular/common';
import { AfterContentInit, AfterViewInit, Component, HostListener, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { waitSeconds } from '@reflact/tsutil';
import { AuthService } from '../../auth/auth.service';
import { KonvaCanvasComponent } from '../konva-canvas/konva-canvas.component';
import { PageContext } from '../resolver/PageAnnotationResolver';
import { PdfAnnotationService } from '../services/PdfAnnotationService';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-document-content-renderer-single-doc',
  templateUrl: './document-content-renderer-single-docold.component.html',
  styleUrls: ['./document-content-renderer-single-docold.component.css'],
})
export class DocumentContentRendererSingleDocoldComponent implements AfterViewInit, AfterContentInit {
  public currentPage: number = 0
  public selectedDocObject: PRM.Document;
  public editAreaVisible = false;
  public pageContexts: PageContext[] = [];
  public bindedAnnotations: string[] = [''];
  public debufStoreMap: Map<string, string> = new Map<string, string>();
  public debufInterval: any;
  public konvaJsons: object[] = [];
  public myUser: PRM.Observer | PRM.Participant;
  public docZoom: number = 90;
  public docIsReady = false;
  public loadedPages = 0;

  // ZOOM
  private lastCenter: any = false;
  private halfScreenHeight = 0;
  private lastDist: any = false;
  public lastEmitTimestamp: number = 0;
  public storedDifferece = 0;

  public startTouchX = 0;
  public startTouchY = 0;
  public storedScrollTop = 0;
  public storedAllPagesWrapperHeight = 0;
  public newMagicNumber = 0;

  public zoomStep = 5;
  public minzoom = 30;
  public maxzoom = 150;
  @ViewChildren(KonvaCanvasComponent) allCanvas: QueryList<KonvaCanvasComponent> = new QueryList<KonvaCanvasComponent>();
  public _allCanvas: QueryList<KonvaCanvasComponent> = new QueryList<KonvaCanvasComponent>();

  constructor(
    public scroller: ViewportScroller,
    public aRoute: ActivatedRoute,
    public router: Router,
    public layoutService: LayoutService,
    public authService: AuthService,
    public annotationService: PdfAnnotationService
  ) {


    if(this.isIpad()) {
      this.maxzoom = 100;

    }
    aRoute.data.subscribe((data) => {
      this.konvaJsons = data.pack.konvaJsons;
      this.selectedDocObject = data.pack.selectedDocObject;
      this.pageContexts = data.pack.pageContexts;
      this.bindedAnnotations = data.pack.annotations;
      this.myUser = data.pack.pack.me;
    });
  }

  isIpad() {
    return navigator.maxTouchPoints == 5;
  }


  ngAfterContentInit(): void {
    this._allCanvas = this.allCanvas;
  }

  ngAfterViewInit(): void {

    if (!this.selectedDocObject.metadata.editable) {
      this.allCanvas.forEach((k) => {
        k.pencilMode = false;
      })
    }
  }

  public getPagePreviewUrl(documentId, pageNo, timeblockId, participantId) {
    let url = '/api/pagepreview/' + documentId + '/' + pageNo + '/';
    if (timeblockId) {
      url += timeblockId;
    } else {
      url += 'introduction';
    }
    url += '/'
    if (participantId) {
      url += participantId;
    } else {
      url += '-1';
    }
    return url;
  }

  @HostListener('touchstart', ['$event'])
  handleTouchStart(event: TouchEvent): void {
    if (event.touches.length == 2) {
      this.halfScreenHeight = window.innerHeight / 2;
      this.startTouchX = event.touches[0].clientX;
      this.startTouchY = event.touches[0].clientY;
      let scrollingElement = document.getElementById("scrollingTag");
      this.storedAllPagesWrapperHeight = document.getElementById("allPagesWrapper").offsetHeight;
      this.storedScrollTop = scrollingElement.scrollTop;
      let st = scrollingElement.scrollTop;
      this.newMagicNumber = (st + this.halfScreenHeight) / this.storedAllPagesWrapperHeight;

      // zoomToolActive zeigt den Screenshot
      this.allCanvas.forEach((k) => {
        k.showSnapshot = true;
      })
    }
  }

  @HostListener('touchmove', ['$event'])
  handleTouchMove(event: TouchEvent): void {
    if (this.getIsLoading()) { return; }
    if (event.touches.length == 2) {
      this.handleZoom(event);
    }
  }

  @HostListener('touchend', ['$event'])
  handleTouchEnd(event: TouchEvent): void {
    this.updateAllStages();
    this.lastDist = null;
  }

  public getIsLoading() {
    return this.pageContexts.length != this.loadedPages;
  }

  public changePencilMode(e) {
    this.allCanvas.forEach(k => {
      k.pencilMode = e;
    });
  }

  public getSelectedCanvas() {
    if (this.allCanvas) {
      return this.allCanvas.get(0)
    } else {
      return undefined;
    }
  }

  public scrollTo(tagId: number) {
    if (tagId < 0) {
      return;
    }
    if (this.selectedDocObject.metadata.pagescount <= tagId) {
      return;
    }
    this.currentPage = tagId;
    document.getElementById('page_' + tagId).scrollIntoView({
      behavior: "auto",
      block: "start",
      inline: "nearest"
    });
    document.getElementById("headernavbar").style.display = "none";

    setTimeout(() => {
      document.getElementById("headernavbar").style.display = "flex";
    }, 1)
  }

  getDistance(p1, p2) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  getCenter(p1, p2) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  }

  handleZoom(e) {
    let touch1 = e.touches[0];
    let touch2 = e.touches[1];
    if (touch1 && touch2) {
      let p1 = { x: touch1.clientX, y: touch1.clientY };
      let p2 = { x: touch2.clientX, y: touch2.clientY };
      if (!this.lastCenter) {
        this.lastCenter = this.getCenter(p1, p2);
        return;
      }
      let newCenter = this.getCenter(p1, p2);
      let dist = this.getDistance(p1, p2);
      if (!this.lastDist) { this.lastDist = dist; }
      let difference = (this.lastDist - dist);
      this.lastDist = dist;
      this.lastCenter = newCenter;
      this.emitWithDelay(difference);
    }
  }

  emitWithDelay(difference: number): void {
    this.storedDifferece += difference;

    this.onZoomChanged(this.storedDifferece);
    const now = Date.now();
    const timeDifference = now - this.lastEmitTimestamp;
    if (timeDifference >= 20) { // Check if at least 2 seconds have passed
      this.onZoomChanged(this.storedDifferece);
      this.storedDifferece = 0;
      this.lastEmitTimestamp = now; // Update the timestamp of the last emit
    }
  }

  public closeEditor() {
    const targetUrl = this.router.url.split('/docs')[0] + '/docs';
    this.router.navigateByUrl(targetUrl);
  }

  public onBlur() {
    clearInterval(this.debufInterval);
    this.storeDebuffed();
  }

  public cbtest(str) {
    clearInterval(this.debufInterval);
    this.bindedAnnotations[this.currentPage] = str;
    this.debufStoreMap.set(JSON.stringify(this.pageContexts[this.currentPage]), str);
    this.debufInterval = setTimeout(this.storeDebuffed.bind(this), 2000);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.updateAllStages();
    return;
  }

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    return;
  }

  public getContentAreaHeight() {
    return window.innerHeight - this.layoutService.headerHeightInt;
  }
  public getContentAreaWidth() {
    return window.innerWidth;
  }

  public getContentAreaOrientation(): 'landscape' | 'portrait' {
    if (this.getContentAreaWidth() > this.getContentAreaHeight()) {
      return 'landscape';
    }
    return 'portrait';
  }

  public async updateAllStages() {
    let stageWith = document.getElementById("allPagesWrapper").offsetWidth;
    for (const k of this.allCanvas.toArray()) {
      await waitSeconds(0.010);
      k.setStageSite(stageWith);
      k.showSnapshot = false;
    }
  }

  public zoomOut() {
    if (this.docZoom <= this.minzoom) { return }
    this.docZoom -= this.zoomStep;
    this.docZoom = Math.max(this.minzoom, this.docZoom);
    document.getElementById("touchMarker").style.width = this.docZoom + "%";
    document.getElementById("allPagesWrapper").style.width = this.docZoom + "%";
    this.updateAllStages();
  }

  public zoomIn() {
    if (this.docZoom >= this.maxzoom) { return }
    this.docZoom += this.zoomStep;
    this.docZoom = Math.min(this.maxzoom, this.docZoom);
    document.getElementById("touchMarker").style.width = this.docZoom + "%";
    document.getElementById("allPagesWrapper").style.width = this.docZoom + "%";
    this.updateAllStages();
  }

  public zoomTo(newZoom) {
    this.docZoom = newZoom;
    this.docZoom = Math.min(this.maxzoom, this.docZoom);
    document.getElementById("touchMarker").style.width = this.docZoom + "%";
    document.getElementById("allPagesWrapper").style.width = this.docZoom + "%";
    this.updateAllStages();
  }

  // !!! DIE SEITEN ÜBERSCHRIFTEN IMMER SO WEIT WIE DER TOUCHMARKER IST
  public onZoomChanged(differencePX: number) {
    // !!! NEUE BREITE AUSRECHNEN UND CHECKEN OB SIE ZU BREIT
    // !!! ODER ZU KLEIN WIRD
    // TOUCH MARKER BREITE IN PX
    let currentWidtPX = document.getElementById("touchMarker").offsetWidth;
    const tmPercentageWidthStr = document.getElementById("touchMarker").style.width;
    let tmPercentageWidthNumber = parseInt(tmPercentageWidthStr.split("%")[0]);
    let newWidthPX = (currentWidtPX - differencePX);
    let percentageGrowth = (newWidthPX / currentWidtPX);
    let newWPercentage = tmPercentageWidthNumber * (percentageGrowth);
    newWPercentage = Math.min(this.maxzoom, newWPercentage);
    newWPercentage = Math.max(this.minzoom, newWPercentage);
    this.docZoom = newWPercentage;
    document.getElementById("touchMarker").style.width = newWPercentage + "%";
    document.getElementById("allPagesWrapper").style.width = newWPercentage + "%";
    let scrollingElement = document.getElementById("scrollingTag");
    this.storedAllPagesWrapperHeight = document.getElementById("allPagesWrapper").offsetHeight;

    let wrapperHeight = this.storedAllPagesWrapperHeight
    let newSt = this.newMagicNumber * wrapperHeight - this.halfScreenHeight;
    scrollingElement.scrollTop = newSt;
  }

  public async konvaImageChanged() {
    this.loadedPages = this.loadedPages + 1;
    if (this.loadedPages >= this.pageContexts.length) {
      await waitSeconds(0.001);
      this.updateAllStages();
    }
  }

  public storeDebuffed() {
    this.debufStoreMap.forEach((value, pageContextStr, map) => {
      const pageContext: PageContext = JSON.parse(pageContextStr);
      this.annotationService.storeAnnotationText(
        pageContext.docid,
        pageContext.page,
        pageContext.timeblockid,
        pageContext.participantid,
        value
      );
    });
    this.debufStoreMap.clear();
  }

  counter(i: number) {
    return new Array(i);
  }

  public getRouterLinkForPageSwitch(page: number, userid) {
    if (page >= this.selectedDocObject.metadata.pagescount || page < 0) {
      return [];
    }
    if (userid !== undefined) {
      return ['../../', page, userid];
    } else {
      return ['../', page];
    }
  }
}
