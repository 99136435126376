import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

import { PackService } from './services/PackService';

@Injectable({
  providedIn: 'root'
})
export class DisableVideoFeedbackGuard {
  constructor(private packService: PackService, private router: Router) { }
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const pack = await this.packService.getCurrentPackage();
    let resolve = true;
    if (pack.disableVideofeedback === 'deactivated') {
      resolve = false;
      this.router.navigateByUrl('/participant/introduction/docs');
    }
    return resolve;
  }

}
