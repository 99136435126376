<span
  *ngIf="layoutService.nameComponents"
  class="alert alert-info"
>introduction-list.component.html</span>
<div
  appHeightWatcher
  class="mt-3"
>
  <app-document-list
    [documents]="introDocs"
    docsPerRow="6"
  ></app-document-list>
</div>