<ng-template
  #slider
  let-component="component"
>
  <div class="row">
    <div class="col-auto">{{component.props.minValue}}</div>
    <div class="col"></div>
    <div class="col-auto">{{component.props.maxValue}}</div>
  </div>
  <div>
    <span
      class="text-center position-relative d-block"
      style="width: 20px; font-size: smaller;"
      [ngStyle]="{'margin-left': calculatePositionPercentage(component.props.minValue, component.props.maxValue, component.value)+'%', 'left': '-' + calculatePositionPercentage(component.props.minValue, component.props.maxValue, component.value)/100*20+'px'}"
    >{{component.value}}</span>
  </div>
  <input
    [(ngModel)]="component.value"
    (ngModelChange)="onChange()"
    type="range"
    class="custom-range w-100"
    [min]="component.props.minValue"
    [max]="component.props.maxValue"
  >
</ng-template>
<ng-template
  #choice
  let-component="component"
>
  <form>
    <div
      *ngFor="let option of component.props.options; let i = index"
      class="form-check"
    >
      <input
        type="radio"
        [id]="option.id"
        [value]="i"
        [(ngModel)]="component.value"
        [name]="option.id"
        (change)="onChange()"
      >
      <label [for]="option.id">&nbsp; {{option.text}}</label>
    </div>
  </form>
</ng-template>
<ng-template
  #text
  let-component="component"
>
  <quill-editor
    class="w-100"
    [(ngModel)]="component.value"
    (ngModelChange)="this.onChange()"
  ></quill-editor>

</ng-template>
<ng-template #none></ng-template>


<div class=" row text-left ">
  <div class="col-12 ">
    <app-document-tab-buttons
      [allparticipants]="selectedeventobject.participants"
      [aRoute]="aRoute"
      [selectedEvent]="selectedeventobject"
    ></app-document-tab-buttons>
    <div class="row px-4 pt-0">
      <div class="col">
        <div
          *ngFor="let component of survey.components"
          class="card"
        >
          <div
            class="card-header"
            [appLayout]="layoutService.baseColorButtons"
          >{{component.caption}}</div>
          <div class="card-body">
            <ng-container
              *ngTemplateOutlet="component.type === 'slider' ? slider : none; context: {component: component}"
            ></ng-container>
            <ng-container
              *ngTemplateOutlet="component.type === 'choice' ? choice : none; context: {component: component}"
            ></ng-container>
            <ng-container *ngTemplateOutlet="component.type === 'text' ? text : none; context: {component: component}">
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
