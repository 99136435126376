<span
  *ngIf="layoutService.nameComponents"
  class="alert alert-info"
>observer-conference.component.html</span>
<div class=" row p-0 m-0 ">
  <!-- SCEDULE-->

  <div
    class="col-2 p-0  overflow-auto "
    style="border-right: 1px solid #000"
    appHeightWatcher
  >
    <ul
      class="list-group list-group-flush "
      style="cursor: pointer"
    >
      <li
        *ngFor="let p of pack.allparticipants"
        [ngStyle]="{
      'borderRightColor':layoutService.activeColor.backgroundColor,
      'border':'100px sold red !important '
      }"
        [routerLink]="['/observer','conference',p.id]"
        class="list-group-item"
        routerLinkActive="activeTime"
        style="background-color: #F2F2F2"
      >

        <app-user-image-and-name-display [participant]="p"></app-user-image-and-name-display>
      </li>
    </ul>
  </div>
  <!-- CONTENT -->
  <div
    #docdiv
    class="col overflow-auto"
    id="docdiv"
    style="background-color: #fff;"
    appHeightWatcher
  >

    <router-outlet></router-outlet>

  </div>
</div>