import { Component } from '@angular/core';
import { LayoutService } from '../shared/services/layout.service';

@Component({
  selector: 'app-observer-view',
  templateUrl: './observer-view.component.html',
  styleUrls: ['./observer-view.component.css']
})
export class ObserverViewComponent {
  constructor(public layoutService: LayoutService) { }
}