<div
  id="deleteModal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h5
          class="modal-title"
          i18n
        >Wirklich Benutzerdaten löschen?</h5>
      </div>
      <div class="modal-body">
        <p i18n>Gelöschte Daten können nicht wieder hergestellt werden.</p>
        <br>
        <p i18n>Möchten Sie fortfahren?</p>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          i18n
        >Nichts unternehmen</button>
        <button
          type="button"
          class="btn btn-danger"
          (click)="deleteUserdata()"
          data-dismiss="modal"
          i18n
        >Löschen</button>
      </div>
    </div>
  </div>
</div>


<div
  class="p-4 overflow-auto text-center"
  style="background-color: #ffffff"
  appHeightWatcher
>

  <div *ngIf="router.url.includes('participant')">
    <p i18n>Die Ergebnisse der Selbstreflexion per E-Mail senden</p>
    <form
      style="width: 20%; display: inline-block;"
      (ngSubmit)="sendResults(f)"
      #f="ngForm"
    >
      <div class="form-group">
        <label
          for="email"
          i18n
        >E-Mail Adresse</label>
        <input
          type="email"
          class="form-control"
          id="email"
          aria-describedby="email"
          name="email"
          required
          ngModel
          #email="ngModel"
          email
        >
      </div>
      <button
        type="submit"
        class="btn btn-primary d-inline-flex align-items-center"
        i18n
      ><i class="ri-mail-line"></i> Ergebnisse senden</button>
    </form>
    <hr>
  </div>
  <p i18n>Alle Ihre gespeicherten Daten (Notizen, Videoaufzeichnungen, etc.) löschen</p>
  <button
    type="button"
    class="btn btn-danger"
    data-toggle="modal"
    data-target="#deleteModal"
    i18n
  ><i class="ri-question-line"></i> Anwenderdaten löschen</button>
</div>