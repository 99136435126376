<!-- PAGE HEADER -->
<div class="row  pageWrapperHeader  my-2 mt-4" style="margin:0px auto ; position:relative;top:0px ; width: 100%;">
  <!-- <div class="col">{{this.pageContext.page+1}} / {{pagesCount}}</div> -->

</div>

<!-- ENDE PAGE HEADER -->
<!-- PAGE  -->
<div
  [id]="'page_'+this.pageContext.page"
  class="pageWrapper "
>
  <div
    [ngClass]="{'hilightPage': highlight}"
    class="newdocViewPage"
    [id]="'page_mainWrapper_'+this.pageContext.page"
    style=" background-color: #fff; "
    #wrapperElement
  >
    <div
      class="text-center"
      [ngClass]="{ 'd-none': !hideDrawing }"
    >
      <img
        alt="Lädt..."
        src="assets/loader.gif"
        style="margin: 0 auto"
        alt="loading gif"
      />
    </div>
    <img
      #snapshotElement
      style="width: 100%;pointer-events: none; background-color: #fff;opacity: 0.2;"
      [ngClass]="{'d-none': !showSnapshot }"
      [src]="this.imageObj.src"
    >

    <div
      #stageElement
      [ngClass]="{'d-none': showSnapshot }"
    ></div>
  </div>
</div>
