<ng-template #eraserTemplate>
  <table>
    <tr>
      <td *ngFor="let s of sizes">
        <div
          style="background-color: #E5E7EB;border-radius: 50%;border:2px solid #fff"
          (click)="this.pencil.thickness = s"
          [style.borderColor]="this.pencil.thickness == s? layoutService.activeColor.backgroundColor: '#fff'"
        >
          <i
            class="d-flex ri-checkbox-blank-circle-fill"
            [style.color]="pencil.color"
            style="text-shadow: 0 0 4px #c0c0c0;transform: scale({{s/40}});"
          ></i>
        </div>
      </td>
    </tr>
  </table>
</ng-template>
<ng-template #popTemplate>
  <table>
    <tr>
      <td *ngFor="let c of colors">
        <i
          class="ri-checkbox-blank-circle-fill"
          [style.color]="c"
          style="text-shadow: 0 0 4px #c0c0c0;
           -webkit-text-stroke-color: {{layoutService.activeColor.backgroundColor}};
           -webkit-text-stroke-width: {{this.pencil.color == c? '2px' : '0px'}}"
          (click)="this.pencil.color = c"
        ></i>
      </td>
    </tr>
    <tr>
      <td *ngFor="let s of sizes">
        <div
          style="background-color: #E5E7EB;border-radius: 50%;border:2px solid #fff"
          (click)="this.pencil.thickness = s"
          [style.borderColor]="this.pencil.thickness == s? layoutService.activeColor.backgroundColor: '#fff'"
        >
          <i
            class="d-flex ri-checkbox-blank-circle-fill"
            [style.color]="pencil.color"
            style="text-shadow: 0 0 4px #c0c0c0;transform: scale({{s/40}});"
          ></i>
        </div>
      </td>
    </tr>
  </table>

</ng-template>
<span
  [style.backgroundColor]="thisToolIsActive()?layoutService.activeColor.backgroundColor : layoutService.baseColorButtons.backgroundColor"

  style="height: 34px;
        padding-left:10px;
        padding-right:10px;
        border-radius: 17px;
        line-height: 10px;
        min-width: 54px;
        position: relative;
        top:-13px"
  class="d-inline-block"
>
  <i
    [class]="pencil?.icon"
    [style.color]="layoutService.baseColorButtons.foregroundColor"
    style="position: relative; top:5px"
  ></i>
<ng-container>

  <div
    outsideClick="true"
    triggers="click"
    [popover]="pencil?.iseraser ? eraserTemplate: popTemplate"
    style="background-color: #ccc;top:5px ;border-radius: 50%;"
    class="d-inline-block position-relative"
  >
    <span
      [style.backgroundColor]="pencil.color"
      style="
    text-shadow: 0 0 4px #c0c0c0;transform: scale({{pencil?.thickness/40}});
    width: 24px;
     height: 24px;
     border-radius: 12px;
     min-width: 24px;

     display: inline-block;
     position: relative;
        "
    >
    </span>
  </div>
  </ng-container>
</span>
