<div appHeightWatcher>
    <div *ngFor="let p of myExerciseEventsByParticipant | keyvalue">
        <b>
            {{this.participantIdToParticipant.get(p.key).firstname}}
            {{this.participantIdToParticipant.get(p.key).lastname}}
        </b>
        <ul class="list-group">
            <li
                *ngFor="let eee of p.value"
                class="list-group-item"
            >
                <app-moduploadvideo
                    [participant]="this.participantIdToParticipant.get(p.key)"
                    [pack]="this.pack"
                    [ev]="eee"
                >
                </app-moduploadvideo>
            </li>
        </ul>
    </div>
</div>