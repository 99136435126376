import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CountdownModule } from 'ngx-countdown';
import { QuillModule } from 'ngx-quill';
import { UiSwitchModule } from 'ngx-ui-switch';
import { UploadxModule } from 'ngx-uploadx';
import { HeightWatcherDirective } from '../height-watcher.directive';
import { PipesModule } from '../pipes/pipes.module';
import { DayselectiontabsComponent } from '../shared/dayselectiontabs/dayselectiontabs.component';
import { HeaderComponent } from '../shared/header/header.component';
import { IsModeratorGuard } from '../shared/ismoderator.guard';
import { MydayeventslistComponent } from '../shared/mydayeventslist/mydayeventslist.component';
import { TimezonePipe } from '../shared/pipes/timezone.pipe';
import { PreptimeCountdownComponent } from '../shared/preptime-countdown/preptime-countdown.component';
import { SavedIndikatorComponent } from '../shared/saved-indikator/saved-indikator.component';
import { LayoutDirective } from '../shared/services/layout.directive';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { WcagComponent } from '../shared/wcag/wcag.component';
import { WcagDirective } from '../shared/wcag/wcag.directive';
import { DocumentTabButtonsComponent } from './document-tab-buttons/document-tab-buttons.component';
import { DocumentViewComponent } from './document-view/document-view.component';
import { ObserverConferenceComponent } from './observer-conference/observer-conference.component';
import { UserImageAndNameDisplayComponent } from './observer-conference/user-image-and-name-display/user-image-and-name-display.component';
import { ObserverRoutingModule } from './observer-routing.module';
import { ObserverViewComponent } from './observer-view.component';
import { PreptimesComponent } from './preptimes/preptimes.component';
import { RatingScaleComponent } from './rating-scale/rating-scale.component';
import { ModuploadvideoComponent } from './upload-videos/moduploadvideo/moduploadvideo.component';
import { UploadVideosComponent } from './upload-videos/upload-videos.component';
import { VideoShareComponent } from './video-share/video-share.component';
import { TimezoneModalComponent } from '../shared/timezone-modal/timezone-modal.component';


@NgModule({
  imports: [
    CommonModule,
    CollapseModule,
    ObserverRoutingModule,
    TooltipModule.forRoot(),
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    FormsModule,
    AccordionModule.forRoot(),
    RatingModule.forRoot(),
    UiSwitchModule,
    UploadxModule,
    QuillModule,
    CountdownModule,
    PipesModule
  ],
  // DINGE DIE das MODUL benötigz
  declarations: [
    LayoutDirective,
    HeightWatcherDirective,
    WcagComponent,
    SidebarComponent,
    HeaderComponent,
    ObserverViewComponent,
    DocumentViewComponent,
    VideoShareComponent,
    ObserverConferenceComponent,
    DayselectiontabsComponent,
    MydayeventslistComponent,
    RatingScaleComponent,
    DocumentTabButtonsComponent,
    WcagDirective,
    UserImageAndNameDisplayComponent,
    PreptimeCountdownComponent,
    SavedIndikatorComponent,
    PreptimesComponent,
    UploadVideosComponent,
    ModuploadvideoComponent,
    TimezonePipe,
    TimezoneModalComponent
  ],
  providers: [
    WcagDirective,
    IsModeratorGuard
  ],
  /*
  DINGE DIE DA MODUL EXPORTIERT
  und bereit stellt für alle dinge die DIES modul
  benutz
  */
  exports: [CollapseModule, DocumentTabButtonsComponent, HeaderComponent, WcagDirective, LayoutDirective, HeightWatcherDirective, UserImageAndNameDisplayComponent]

})
export class ObserverViewModule { }