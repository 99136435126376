import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IsSavedService {
  protected isSaved = true;

  setIsSaved(value: boolean) {
    this.isSaved = value;
  }

  getIsSaved() {
    return this.isSaved;
  }
}