import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { SocketService } from '../shared/SocketService';

interface AuthData {
  pin: string;
  iPadId: string;
  role: string;
  lang: string;
}


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public accesValidationChange: Subject<{ _id: string, accessValidation: 'liveauth' | 'disabled' }> = new Subject();
  public static waitingForValidationSubject: Subject<{ status: 'accept' | 'decline' | 'factor2mail', lang: 'de' | 'en', role: 'moderator' | 'participant' | 'observer', webapptoken: string }>;
  protected authData: AuthData = { pin: '', iPadId: '', role: '', lang: '' };
  private refreshOnNextLoad = true;

  constructor(private router: Router, private socketService: SocketService, public toastr: ToastrService) {
    this.setIpadId(this.genClientId());
    this.loadAuthData();
    this.socketService.socket.on('validatePadIdAndPinResult', this.validatePadIdAndPinResultRecieved);
    this.socketService.socket.on('accessvalidationchanged', this.accessvalidationchanged.bind(this))
  }

  public login(): Subject<{ status: 'accept' | 'decline' | 'factor2mail', lang: 'de' | 'en', role: 'moderator' | 'participant' | 'observer', webapptoken: string }> {
    AuthService.waitingForValidationSubject = new Subject<{ status: 'accept' | 'decline', lang: 'de' | 'en', role: 'moderator' | 'participant' | 'observer', webapptoken: string }>();
    this.socketService.socket.emit('validatePadIdAndPinRequest', { pin: this.getAuthData().pin, iPadId: this.getAuthData().iPadId });
    return AuthService.waitingForValidationSubject;
  }

  public getAuthData(): AuthData {
    return this.authData;
  }

  public logout(queryParams: any = {}) {
    this.refreshOnNextLoad = true;
    this.clearSession();
    this.router.navigate(['/auth'], { queryParams }).then(() => {
      window.location.reload();
    });
  }

  public setWebAppToken(webAppToken: string) {
    const authData = jwtDecode<AuthData>(webAppToken);
    localStorage.setItem('webapptoken', webAppToken);
    this.setAuthData({ iPadId: authData.iPadId, lang: authData.lang, pin: authData.pin, role: authData.role });
  }

  public getWebAppToken(): string {
    return localStorage.getItem('webapptoken');
  }

  public clearSession() {
    this.socketService.socket.emit('logoutWebApp');
    this.setPin('');
    localStorage.removeItem('webapptoken');
    localStorage.removeItem('prmwebapplasturl');
    localStorage.removeItem('timezoneModalClosed');
  }

  public loadAuthData() {
    if (this.hasSession()) {
      this.setWebAppToken(this.getWebAppToken());
    }
  }

  public hasSession() {
    return localStorage.getItem('webapptoken') != null;
  }

  public setAuthData(authData: AuthData) {
    this.authData = authData;
  }

  public setPin(pin: string) {
    this.authData.pin = pin;
  }

  public setIpadId(iPadId: string) {
    this.authData.iPadId = iPadId;
  }

  public doRefreshOnNextLoad(): boolean {
    if (this.refreshOnNextLoad) {
      this.refreshOnNextLoad = false;
      return true;
    }
  }

  public validatePadIdAndPinResultRecieved(data) {
    if (AuthService.waitingForValidationSubject != null) {
      AuthService.waitingForValidationSubject.next(data);
    }
  }

  public accessvalidationchanged(data) {
    this.accesValidationChange.next(data)
  }

  public genClientId(): string {
    return '' + new Date().getMilliseconds() + '_' + Math.round(Math.random() * 1000000);
  }

  public setPrmWebAppLastUrl(url: string) {
    if (url == null) {
      localStorage.removeItem('prmwebapplasturl');
      return;
    }
    const webAppToken = this.getWebAppToken();
    if (webAppToken == null) return;
    const lastUrl = {
      url,
      webAppToken
    };
    localStorage.setItem('prmwebapplasturl', JSON.stringify(lastUrl));
  }

  public getPrmWebAppLastUrl() {
    const lastUrl = localStorage.getItem('prmwebapplasturl');
    if (lastUrl) {
      const lastUrlData = JSON.parse(lastUrl);
      const authData = jwtDecode<AuthData>(lastUrlData.webAppToken);
      return { url: lastUrlData.url, pin: authData.pin, iPadId: authData.iPadId }
    }
    return null;
  }
}