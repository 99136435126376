import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SettingsService } from 'src/app/observer-view/settings/settings.service';
import { AuthService } from '../../auth/auth.service';
import { EndTime, PreptimeService } from '../PreptimeService';
import { SocketService } from '../SocketService';
import { PackService } from '../services/PackService';
import { LayoutService } from '../services/layout.service';

export interface MenuItem {
  label: string;
  text: string;
  routerLink: RouterLink;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @Input() brand: string;
  @Input() brandlink: string;

  menuItems: any[]; // DIESES ARRAY WIRD MIT VERSCHIEDENEN MENUS GEFÜLLT
  @Input() secondlinetext: string;
  @ViewChild('header') headerEl: ElementRef;
  public currentDate: string;
  public currentArea: string;
  myUser: any;
  headerText: string;
  lang: string;
  eventIdToEndTime: Map<string, EndTime>;
  public urlStringToEndTime: Map<string, EndTime>;
  public pack: PRM.IpadPackage;
  public prepTimeDone: EventEmitter<string> = new EventEmitter();
  public isModerator = false;
  public modalRef: BsModalRef;
  public observerMenuItems: any[] = [
    { text: 'ri-file-copy-2-line', routerLink: 'introduction/docs', label: $localize`Information` },
    { text: 'ri-file-copy-2-line', routerLink: ['documentview'], label: $localize`Zeitplan` },
    { text: 'ri-file-copy-2-line', routerLink: ['preptimes'], label: $localize`Vorbereitungszeiten` },
    { text: 'ri-video-chat-line', routerLink: ['videoupload'], label: $localize`TN-Video` },
    { text: 'ri-contacts-line', routerLink: 'conference', label: $localize`Ergebnisse` },
    { text: 'ri-settings-line', routerLink: 'settings', label: $localize`Einstellungen` }
  ];

  public participantMenuItems: any[] = [
    { text: 'ri-file-copy-2-line', routerLink: 'introduction/docs', label: $localize`Information` },
    { text: 'ri-file-copy-2-line', routerLink: ['documentview'], label: $localize`Zeitplan` },
    { text: 'ri-video-chat-line', routerLink: 'videoshare', label: $localize`:@@videoFeedbackText:Video Feedback` },
    { text: 'ri-settings-line', routerLink: 'settings', label: $localize`Einstellungen` }
  ];

  constructor(
    public layoutService: LayoutService,
    private aRoute: ActivatedRoute,
    public socketService: SocketService,
    public router: Router,
    private authService: AuthService,
    private prepService: PreptimeService,
    private settingsService: SettingsService,
    public modalService: BsModalService,
    public packService: PackService,


  ) {
    aRoute.data.subscribe((data) => {
      this.myUser = data.pack.me;
      this.pack = data.pack;
      this.menuItems = this.participantMenuItems;

      this.lang = data.pack.lang;
      if ((this.pack.me as PRM.Observer).role == 'moderator') {
        this.isModerator = true;
        this.menuItems = this.observerMenuItems;
      }

      if ((this.pack.me as PRM.Observer).role == 'observer') {
        this.menuItems = this.observerMenuItems;
      }

    });

    this.authService.accesValidationChange.subscribe(data => {
      this.pack.accessValidation = data.accessValidation
    });

    router.events.subscribe((data) => {
      this.currentArea = router.url.split('/')[2];
    });

    if (router.url.includes('participant')) {
      this.eventIdToEndTime = new Map<string, EndTime>();
      this.urlStringToEndTime = new Map<string, EndTime>();
      console.log("urlstringtoendtime", this.urlStringToEndTime)
      this.preparePrepTimes();
      prepService.mapChanged.subscribe((r) => {
        this.preparePrepTimes();
      });
    }
  }

  async ngOnInit(): Promise<void> {
    const currentDate = dayjs();
    this.currentDate = currentDate.locale(this.lang).format('dd., DD. MMMM YYYY')

    this.pack = await this.packService.getCurrentPackage();
    if ((this.pack.me as PRM.Observer).role == 'moderator') {
      this.isModerator = true;
    }
  }

  ngAfterViewInit(): void {
    this.layoutService.headerHeight =
      this.headerEl.nativeElement.getBoundingClientRect().height + 'px';
  }

  async changeAuthAccess(access: 'liveauth' | 'disabled') {
    await this.settingsService.changeAuthAccess(this.pack._id, access)
  }

  public logout() {
    this.authService.logout();
  }

  public preparePrepTimes() {
    this.eventIdToEndTime = this.prepService.getMap();
    if (this.eventIdToEndTime.size > 0) {
      this.eventIdToEndTime.forEach((value: EndTime, key: string) => {
        const bool = (new Date(value.endtime).getTime() - new Date().getTime() - this.prepService.getDiff()) > 0;
        let url = '/participant/documentview/';
        this.pack.eventdays.forEach((days) => {
          const index = days.events.findIndex((e) => e.id === key);
          if (index > -1) {
            url += days.day + '/' + days.events[index]._id + '/docs';
          }
        });
        if (bool) {
          this.urlStringToEndTime.set(url, value);
        } else {
          this.prepTimeDone.emit(url);
          this.urlStringToEndTime.delete(url);
        }
      });
    }
  }

  async moderatorForceLogout() {
    await this.packService.moderatorForceLogout(this.pack._id);
  }
}