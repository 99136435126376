import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersioningService {
  constructor(private http: HttpClient) { }

  public async getVersions() {
    return await firstValueFrom(this.http.get("/api/version/web"));
  }
}
