import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { SocketService } from '../SocketService';
import { Pack } from './Pack';
import IpadPackage = PRM.IpadPackage;

@Injectable({
  providedIn: 'root'
})
export class PackService {
  private package: BehaviorSubject<Pack> = new BehaviorSubject<Pack>(null);
  private pin: string;
  private iPadId: string;

  constructor(private http: HttpClient, private authService: AuthService, private socketService: SocketService, private toastr: ToastrService, private route: Router) {
    this.socketService.socket.on('feedbackParticipant', this.feedbackParticipantRecieved.bind(this));
    this.socketService.socket.on('moderatorlogout', this.onmoderatorlogout.bind(this));
  }

  public feedbackParticipantRecieved(data) {
    if (data.inviting === true) {
      this.toastr.info(
        'Ein Teilnehmer hat Sie zum Feedback geben eingeladen. Klicken Sie hier, um zum Video zu gelangen.',
        'Neue Feedbackanfrage',
        { closeButton: true, timeOut: 30000 }
      ).onTap.subscribe(() =>


        this.route.navigate(['participant', 'videoshare'])

      );
    }
  }

  public async getCurrentPackage(): Promise<IpadPackage> {
    if (!!this.package.value && !this.authService.doRefreshOnNextLoad()) {
      return this.package.value.getIPadPack();
    }
    await this.fetchPackage();
    try {
      if (window.location.hostname !== 'localhost' && '/webapp/' + this.package.value.getIPadPack().lang + '/' !== window.location.pathname) {
        window.location.pathname = '/webapp/' + this.package.value.getIPadPack().lang + '/';
      }
    } catch (e) { }

    this.socketService.socket.emit('connectSuccess', this.authService.getWebAppToken());
    return this.package.value.getIPadPack();
  }

  public setCurrentPin(pin: string) {
    if (!!this.package.value) {
      this.package.next(null);
    }
    this.pin = pin;
  }

  public getPin(): string {
    return this.pin;
  }

  public getIPadId(): string {
    return this.iPadId;
  }

  private async fetchPackage() {
    const authData = this.authService.getAuthData();
    this.setCurrentPin(authData.pin);
    this.iPadId = authData.iPadId;
    return firstValueFrom(this.http.get<IpadPackage>('/api/ipadpackage')
      .pipe(
        tap(pack => {
          this.package.next(new Pack(pack));
        })
      ));
  }

  async moderatorForceLogout(packid: string) {
    const url = `/api/moderatorlogout/${packid}`
    return firstValueFrom(this.http.post(url, {}));
  }

  public async onmoderatorlogout(data) {
    const pack = await this.getCurrentPackage();
    if ((pack.me as PRM.Observer).role == 'moderator') {
      this.toastr.info($localize`Alle Beobachter und Teilnehmer wurden ausgeloggt!`)
    } else {
      this.authService.logout()
    }
  }

  public async updateTimezone(timezone: string) {
    return firstValueFrom(this.http.post('/api/usertimezone/update', { timezone }));
  }
}