<div class="d-flex">
  <div
    style="height: 48px ; width:48px; border-radius:50%; overflow: hidden"
    [appLayout]="layoutService.baseColorButtons"
    class="mr-2"
  >
    <i
      class="ri-user-2-fill pr-2"
      style="line-height:  40px;font-size: 45px !important;position: relative; top:10px"
    ></i>
  </div>
  <span
    style="line-height:  48px"
    *ngIf="showName"
  >{{participant.fullNameWithIndex}}</span>
</div>