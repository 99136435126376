import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SocketService } from 'src/app/shared/SocketService';
import { AuthService } from '../../auth/auth.service';

export type ParticipantResultType = "observationformresult" | "dimensionresult" | "surveyresult" | "pageannotation" | "videofeedback"
export type ParticipantResult = {
  packageid: string
  docid?: string
  _id: string
  total?: number,
  participantid?: string,
  ankerresults?: any[],
  value?: any
  videoid?: string
  updatedDate?: Date
  type: ParticipantResultType
  surveyid?: string,
  ratingComment: string,
  pin?: string,
  page?: string
  hostTotal?: string
  fromparticipantid?: string
  observerid?: string
  timeblockid?: string
  dimensionid?: string
}

export type Rating = { dimensionresults: ParticipantResult[] }

@Injectable({
  providedIn: 'root'
})
export class RatingService {
  // public rating: Rating;
  public $rating: Subject<ParticipantResult> = new Subject<ParticipantResult>();
  // public observation: any;
  public $observation: Subject<any> = new Subject<any>();

  public activeObserverId: string
  public activeTimeBlockId: string
  public activeParticipantId: string

  constructor(private authService: AuthService, private http: HttpClient, private socketService: SocketService) {

    this.socketService.socket.on("participantResultChange", (ratingOrObservation: ParticipantResult | any) => {
      /*
            console.log("ratingOrObservation", { ratingOrObservation })
            console.log("1:", this.activeObserverId != ratingOrObservation.observerid)
            console.log("2:", this.activeTimeBlockId != ratingOrObservation.timeblockid)
            console.log("3:", this.activeParticipantId != ratingOrObservation.participantid)
      */
      if (this.activeObserverId != ratingOrObservation.observerid ||
        this.activeTimeBlockId != ratingOrObservation.timeblockid ||
        this.activeParticipantId != ratingOrObservation.participantid) {
        return
      }


      if (ratingOrObservation.type === "dimensionresult") {
        console.log("ratingOrObservation", ratingOrObservation)
        /* const newRating = {
           dimensionresults: ratingOrObservation.dimensionresults.map((result) => {
             if (result._id === ratingOrObservation._id) {
               result = ratingOrObservation;
             }
             return result;
           })
         }*/

        this.$rating.next(ratingOrObservation);
        //   this.rating = newRating;
      } else {
        console.log("Nexte rating", ratingOrObservation)
        this.$observation.next(ratingOrObservation);
        //   this.observation = ratingOrObservation;
      }
    })
  }

  public setContext(observerid: string, timeblockid: string, participantid: string) {
    console.log("context for rating service is now ", { observerid, timeblockid, participantid })

    this.activeObserverId = observerid
    this.activeTimeBlockId = timeblockid
    this.activeParticipantId = participantid
  }

  public async fetchObervationFormResult(timeblockid: string, participantid: string) {
    const url = '/api/participantresult/observationform/' + timeblockid + '/' + participantid;

    return firstValueFrom(this.http.get(url).pipe(
      tap(observation => {

      })
    ));
  }

  public async fetchObservationFormTotal(participantId: string) {
    const url = '/api/participantresult/observationformhosttotal/' + participantId;
    return firstValueFrom(this.http.get(url));
  }

  public async saveObervationForm(timeblockid: string, participantid: string, type: 'strength' | 'developmentAreas' | 'notes' | 'learningArea', value: string) {
    const data = { format: 'html' };
    if (type === 'developmentAreas') type = 'learningArea';
    data[type] = value;
    return new Promise((resolve, reject) => {
      this.socketService.socket.emit("observationformChangeRequest", {
        timeblockid,
        participantid,
        data
      }, (res) => {
        resolve(res);
      });
    })
  }

  public async fetchMyRating(timeBlockId: string, participantId: string) {
    const rating = await firstValueFrom(this.http.get<Rating>('/api/participantresult/dimensionresult/' + timeBlockId + '/' + participantId))
    return rating
  }

  public async fetchModeratorRating(participantId: string) {

    const rating = await firstValueFrom(this.http.get<Rating>('/api/participantresult/moderatordimensionresult' + '/' + participantId))
    return rating
  }

  public saveRating(timeBlockId: string, participantId: string, dimensionId: string, rating) {
    const body = {
      total: rating.dimension[dimensionId],
      ankerresults: []
    };

    for (const ankerid in rating.anchor) {
      if (rating.anchor.hasOwnProperty(ankerid) && rating.anchor[ankerid] !== '') {
        let value = rating.anchor[ankerid];
        if (value === 'null') {
          value = null;
        }
        body.ankerresults.push({ ankerid, value });
      }
    }

    return new Promise((resolve, reject) => {
      this.socketService.socket.emit("dimensionresultChangeRequest", {
        timeblockid: timeBlockId,
        participantid: participantId,
        dimensionid: dimensionId,
        data: body
      }, (res) => {
        resolve(res);
      });
    })
  }
}