import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { RatingModule } from 'ngx-bootstrap/rating';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { QuillModule } from 'ngx-quill';
import { UploadxModule } from 'ngx-uploadx';
import { ObserverViewModule } from '../observer-view/observer-view.module';
import { SettingsComponent } from '../observer-view/settings/settings.component';
import { PipesModule } from '../pipes/pipes.module';
import { DisableVideoFeedbackGuard } from '../shared/disableVideoFeedback.guard';
import { InitPrepTimeServiceResolver } from '../shared/resolver/InitPrepTimeServiceResolver';
import { VideoComponent } from '../shared/video/video.component';
import { SeconddeviceComponent } from './../shared/seconddevice/seconddevice.component';
import { SeconddeviceAuthComponent } from './../shared/seconddeviceauth/seconddeviceauth.component';
import { VideoRecordingComponent } from './../shared/video-recording/video-recording.component';
import { PartiRoutingModule } from './parti-routing.module';
import { PartiViewComponent } from './parti-view.component';
import { SurveyComponent } from './survey/survey.component';


@NgModule({
  imports: [
    QRCodeModule,
    CommonModule,
    CollapseModule,
    PartiRoutingModule,
    TabsModule.forRoot(),
    FormsModule,
    AccordionModule.forRoot(),
    BsDropdownModule.forRoot(),
    RatingModule.forRoot(),
    /** HIER WIRD DAS OBSERVER VIEW MODUL
     * IMPORTIERT, DAS BRINGT DANN SEINE EIGENE ROUTEN MIT
     * UND ÜBERSCHREIBT DIE ROUTEN VOM PARTICIPANT MODUL!!!
     */
    ObserverViewModule,
    UploadxModule,
    ModalModule,
    QuillModule,
    ProgressbarModule.forRoot(),
    PipesModule
  ],
  // DINGE DIE das MODUL benötigz
  declarations: [
    PartiViewComponent,
    SettingsComponent,
    VideoComponent,
    VideoRecordingComponent,
    SurveyComponent,
    SeconddeviceComponent,
    SeconddeviceAuthComponent,
  ],
  providers: [
    InitPrepTimeServiceResolver,
    DisableVideoFeedbackGuard
  ],
  /*
  DINGE DIE DA MODUL EXPORTIERT
  und bereit stellt für alle dinge die DIEES modul
  benutz
  */
  exports: [CollapseModule]

})
export class PartiViewModule { }