import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PreptimeService } from './PreptimeService';
import { PackService } from './services/PackService';

@Injectable({
  providedIn: 'root'
})
export class DocumentAccessGuard {
  constructor(private packService: PackService, private prepService: PreptimeService) { }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const pack = await this.packService.getCurrentPackage();
    const selectedEvent = pack.eventdays[route.parent.params.day].events.find(e => e._id == route.params.selectedevent).id;
    if (!this.prepService.hasEventId(selectedEvent)) {
      return false;
    }
    const prepEnd = new Date(this.prepService.getEndtimeForEventId(selectedEvent).endtime);
    return ((prepEnd.getTime() - new Date().getTime() - this.prepService.getDiff()) / 1000) > 0;
  }
}