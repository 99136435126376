import { AfterViewInit, Component, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PackService } from '../services/PackService';

@Component({
  selector: 'app-timezone-modal',
  templateUrl: './timezone-modal.component.html',
  styleUrls: ['./timezone-modal.component.scss']
})
export class TimezoneModalComponent implements AfterViewInit {
  @ViewChild("content") content: TemplateRef<any>;
  public guessedTimezone: string = '';
  private modal: BsModalRef;
  private previousClose: number = 0;
  public userTimezone = '';
  private enableUserTimezone = true;

  constructor(private modalService: BsModalService, private aRoute: ActivatedRoute, private packService: PackService) {
    dayjs.extend(utc);
    dayjs.extend(tz);

    this.guessedTimezone = dayjs.tz.guess();
    this.previousClose = +localStorage.getItem('timezoneModalClosed');

    aRoute.data.subscribe((data) => {
      this.userTimezone = data.pack.me.timezone;
      this.enableUserTimezone = data.pack.enableUserTimezone;
    });
  }

  ngAfterViewInit(): void {
    if (
      this.enableUserTimezone &&
      this.userTimezone != '' &&
      this.userTimezone != this.guessedTimezone &&
      this.previousClose + 60 * 60 * 24 < dayjs().unix()
    ) {
      this.modal = this.modalService.show(this.content, { backdrop: 'static', keyboard: false });
    }
  }

  public async saveTimezone() {
    await this.packService.updateTimezone(this.guessedTimezone);
    localStorage.setItem('timezoneModalClosed', dayjs().unix().toString());
    location.reload();
  }

  public cancel() {
    if (this.modal) {
      localStorage.setItem('timezoneModalClosed', dayjs().unix().toString());
      this.modal.hide();
    }
  }
}
