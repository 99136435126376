import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { LayoutService } from '../../shared/services/layout.service';
import { SettingsService } from './settings.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent {
  constructor(public layoutService: LayoutService, public settingsService: SettingsService, public router: Router, public authService: AuthService) { }

  sendResults(form: NgForm) {
    if (form.valid) {
      this.settingsService.emailSelfestimation(form.value.email);
    }
  }

  deleteUserdata() {
    this.settingsService.anonymiseParticipantData();
  }
}