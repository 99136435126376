import { Component, Input } from '@angular/core';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-dayselectiontabs',
  templateUrl: './dayselectiontabs.component.html',
  styleUrls: ['./dayselectiontabs.component.css']
})
export class DayselectiontabsComponent {
  @Input() public eventdays;
  @Input() public viewForRouter;
  @Input() public viewForRole;

  constructor(public layoutService: LayoutService) { }
}