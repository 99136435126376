
<span
     *ngIf="layoutService.nameComponents"
     class="alert alert-info"
>documentview.component.html</span>
<div class="row p-0 m-0 ">
     <!-- DAY -->
     <div
          class="col p-0  border-right"
          [ngClass]="{'d-none': layoutService.hideSidebar}"
          [style]="{'background-color': '#ffffff' , 'max-width':'100px'}"
     >
          <app-dayselectiontabs
               [eventdays]="this.package.eventdays"
               viewForRouter="documentview"
               [viewForRole]="role"
          >
          </app-dayselectiontabs>
     </div>
     <!-- SCEDULE -->
     <div
          class="col-3 p-0  overflow-auto border-right"
          style="cursor: pointer"
          [ngClass]="{'d-none': layoutService.hideSidebar}"
          appHeightWatcher
     >
          <app-mydayeventslist
               [dayEvents]="this.eventondayDays"
               [viewForRole]="role"
          ></app-mydayeventslist>
     </div>
     <!-- CONTENT -->
     <div
          #docdiv
          class="col overflow-auto"
          id="docdiv"
          style="padding: 0px;"
          [ngStyle]="{'background-color':layoutService.hideSidebar? '#efefef': '#fff'}"
          appHeightWatcher
     >
          <router-outlet></router-outlet>
     </div>
</div>
