import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import { AuthService } from '../auth/auth.service';

interface RecordedVideoOutput {
  blob: Blob;
  url: string;
  title: string;
}

@Injectable()
export class VideoRecordingService {

  private stream;
  private recorder;
  private interval;
  private _stream = new Subject<MediaStream>();
  private _recorded = new Subject<RecordedVideoOutput>();
  private _recordedUrl = new Subject<string>();
  private _recordingFailed = new Subject<string>();

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  getRecordedUrl(): Observable<string> {
    return this._recordedUrl.asObservable();
  }

  getRecordedBlob(): Observable<RecordedVideoOutput> {
    return this._recorded.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  getStream(): Observable<MediaStream> {
    return this._stream.asObservable();
  }

  startRecording(conf: any): Promise<any> {
    let browser = <any>navigator;
    if (this.recorder) {
      console.log('kein recorder');
      return Promise.resolve(null);
    }

    return new Promise((resolve, reject) => {
      browser.mediaDevices.getUserMedia(conf).then(stream => {
        console.log("stream", stream)
        this.stream = stream;
        this.record();
        resolve(this.stream);
      }).catch(error => {
        this._recordingFailed.next(null);
        reject();
      });
    });
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {
    this.recorder = new RecordRTC(this.stream, {
      type: 'video',
      mimeType: 'video/mp4',
      bitsPerSecond: 600000
    });
    this.recorder.startRecording();
    this.interval = setInterval(
      () => {
        this._stream.next(this.stream);
      },
      500
    );
  }

  private toString(value) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }

  stopRecording() {
    console.log("recorder?")
    if (this.recorder) {
      console.log("stopping recorder")
      this.recorder.stopRecording(this.processVideo.bind(this));
    }
  }

  private processVideo(audioVideoWebMURL) {
    const recordedBlob = this.recorder.getBlob();
    this.recorder.getDataURL(function (dataURL) { });
    const recordedName = encodeURIComponent('video_' + new Date().getTime() + '.mp4');
    console.log("blob here?", recordedBlob)
    this._recorded.next({ blob: recordedBlob, url: audioVideoWebMURL, title: recordedName });
    this.stopMedia();
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream.getVideoTracks().forEach(track => track.stop());
        this.stream.stop();
        this.stream = null;
      }
    }
  }

  public async createVideoRecordingHistoryEntry(request: any): Promise<any> {
    const url = '/api/recordinghistory/create/' + request.packageId;
    return await firstValueFrom(this.httpClient.post(url, request));
  }
}
