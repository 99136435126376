import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { ColorPair } from './layout.service';

@Directive({
  selector: '[appLayout]'
})
export class LayoutDirective implements AfterViewInit {
  @Input('appLayout') public colorPair: ColorPair;
  @Input('appLayoutColorFlip') public colorFlip = false;

  constructor(public el: ElementRef) { }

  ngAfterViewInit(): void {
    if (this.colorFlip) {
      this.el.nativeElement.style.backgroundColor = this.colorPair.foregroundColor;
      this.el.nativeElement.style.color = this.colorPair.backgroundColor;
    } else {
      this.el.nativeElement.style.backgroundColor = this.colorPair.backgroundColor;
      this.el.nativeElement.style.color = this.colorPair.foregroundColor;
    }
  }
}