import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { LayoutService } from '../../shared/services/layout.service';

@Component({
  selector: 'app-document-view',
  templateUrl: './document-view.component.html',
  styleUrls: ['./document-view.component.css']
})
export class DocumentViewComponent {
  id: string;
  role: string;
  package: PRM.IpadPackage;
  eventondayDays: PRM.Event[] = [];
  allparticipants: PRM.IpadParticipant[];
  myEvent: PRM.Event;
  public selectedeventobject: PRM.Event;
  index: number;
  public angular;
  @ViewChild('docdiv') documentDiv: ElementRef;

  constructor(private aRoute: ActivatedRoute, public layoutService: LayoutService) {
    this.id = this.aRoute.snapshot.params.id;
    aRoute.data.subscribe((data) => {
      this.eventondayDays = [];
      this.package = data.pack.pack;
      // @ts-ignore
      if (this.package.me.role === 'moderator' || this.package.me.role === 'observer') {
        this.role = 'observer';
      } else {
        // @ts-ignore
        this.role = this.package.me.role;
      }
      this.selectedeventobject = data.pack.selectedeventobject;
      this.allparticipants = this.package.allparticipants;
      for (const event of data.pack.dayEvents) {
        if (parseFloat(event.team) === parseFloat('' + (this.package.me as PRM.Observer).team) || event.participantId.includes(this.package.me.id)) {
          event.participants = [];
          for (const id of event.participantId) {
            const index = this.allparticipants.findIndex(participant => participant.id === id);
            if (index > -1) {
              event.participants.push(this.allparticipants[index]);
            }
          }
          this.eventondayDays.push(event);
        }
      }
      for (const EventDay of this.package.eventdays) {
        this.index = EventDay.events.findIndex(el => el.id === this.id);
      }
      console.debug('events', this.eventondayDays);
    });
  }

  scrollTop() {
    this.documentDiv.nativeElement.scrollTo(0, 0);
  }
}