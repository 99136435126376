import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UploadState, UploadxService } from 'ngx-uploadx';
import { AuthService } from 'src/app/auth/auth.service';
import { ConfirmDeleteVideoComponent } from './../confirm-delete-video/confirm-delete-video.component';
import { VideoRecordingService } from './../video-recording.service';


@Component({
  selector: 'app-video-recording',
  templateUrl: './video-recording.component.html',
  styleUrls: ['./video-recording.component.css']
})
export class VideoRecordingComponent implements OnInit, AfterViewInit {
  public tbid: string;
  public packageId: string;
  @ViewChild('videoElement') videoElement: { nativeElement: HTMLVideoElement };
  public video: any;
  public isPlaying: boolean = false;
  public isVideoRecording: boolean = false;
  public showLivePreview: boolean = false;
  public videoBlobUrl: SafeUrl;
  private videoBlob: Blob;
  private videoName: string;
  public videoStream: MediaStream;
  private videoConf = { video: { facingMode: "environment", width: 320 }, audio: true }
  private videoType: string = "selfRecording";
  public pack: PRM.IpadPackage;
  public uploadProgress: number;
  public uploadStatus: string;
  public showPlayButton: boolean = false;
  public isUploading = false;
  @Input() public is2ndDevice: boolean = true;
  public maxRetrys = 3;
  public retryCount = 0;
  public viewState: string = "initial";

  constructor(private route: ActivatedRoute, private httpClient: HttpClient,
    private ref: ChangeDetectorRef,
    public authService: AuthService,
    private videoRecordingService: VideoRecordingService,
    public uploadService: UploadxService,
    private sanitizer: DomSanitizer,
    private modalService: BsModalService) {
    //if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/oppo/i) || navigator.userAgent.match(/Find/i)) {
    //  this.is2ndDevice = true;
    //}
    route.data.subscribe((data) => {
      this.pack = data.pack.pack;
      this.packageId = this.pack._id
      if (data.pack.selectedeventobject) {
        this.tbid = data.pack.selectedeventobject.tbid;
      } else {
        this.tbid = route.snapshot.params.tbid;
      }
    });
    this.videoRecordingService.recordingFailed().subscribe(() => {
      this.isVideoRecording = false;
      this.ref.detectChanges();
    });

    this.videoRecordingService.getStream().subscribe((stream) => {
      this.videoStream = stream;
      this.ref.detectChanges();
    });

    this.videoRecordingService.getRecordedBlob().subscribe((data) => {
      this.videoBlob = data.blob;
      this.videoName = data.title;
      console.log("url", data.url)
      this.videoBlobUrl = this.sanitizer.bypassSecurityTrustUrl(data.url);
      console.log("myblob", data.url)

      this.ref.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.video = this.videoElement.nativeElement;
  }

  ngOnInit() {
    this.uploadService.init({}).subscribe((state: UploadState) => {
      this.uploadProgress = state.progress || 0
      this.uploadStatus = state.status;
      if (this.uploadStatus == "retry") {
        this.retryCount++;
        if (this.retryCount >= this.maxRetrys) {
          this.uploadService.control({ action: 'cancel' })
        }
      }
      if (this.uploadStatus == 'complete') {
        const reqBody = {
          participantTbid: this.tbid,
          packageId: this.packageId,
          participantPin: this.authService.getAuthData().pin,
          firstname: this.pack.me.firstname,
          lastname: this.pack.me.lastname,
          device: (this.pack.secondDevice) ? 'second_device' : 'primary_device',
          action: 'completed_upload_recording'
        };

        this.videoRecordingService.createVideoRecordingHistoryEntry(reqBody).then();
      }
    })
  }

  hideVideoControls() { this.video.controls = false; }
  showVideoControls() { this.video.controls = true; }
  hideIosPreviewButton() { this.showPlayButton = false; }
  showIosPreviewButton() { this.showPlayButton = true; }
  videoPreviewClick() {
    this.video.play();
    // #Logging2 hier hat man auf aufnahme starten gedrückt
    const reqBody = {
      participantTbid: this.tbid,
      packageId: this.packageId,
      participantPin: this.authService.getAuthData().pin,
      firstname: this.pack.me.firstname,
      lastname: this.pack.me.lastname,
      device: (this.pack.secondDevice) ? 'second_device' : 'primary_device',
      action: 'start_recording'
    };

    this.videoRecordingService.createVideoRecordingHistoryEntry(reqBody).then();
  }

  startVideoRecording() {
    if (!this.isVideoRecording) {
      this.hideVideoControls();
      this.isVideoRecording = true;
      this.videoRecordingService.startRecording(this.videoConf)
        .then(stream => {
          if (stream == null) {
            alert("FEHLER")
            return;
          }
          this.showLivePreview = true;
          this.video.srcObject = stream;
          this.videoPreviewClick();
          this.showIosPreviewButton();
          // #Logging3 wenn man hier ist, läuft die aufnahme und man hat angenommen zu filmen
          const reqBody = {
            participantTbid: this.tbid,
            packageId: this.packageId,
            participantPin: this.authService.getAuthData().pin,
            firstname: this.pack.me.firstname,
            lastname: this.pack.me.lastname,
            device: (this.pack.secondDevice) ? 'second_device' : 'primary_device',
            action: 'accept_camera_usage'
          };

          this.videoRecordingService.createVideoRecordingHistoryEntry(reqBody).then();
        })
        .catch(function (err) {
        });
    }
  }

  stopVideoRecording() {
    if (this.isVideoRecording) {
      console.log("blob url", this.videoBlobUrl)
      this.videoRecordingService.stopRecording();
      this.video.srcObject = this.videoBlobUrl;
      this.isVideoRecording = false;
      this.showVideoControls();
      this.hideIosPreviewButton();
      // #Logging4 hier hat man die aufnahme gestoppt
      const reqBody = {
        participantTbid: this.tbid,
        packageId: this.packageId,
        participantPin: this.authService.getAuthData().pin,
        firstname: this.pack.me.firstname,
        lastname: this.pack.me.lastname,
        device: (this.pack.secondDevice) ? 'second_device' : 'primary_device',
        action: 'stop_and_upload_recording'
      };

      this.videoRecordingService.createVideoRecordingHistoryEntry(reqBody).then();
    }
  }

  openConfirmClearRecord() {
    const modal = this.modalService.show(ConfirmDeleteVideoComponent, { backdrop: false, focus: false, keyboard: true })
    modal.onHide.subscribe(m => {
      if (modal.content.accepted) {
        this.clearVideoRecordedData();
      }
    })
  }

  clearVideoRecordedData() {
    this.videoBlob = null
    this.videoBlobUrl = null;
    this.video.srcObject = null;
    this.showLivePreview = false;
    this.hideVideoControls();
    this.ref.detectChanges();
  }

  retryUpload() {
    this.retryCount = 0
    this.uploadVideo();
  }

  uploadVideo(lastSize: number = -1) {
    //this.logVideoBlobSize(this.videoBlob)
    if (!this.videoBlob) {
      return setTimeout(() => {
        this.uploadVideo(-1)
      }, 200)
    }

    if (this.videoBlob.size != lastSize) {
      return setTimeout(() => {
        this.uploadVideo(this.videoBlob.size)
      }, 200)
    }

    this._uploadVideo(this.videoBlob, 'video/mp4', this.videoName);
    this.isUploading = true;
    // #Logging5 hier rennt der upload
  }

  public logVideoBlobSize(vb: Blob) {
    const reqBody = {
      participantTbid: this.tbid,
      packageId: this.packageId,
      participantPin: this.authService.getAuthData().pin,
      //firstname: this.pack.me.firstname,
      lastname: this.pack.me.lastname,
      device: (this.pack.secondDevice) ? 'second_device' : 'primary_device',
      action: 'report_videoblobsize',
      firstname: vb == null ? "blob is null" : "blobsize:" + vb.size
    };

    this.videoRecordingService.createVideoRecordingHistoryEntry(reqBody).then();
  }

  _uploadVideo(data: any, type: string, filename: string): any {
    const blob = new Blob([data], { type: type });
    let file = new File([blob], "ParticipantVideo.mp4", { type: "video/mp4" });
    const reqBody = {
      participantTbid: this.tbid,
      packageId: this.packageId,
      participantPin: this.authService.getAuthData().pin,
      lastname: this.pack.me.lastname,
      device: (this.pack.secondDevice) ? 'second_device' : 'primary_device',
      action: 'report_videofile',
      firstname: JSON.stringify({ name: file.name, size: file.size, lastmod: file.lastModified, type: file.type, blobSize: blob.size, blobType: blob.type })
    }
    this.videoRecordingService.createVideoRecordingHistoryEntry(reqBody).then();

    this.uploadService.handleFiles(file, {
      endpoint: '/api/upload/participantvideo',

      metadata: {
        ipadid: this.authService.getAuthData().iPadId,
        participantpin: this.authService.getAuthData().pin,
        timeblockid: this.tbid,
        videotype: this.videoType
      }
    })
  }

  stopAndUpload() {
    this.stopVideoRecording();
    setTimeout(() => {
      this.uploadVideo()
    }, 200);
  }
}