import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { VimeoService } from '../services/vimeo.service';

@Injectable()
export class VideoResolver {
  constructor(private service: VimeoService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const videoId = route.params.selectedvideo;
    const result = await this.service.getVimeoInfo(videoId);
    if (result?.id) {
      return result;
    }
    return { id: videoId, isVimeoVideo: false };
  }
}