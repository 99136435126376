import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { RatingService } from '../../observer-view/rating-scale/rating.service';

@Injectable()
export class RatingResolver {

  constructor(private ratingService: RatingService) {
  }

  async resolve(route: ActivatedRouteSnapshot) {
    const ratingFromService = await this.ratingService.fetchMyRating(route.params.timeblockid, route.params.userid);
    const rating = {

      dimensionResult: ratingFromService.dimensionresults,
      timeBlockId: route.params.timeblockid,
      userId: route.params.userid,

    };
    console.log("myrating is", rating)
    return rating;
  }
}

