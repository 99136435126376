import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { PackService } from '../services/PackService';
import { PRM } from '@reflact/prmacweb';
import { RatingService } from '../../observer-view/rating-scale/rating.service';
import { PdfAnnotationService } from '../services/PdfAnnotationService';

interface ParticipantSummary {
  events: Array<PRM.Event>;
  ratingSummary: RatingSummary;
  totalRating: RatingTotal;
  docannotations: any
  pack: PRM.IpadPackage
}

interface RatingSummary {
  dimensions: Array<any>;
  scale: Array<PRM.ScaleEntry>;
  ratings: Array<any>;
}

interface RatingTotal {
  devmeasures: string;
  learningArea: string;
  strength: string;
}

@Injectable()
export class ParticipantSummaryNewResolver {
  private participantSummary: ParticipantSummary;
  private participantId: string;

  constructor(private packService: PackService, private ratingService: RatingService, public pageAnnotationService: PdfAnnotationService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.participantId = route.params.participantid;

    const totalRating: any = await this.ratingService.fetchObservationFormTotal(this.participantId);
    const docannotations = await this.pageAnnotationService.getDocAnnotations(route.params.participantid);
    const packData = await this.packService.getCurrentPackage();

    this.participantSummary = { events: [], ratingSummary: { dimensions: [], scale: [], ratings: [] }, totalRating: totalRating, docannotations, pack: packData };
    await this.parseRatings(this.participantId);
    packData.eventdays.forEach((eventDay: PRM.EventDay) => {
      this.parseEventDay(eventDay.events);
    });

    this.participantSummary.ratingSummary.dimensions.push(...packData.dimensions);
    this.participantSummary.ratingSummary.scale.push(...packData.scale);
    const participantSummary: ParticipantSummary = { ...this.participantSummary };
    return participantSummary;
  }

  private parseEventDay(eventDay: Array<PRM.Event>) {
    eventDay.forEach((event: PRM.Event) => {
      this.parseEvent(event);
    });
  }


  private parseEvent(event: PRM.Event) {
    if (event.participantId.indexOf(this.participantId) > -1 && event._id !== 'null' && event.timeblock.type === 'exercise') {
      event.participantSummaryDocuments = [];
      this.participantSummary.events.push(event);
      if (event.documents != null) {
        this.parseEventDocuments(event, event.documents);
      }
      if (event.timeblock.documents != null) {
        this.parseTimeBlockDocuments(event, event.timeblock.documents);
      }
      this.parseObservationForm(event, this.participantId);
    }
  }

  private async parseObservationForm(event: PRM.Event, participantId: string) {
    event.strengths = [];
    event.developmentAreas = [];
    event.notes = [];
    const result: any = await this.ratingService.fetchObervationFormResult(event.timeblock._id, participantId);
    if (result.strength && result.strength !== '') {
      event.strengths.push(result.strength);
    }
    if (result.learningArea && result.learningArea !== '') {
      event.developmentAreas.push(result.learningArea);
    }
    if (result.notes && result.notes !== '') {
      event.notes.push(result.notes);
    }
  }

  private async parseRatings(participantId: string) {
    const results: any = await this.ratingService.fetchModeratorRating(participantId);
    this.participantSummary.ratingSummary.ratings = results.dimensionresults;
  }

  private parseTimeBlockDocuments(event: PRM.Event, documents: Array<PRM.IpadDocument>) {
    documents.forEach((document: PRM.IpadDocument) => {
      if (document.metadata.editable &&
        document.metadata.participantInstanceEnabled &&
        event.participantSummaryDocuments.findIndex(d => (d._id === document._id)) === -1) {
        event.participantSummaryDocuments.push(document);
      }
    });
  }

  private parseEventDocuments(event: PRM.Event, documents: Array<PRM.IpadDocument>) {
    documents.forEach((document: PRM.IpadDocument) => {
      if (document.metadata.editable &&
        document.metadata.participantInstanceEnabled &&
        event.participantSummaryDocuments.findIndex(d => (d._id === document._id)) === -1) {
        event.participantSummaryDocuments.push(document);
      }
    });
  }
}
