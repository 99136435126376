import {AfterViewInit, Directive, ElementRef} from '@angular/core';
import {LayoutService} from './shared/services/layout.service';

@Directive({
  selector: '[appHeightWatcher]'
})
export class HeightWatcherDirective implements AfterViewInit {

  constructor(private el: ElementRef, public layoutService: LayoutService) {
  }

  run() {
    this.el.nativeElement.style.height = 'calc(100vh - ' + this.layoutService.headerHeight + ')';
  }

  ngAfterViewInit(): void {

    this.run();


  }

}
