import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Injectable} from '@angular/core';
import {SurveyService} from '../../parti-view/survey/survey.service';

@Injectable()
export class SurveyResultResolver  {
  constructor(private service: SurveyService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const surveyresults = await this.service.getSurveyResults();
    return surveyresults;
  }
}
