import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { HttpErrorService } from '../shared/http-error.service';

@Component({
  selector: 'app-login-no-privacy-policy',
  templateUrl: './login-no-privacy-policy.component.html',
  styleUrls: ['./login-no-privacy-policy.component.css']
})
export class LoginNoPrivacyPolicyComponent implements OnInit {
  err: HttpErrorResponse;

  public waitingForLoginValidation = false;
  @ViewChild('pincode') public pincode: ElementRef;

  constructor(
    private authService: AuthService,
    private route: Router,
    private httpErrorService: HttpErrorService
  ) { }

  ngOnInit(): void {
    this.httpErrorService.getError().subscribe((err) => {
      this.err = err;
    });
  }

  async onSubmit(form) {
    const pin = this.pincode.nativeElement.value;
    this.authService.setPin(pin);
    this.authService.login().subscribe((value) => {
      if (value.status === 'accept') {
        const localStorageObject = this.authService.getPrmWebAppLastUrl();
        const authData = this.authService.getAuthData();

        if (
          localStorageObject &&
          ['/auth', "/pv44"].indexOf(localStorageObject.url) < 0 &&
          localStorageObject.pin === authData.pin &&
          localStorageObject.iPadId === authData.iPadId
        ) {

          this.route.navigate([localStorageObject.url]);
          return;
        }

        if (
          window.location.hostname !== 'localhost' &&
          window.location.pathname.indexOf('/webapp/' + value.lang) < 0
        ) {
          // wrong language frontend loaded switching
          if (authData.role === 'moderator' || authData.role === 'observer') {
            window.location.href = '/webapp/' + value.lang + '/#/observer/introduction/docs';
          } else if (authData.role === 'participant') {
            window.location.href = '/webapp/' + value.lang + '/#/participant/introduction/docs';
          }
        } else if (authData.role === 'moderator' || authData.role === 'observer') {
          this.route.navigate(['/observer/introduction/docs']);
        } else if (authData.role === 'participant') {
          this.route.navigate(['/participant/introduction/docs']);
        }
      }
      if (value.status === 'decline') {
        this.err = new HttpErrorResponse({ status: 401 });
      }
      this.waitingForLoginValidation = false;
    });
    this.waitingForLoginValidation = true;
  }

  public punschPin(v: number) {
    let value = this.pincode.nativeElement.value + '';
    if (v === -1) {
      value = value.slice(0, -1);
    } else if (value.length < 6) {
      value += v;
    }
    this.pincode.nativeElement.value = value;
  }

  public processKeyUp(e, lastEl, nextEl: HTMLElement) {
    if (e.key === 'Tab' || e.key === 'Shift') {
      return;
    }
    if (e.key === 'Backspace') {
      lastEl.focus();
    } else if (e.key >= '0' && e.key <= '9') {
      if (nextEl.id === 'login') {
        nextEl.click();
      } else {
        nextEl.focus();
      }
    }
  }
}