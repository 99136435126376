import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SurveyService {
  public constructor(private http: HttpClient, private authService: AuthService) { }

  public sendSurveyResults(surveyid, timeblockid, value) {
    const url = '/api/participantresult/survey';
    const body = {
      surveyid,
      timeblockid,
      value
    };

    return firstValueFrom(this.http.post(url, body));
  }

  public getSurveyResults(timeblockid?) {
    let url = '/api/participantresult/mysurveyresults';
    if (timeblockid) {
      url += '/' + timeblockid;
    }

    return firstValueFrom(this.http.get(url));
  }
}