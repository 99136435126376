import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-document-content-renderer-single-video',
  templateUrl: './document-content-renderer-single-video.component.html',
  styleUrls: ['./document-content-renderer-single-video.component.css']
})
export class DocumentContentRendererSingleVideoComponent {
  videoId: string;
  window: Window = window;
  isVimeoVideo: boolean;
  vimeoUrl: any;
  constructor(private aRoute: ActivatedRoute, public authService: AuthService, public sanitizer: DomSanitizer, public layoutService: LayoutService, private router: Router) {
    aRoute.data.subscribe((data) => {

      this.isVimeoVideo = data.video.isVimeoVideo;
      this.videoId = data.video.id;
      this.vimeoUrl = sanitizer.bypassSecurityTrustResourceUrl('https://player.vimeo.com/video/' + this.videoId);
    });
  }

  public closeEditor() {
    const targetUrl = this.router.url.split('/docs')[0] + '/docs';
    this.router.navigateByUrl(targetUrl);
  }
}