import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { mapOfArrayEnsureInitialVal } from '@reflact/tsutil';
import { UploadxService } from 'ngx-uploadx';
@Component({
  selector: 'app-upload-videos',
  templateUrl: './upload-videos.component.html',
  styleUrls: ['./upload-videos.component.css']
})
export class UploadVideosComponent {
  pack: PRM.IpadPackage;
  myExerciseEventsByParticipant: Map<string, PRM.Event[]> = new Map();
  participantIdToParticipant: Map<string, PRM.IpadParticipant>

  constructor(private aRoute: ActivatedRoute, public uploadService: UploadxService) {
    aRoute.data.subscribe(data => {
      this.initDataFromPack(data.pack);
    })
  }

  public initDataFromPack(pack: PRM.IpadPackage) {
    this.pack = pack;

    this.pack.eventdays.forEach(day => {
      day.events.map(e => {
        if (e.timeblock.type == "exercise" && e.team == (pack.me as PRM.Observer).team) {
          mapOfArrayEnsureInitialVal(this.myExerciseEventsByParticipant, e.resourceId, e)
        }
      })
    })
    this.participantIdToParticipant = new Map(this.pack.allparticipants.map(p => [p.id, p]))
  }
}