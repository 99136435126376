import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Injectable} from '@angular/core';
import {PreptimeService} from '../PreptimeService';

@Injectable()
// @ts-ignore
export class InitPrepTimeServiceResolver  {

  constructor(private prepService: PreptimeService) {
    this.prepService.init();
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return true;
  }
}

