<span
  *ngIf="layoutService.nameComponents"
  class="alert alert-info"
>mydayeventlist.component.html</span>
<ul class="list-group list-group-flush ">
  <li
    *ngFor="let ev of dayEvents"
    [ngStyle]="{
      'borderRightColor':layoutService.activeColor.backgroundColor,
      'border':'100px sold red !important '
      }"
    [routerLinkActiveOptions]="{exact: false}"
    [routerLink]="['/',viewForRole,'documentview',ev.day, ev._id]"
    class="list-group-item"
    routerLinkActive="activeTime"
    style="background-color: #F2F2F5"
  >
    <div class="row">
      <div class="col-12  col-lg-3 pl-0">
        <small *ngIf="userTimezone">{{ev.start | timezone: this.startDate : ev.day : this.userTimezone :
          this.packTimezone : true}}</small>
        <small *ngIf="!userTimezone">{{ev.start}}</small>
        <br>

        <small>
          <a
            href="{{ev.roomLink}}"
            target="_blank"
            *ngIf="ev.roomLink &&  ev.roomName &&ev.roomLink.trim() !='' && ev.roomName.trim() !='' && ev.roomLink.trim() != '-'"
            [style.color]="layoutService.activeColor.backgroundColor"
          ><u>{{ev.roomName}}</u></a>
          <span *ngIf="!ev.roomLink  || ev.roomLink.trim() == '' || ev.roomLink.trim() == '-'"> {{ev.roomName}}</span>
        </small>
      </div>
      <div class="col pl-0 pl-xs-3">

        {{ev.timeblock.name}}
        <br>
        <span *ngFor="let participant of ev.participants; let i = index"><span
            *ngIf="viewForRole === 'observer'">{{participant.fullNameWithIndex}}</span><span
            *ngIf="viewForRole !== 'observer'"
          >{{participant.firstname}} {{participant.lastname}}</span>
          <span *ngIf="(i +1) != ev.participants.length">, </span></span>
      </div>
    </div>
  </li>
</ul>