import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { AuthService } from '../../auth/auth.service';
import { SocketService } from '../../shared/SocketService';
import { LayoutService } from '../../shared/services/layout.service';
import { VideoService } from '../../shared/video/video.service';

@Component({
  selector: 'app-video-share',
  templateUrl: './video-share.component.html',
  styleUrls: ['./video-share.component.css']
})
export class VideoShareComponent implements OnInit {
  public pack: PRM.IpadPackage;
  public feedbackForVideos: any[];
  public dayjs;

  constructor(public layoutService: LayoutService,
    public authService: AuthService,
    public videoService: VideoService,
    private aRoute: ActivatedRoute, private socketService: SocketService
  ) {
    dayjs.extend(relativeTime);
    this.dayjs = dayjs;
    this.fetchFeedbackVideos();
    this.socketService.socket.on('feedbackParticipant', this.feedbackParticipantRecieved.bind(this));
  }

  public ngOnInit() {
    this.fetchFeedbackVideos();
  }
  async fetchFeedbackVideos() {
    const allFeedbackVideos: any = await this.videoService.getAllFeedbackVideos();

    console.log("fetch feedabck videos", allFeedbackVideos)

    if (allFeedbackVideos) {
      this.feedbackForVideos = allFeedbackVideos.feedbacks;
    }
    this.aRoute.data.subscribe((data) => {
      this.pack = data.pack;
      this.feedbackForVideos.forEach((vid) => {
        vid.participantObject = this.getParticipantObject(vid.metadata.participantid);
      });
    });
  }

  private feedbackParticipantRecieved(data: any) {
    if (!data.inviting) {
      this.removeFeedbackVideo(data.video);
      return;
    }
    const video = data.video;
    video.ratingComment = undefined;
    video.participantObject = this.getParticipantObject(video.metadata.participantid);
    this.feedbackForVideos.push(video);
  }

  private getParticipantObject(participantId: string) {
    const index = this.pack.allparticipants.findIndex(p => p.id === participantId);
    if (index > -1) {
      return this.pack.allparticipants[index];
    }
  }

  private removeFeedbackVideo(video: PRM.ParticipantVideo) {
    const index = this.feedbackForVideos.findIndex(f => f._id === video._id);
    if (index > -1) {
      this.feedbackForVideos.splice(index, 1);
    }
  }

  public saveRating(feedbackVid: any) {
    feedbackVid.check = true;
    this.videoService.saveRatingForVideoId(feedbackVid._id, feedbackVid.myfeedback, feedbackVid.ratingComment);
    setTimeout(() => { feedbackVid.check = false; }, 1000);
  }
}