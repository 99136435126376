import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { AuthService } from '../../auth/auth.service';
import { LayoutService } from '../../shared/services/layout.service';

@Component({
  selector: 'app-document-content-renderer',
  templateUrl: './document-content-renderer.component.html',
  styleUrls: ['./document-content-renderer.component.css']
})
export class DocumentContentRendererComponent {
  public documents: PRM.Document[] = [];
  public selectedeventobject: PRM.Event = null;
  public allparticipants: PRM.Participant[];

  constructor(public aRoute: ActivatedRoute,
    public layoutService: LayoutService,
    public authService: AuthService,
    public router: Router
  ) {
    aRoute.data.subscribe((data) => {
      this.documents = data.pack.allDocuments;
      if (data.pack.selectedeventobject) {
        this.allparticipants = data.pack.selectedeventobject.participants;
        this.selectedeventobject = data.pack.selectedeventobject;
      }
    });
  }

  getRouterlinkToSingleDocView(doc: PRM.IpadDocument) {
    if (doc.contentType === 'application/pdf') {
      const r = [doc._id, '0'];
      if (doc.metadata.participantInstanceEnabled) {
        r.push(doc.metadata.participantObject.id);
      }
      return r;
    } else if (doc.contentType === 'video/mp4') {
      return ['video', doc._id];
    }
  }
}