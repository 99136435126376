<ng-template #tolTemplate>
  <div [innerHtml]="getScaleTooltip()"></div>
</ng-template>

<div
  class=" row text-left"
  style="background-color: #fff"
>
  <div class="col-12 ">
    <app-document-tab-buttons
      [allparticipants]="allparticipants"
      [aRoute]="aRoute"
      [selectedEvent]="selectedeventobject"
    ></app-document-tab-buttons>
    <div class="row  px-4">
      <div class="col">
        <div class="row  ">
          <div class="col ">
            <div class="card mb-4">
              <div
                class="card-header  "
                [appLayout]="layoutService.baseColorButtons"
              >
                <b i18n> Notizen </b>
                <app-saved-indikator [isSaved]="isSavedService.getIsSaved()"></app-saved-indikator>
              </div>
              <div class="card-body p-0">

                <quill-editor
                  class="w-100"
                  style="margin-bottom: -5px;"
                  [(ngModel)]="this.notes"
                  (ngModelChange)="cbtest('notes')"
                ></quill-editor>
              </div>
            </div>
          </div>
        </div>

        <form #ratingForm="ngForm">
          <ng-container
            ngModelGroup="{{dim.id}}"
            *ngFor="let dim of dimensionsForTimeBlock"
          >
            <div class="card mb-4">
              <div
                class="card-header p-0"
                style="cursor: pointer;"
              >
                <table class="table mb-0">
                  <tbody>
                    <tr [appLayout]="layoutService.baseColorButtons">
                      <td
                        class="col-10"
                        (click)="isCollapsed[dim.id] = !isCollapsed[dim.id]"
                      >
                        <i
                          class="ri-arrow-down-s-line"
                          *ngIf="isCollapsed[dim.id]"
                          style="position: relative; top:8px;"
                          icon="angle-down"
                          size="1x"
                        ></i>
                        <i
                          class="ri-arrow-up-s-line"
                          *ngIf="!isCollapsed[dim.id]"
                          style="position: relative; top:8px;"
                          icon="angle-up"
                        ></i>
                        <b>{{dim.name}}</b>

                      </td>

                      <td class="text-center"> <i
                          class="ri-question-line"
                          style="position: relative; top:5px;"
                          [popover]="tolTemplate"
                        ></i></td>
                      <td
                        *ngFor="let s of scale"
                        class="text-center"
                      >
                        <div class="form-check">
                          <span class="form-check-input">{{s.value}}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                class="card-body p-0"
                [collapse]="isCollapsed[dim.id]"
                [isAnimated]="true"
              >
                <table class="table  table-striped mb-0">
                  <tbody>
                    <tr
                      *ngFor="let ank of dim.anker"
                      ngModelGroup="anchor"
                    >
                      <td class="col-10"> {{ank.name}} </td>
                      <td style="text-align:center; "><span style="visibility:hidden">xxx</span></td>
                      <td
                        *ngFor="let s of scale"
                        style="text-align:center"
                      >
                        <div class="form-check">
                          <input
                            [(ngModel)]="ngModelArrayForBinding[dim.id+'_XX_'+ank.id]"
                            class="form-check-input"
                            type="radio"
                            value="{{s.value}}"
                            name="{{ank.id}}"
                            (change)="saveDimension(ratingForm, dim.id)"
                          >
                        </div>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <div class="card-footer p-0">
                <table class="table mb-0">
                  <tbody>
                    <tr ngModelGroup="dimension">
                      <td class="col-10"> Total</td>
                      <td style="text-align:center; "><span style="visibility:hidden">xxx</span></td>
                      <td
                        *ngFor="let s of scale"
                        style="text-align:center"
                      >
                        <div class="form-check">
                          <input
                            [(ngModel)]="ngModelArrayForBinding[dim.id]"
                            type="radio"
                            class="form-check-input"
                            name="{{dim.id}}"
                            value="{{s.value}}"
                            (change)="saveDimension(ratingForm, dim.id)"
                          >
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>


          </ng-container>
        </form>
        <div class="row">
          <div class="col">
            <div class="card mb-4">
              <div
                class="card-header"
                [appLayout]="layoutService.baseColorButtons"
              >
                <b i18n>Stärken</b>
                <app-saved-indikator [isSaved]="isSavedService.getIsSaved()"></app-saved-indikator>
              </div>
              <div class="card-body p-0">
                <quill-editor
                  class="w-100"
                  placeholder=""
                  [(ngModel)]="this.strength"
                  (ngModelChange)="cbtest('strength')"
                ></quill-editor>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card mb-4">
              <div
                class="card-header"
                [appLayout]="layoutService.baseColorButtons"
              >
                <b i18n> Entwicklungsfelder</b>
                <app-saved-indikator [isSaved]="isSavedService.getIsSaved()"></app-saved-indikator>
              </div>
              <div class="card-body p-0">
                <quill-editor
                  class="w-100"
                  placeholder=""
                  [(ngModel)]="this.developmentAreas"
                  (ngModelChange)="cbtest('developmentAreas')"
                ></quill-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>