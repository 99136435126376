import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { HttpErrorService } from './http-error.service';

@Injectable()
export class HttpErrorHandlingInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private httpErrorService: HttpErrorService, private toastr: ToastrService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: any, caught: Observable<HttpEvent<any>>) => {
        this.httpErrorService.setError(err);
        if (err.status >= 400) {
          console.warn(err, "HttpStatus Error in Client " + err.status);
        }
        if (err.status === 401 || err.status === 403) {
          if (err.url.includes('profil-m.solutions')) {
            this.authService.logout({ sessionexpired: true });
          }
        }
        return of(err);
      }
      )
    );
  }
}
