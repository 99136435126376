import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { LayoutService } from '../../shared/services/layout.service';
import IpadPackage = PRM.IpadPackage;

@Component({
  selector: 'app-observer-conference',
  templateUrl: './observer-conference.component.html',
  styleUrls: ['./observer-conference.component.css']
})
export class ObserverConferenceComponent {
  public pack: IpadPackage;

  constructor(private aRoute: ActivatedRoute, public layoutService: LayoutService) {
    aRoute.data.subscribe((data) => {
      this.pack = data.pack;
    });
  }
}