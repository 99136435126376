<div class="row mb-3">
  <div class="col mx-5 text-center ">
    <div
      class="d-inline-block "
      [appLayout]="layoutService.baseColorSurface"
      style="border-radius: 20px;height:40px"
    >
      <span style="position: relative; top:16px; ">
        <app-konva-tool-selector
          class="px-1"
          *ngFor="let p of pencils"
          (click)="activatePencil(p)"
          [activePencil]="activePencil"
          [pencil]="p"
          [colors]="colors"
          [sizes]="sizes"
        ></app-konva-tool-selector>
      </span>
    </div>
    <div
      class="d-inline-block ml-2"
      style="border-radius: 20px;height:40px; position: relative; top:2px"
      [appLayout]="layoutService.baseColorSurface"
    >
    <span style="position: relative; top:8px; ">
      <app-ri-button
        [disabled]="this.allCanvas.get(this.currentPage).shapes == 0"
        icon="ri-arrow-go-back-line"
        (click)="undo()"
      ></app-ri-button>
      <app-ri-button
        [disabled]="this.allCanvas.get(this.currentPage).redoShapes == 0"
        icon="ri-arrow-go-forward-line"
        (click)="redo()"
      ></app-ri-button>
    </span>
    </div>
  </div>


</div>
