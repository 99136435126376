import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SocketService } from 'src/app/shared/SocketService';
import { PackService } from '../services/PackService';
import { LayoutService } from './../services/layout.service';

@Component({
  selector: 'app-seconddevice',
  templateUrl: './seconddevice.component.html',
  styleUrls: ['./seconddevice.component.css']
})

export class SeconddeviceComponent implements OnInit {
  public tbid: string;
  public tbname: string;
  public uploadComplete = false;

  constructor(aRoute: ActivatedRoute,
    public layoutService: LayoutService,
    public socketService: SocketService,
    public packService: PackService) {
    document.body.style.overflow = "auto";
    this.tbid = aRoute.snapshot.params.tbid;
    this.getTimeblockNameById();

  }
  ngOnInit(): void {
    document.getElementsByTagName("body")[0].classList.add("second-device")
  }

  public onVideoUploadState(event: any) {
    if (event.status == "complete") {
      this.uploadComplete = true;
    }
  }

  public async getTimeblockNameById() {
    const pack = await this.packService.getCurrentPackage();
    pack.eventdays.forEach(eventday => {
      eventday.events.forEach(event => {
        if (event.tbid === this.tbid) {
          this.tbname = event.timeblock.name;
        }
      })
    });
  }
}