import { AfterViewInit, Component, Input, QueryList } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { KonvaCanvasComponent } from '../konva-canvas.component';
export interface Pencil {
  icon: string;
  color: any;
  thickness: any;
  opacity: number;
  iseraser: boolean;
}
@Component({
  selector: 'app-konva-paintbox',
  templateUrl: './konva-paintbox.component.html',
  styleUrls: ['./konva-paintbox.component.css']
})
export class KonvaPaintboxComponent implements AfterViewInit {
  @Input() allCanvas: QueryList<KonvaCanvasComponent>;
  public colors = ['#FF0014', '#FF7222', '#FFFD3F', '#00B229', '#001FF7', '#FF0EF8', '#FFFFFF', '#000000'];
  public sizes = [2, 5, 10, 20, 25, 30, 35, 40];
  public pencils: Pencil[] = [];
  public activePencil: Pencil;
  @Input() public currentPage: number = 0;

  constructor(public layoutService: LayoutService) {
    this.pencils.push({ icon: 'ri-ball-pen-line', color: '#000000', thickness: 5, opacity: 1, iseraser: false });
    this.pencils.push({ icon: 'ri-mark-pen-line', color: '#FFFD3F', thickness: 40, opacity: 0.3, iseraser: false });
    this.pencils.push({ icon: 'ri-eraser-fill', color: '#000000', thickness: 40, opacity: 1, iseraser: true });
    this.activatePencil(this.pencils[0]);
  }


  undo(){

    this.allCanvas.get(this.currentPage).undo();

  }
  redo(){
    this.allCanvas.get(this.currentPage).redo();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.activatePencil(this.activePencil);
    }, 1);
  }

  activatePencil(p: Pencil) {
    this.activePencil = p;
    this.allCanvas?.forEach(k => {
      k.setPencil(this.activePencil);
    });
  }

  setColor(c: string) {
    this.activePencil.color = c;
  }
}
