<div
    class="alert alert-info m-5"
    *ngIf="viewState == 'initial' "
>
    <h5 i18n="@@browserRecordingPermission">
      Erteilen Sie bitte in dem Browser, in dem Ihnen der QR-Code angezeigt wurde, die Erlaubnis zur Aufnahme.
    </h5>
</div>
<div
    class="alert alert-danger m-5"
    *ngIf="viewState == 'forbidden' "
>
    <h5 i18n="@@browserRecordingPermissionDenied">
      Sie haben der Aufnahme nicht zugestimmt, diese Seite kann geschlossen werden.
    </h5>
</div>
