import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { Pack } from '../services/Pack';
import { PackService } from '../services/PackService';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-mydayeventslist',
  templateUrl: './mydayeventslist.component.html',
  styleUrls: ['./mydayeventslist.component.css']
})
export class MydayeventslistComponent {
  @Input() public dayEvents: PRM.Event[];
  @Input() public viewForRole: string;
  public startDate: string = '';
  public packTimezone: string = 'Europe/Berlin';
  public userTimezone: string = 'Europe/Berlin';
  public pack: Pack;

  constructor(public layoutService: LayoutService, public packService: PackService, private aRoute: ActivatedRoute) {
    aRoute.data.subscribe((data) => {
      this.startDate = data.pack.pack.startDate;
      if (data.pack.pack.timezone) this.packTimezone = data.pack.pack.timezone;
      if (data.pack.pack.me.timezone) this.userTimezone = data.pack.pack.me.timezone;
      this.pack = data.pack.pack
    });
  }
}