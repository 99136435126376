import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { waitSeconds } from '@reflact/tsutil';
import { AuthService } from '../../auth/auth.service';
import { IsSavedService } from '../../shared/services/is-saved.service';
import { LayoutService } from '../../shared/services/layout.service';
import { Rating, RatingService } from './rating.service';
import { Subscription, SubscriptionLike } from 'rxjs';

type ObservationFormResolverPageContext = {
  timeblockId: string,
  userId: string,
  type: 'strength' | 'developmentAreas' | 'notes'
}

@Component({
  selector: 'app-rating-scale',
  templateUrl: './rating-scale.component.html',
  styleUrls: ['./rating-scale.component.css'],
  providers: [RatingService]
})

export class RatingScaleComponent {
  public scale: PRM.ScaleEntry[];
  public dimensionsForTimeBlock: PRM.Dimension[];
  public allparticipants: PRM.Participant[];
  public rating;
  public strength = '';
  public developmentAreas = '';
  public notes = '';
  public ngModelArrayForBinding: any = [];
  public isCollapsed: any = [];
  public selectedeventobject: PRM.Event;
  public debufStoreMap: Map<string, { strength: string, developmentAreas: string, notes: string }> = new Map<string, { strength: string, developmentAreas: string, notes: string }>();
  public debufInterval: any;

  public ratingSubscription: SubscriptionLike
  public obersavationSubscription: SubscriptionLike
  public loggedInObserverId: string


  constructor(
    public aRoute: ActivatedRoute,
    public layoutService: LayoutService,
    public authService: AuthService,
    public ratingService: RatingService,
    public isSavedService: IsSavedService
  ) {
    this.debufInterval = 1000;

    aRoute.data.subscribe((data) => {
      //this.rating = data.rating;
      this.scale = data.pack.pack.scale;
      this.dimensionsForTimeBlock = data.pack.dimensionsForTimeBlock;
      this.allparticipants = data.pack.selectedeventobject.participants;
      this.selectedeventobject = data.pack.selectedeventobject;
      console.log("ME", data.pack.pack.me.id)
      this.loggedInObserverId = data.pack.pack.me.id
      this.createData()
    });


  }
  async createData(): Promise<void> {
    const route = this.aRoute.snapshot
    this.ratingService.setContext(this.loggedInObserverId, route.params.timeblockid, route.params.userid);

    const result = await this.ratingService.fetchObervationFormResult(route.params.timeblockid, route.params.userid) as any

    this.developmentAreas = result.learningArea;
    this.strength = result.strength;
    this.notes = result.notes;

    //console.log("updated obs", result)

    const ratingFromService = await this.ratingService.fetchMyRating(route.params.timeblockid, route.params.userid);

    this.rating = {
      dimensionResult: ratingFromService.dimensionresults,
      timeBlockId: route.params.timeblockid,
      userId: route.params.userid,
    };

    if (this.ratingSubscription != null) {
      this.ratingSubscription.unsubscribe()
    }
    this.ratingSubscription = this.ratingService.$rating.subscribe((particpantResult) => {
      console.log("Update Participant resilt", particpantResult)
      const dimresultUpdateIndex = this.rating.dimensionResult.findIndex((d) =>
        d.timeblockid == particpantResult.timeblockid &&
        d.observerid == particpantResult.observerid &&

        d.dimensionid == particpantResult.dimensionid &&
        d.participantid == particpantResult.participantid)

      if (dimresultUpdateIndex != -1) {
        console.log("Update Dimresult at index", dimresultUpdateIndex)
        console.log("From ", this.rating.dimensionResult[dimresultUpdateIndex])
        console.log("to ", particpantResult)


        this.rating.dimensionResult[dimresultUpdateIndex] = particpantResult
      } else {
        this.rating.dimensionResult.push(particpantResult)
      }
      this.prepareNgModel();
    })

    if (this.obersavationSubscription != null) {
      this.obersavationSubscription.unsubscribe()
    }
    this.obersavationSubscription = this.ratingService.$observation.subscribe((observation) => {

      if (observation && observation.type == "observationformresult") {
        //console.log("Recieved Obsercationform subject", observation)
        this.developmentAreas = observation.value.learningArea;
        this.strength = observation.value.strength;
        this.notes = observation.value.notes;
      }
    })
    this.prepareNgModel();

  }

  prepareNgModel(): void {
    this.ngModelArrayForBinding = [];
    this.dimensionsForTimeBlock.forEach((dim: PRM.Dimension) => {
      this.ngModelArrayForBinding[dim.id] = null;
      this.isCollapsed[dim.id] = true;
      dim.anker.forEach((ank) => {
        this.ngModelArrayForBinding[dim.id + '_XX_' + ank.id] = null;
      }
      );
    });

    this.rating.dimensionResult.forEach((result) => {
      this.ngModelArrayForBinding[result.dimensionid] = '' + result.total;
      if (result.ankerresults != null) {
        result.ankerresults.forEach((ar) => {
          this.ngModelArrayForBinding[result.dimensionid + '_XX_' + ar.ankerid] = '' + ar.value;
        });
      }
    });
  }

  public async saveStrengthAndWeaknessDebuffed() {
    this.debufStoreMap.forEach((value, pageContextString, map) => {
      const pageContext: ObservationFormResolverPageContext = JSON.parse(pageContextString);
      this.saveWithCheck(pageContext, value);
    });
    this.debufStoreMap.clear();
  }

  private async saveWithCheck(pageContext: ObservationFormResolverPageContext, value: any) {
    const result: any = await this.ratingService.saveObervationForm(pageContext.timeblockId, pageContext.userId, pageContext.type, value[pageContext.type]);
    if (result.status == "ok") {
      this.isSavedService.setIsSaved(true);
    } else {
      await waitSeconds(1)
      await this.saveWithCheck(pageContext, value);
    }
  }

  public saveDimension(form: NgForm, dimensionId: string) {
    this.ratingService.saveRating(this.rating.timeBlockId, this.rating.userId, dimensionId, form.value[dimensionId]);
  }

  public cbtest(type: 'strength' | 'developmentAreas' | 'notes') {
    this.isSavedService.setIsSaved(false);
    clearInterval(this.debufInterval);
    this.debufStoreMap.set(
      JSON.stringify({ timeblockId: this.rating.timeBlockId, userId: this.rating.userId, type: type }),
      { strength: this.strength, developmentAreas: this.developmentAreas, notes: this.notes }
    );
    this.debufInterval = setTimeout(this.saveStrengthAndWeaknessDebuffed.bind(this), this.debufInterval);
  }

  public getScaleTooltip(): string {
    let res = '<table class="table-slick">';
    this.scale.forEach(s => res += '<tr class="border-0 "><td class="p-0 pr-2 border-0">' + s.value + '</td><td class="p-0 border-0 pb-2">' + s.name + '</td></tr>')
    res = res.slice(0, -1);
    return res + '</table>';
  }
}