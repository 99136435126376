import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PackService } from './services/PackService';

@Injectable({
  providedIn: 'root'
})
export class ResultconferenceGuard {
  constructor(private packService: PackService, private router: Router) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const pack = await this.packService.getCurrentPackage();
    let resolve = true;
    if (pack.disableReportView) {
      resolve = false;
      this.router.navigateByUrl('/observer/introduction/docs');
    }
    return resolve;
  }
}