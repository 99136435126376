import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PRM } from '@reflact/prmacweb';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ModPrepTimeService {
  constructor(private http: HttpClient, private authService: AuthService) { }

  public async setPrepTimeState(eventId: string, participantId: string, checked: boolean) {
    const url = '/api/preptime/editasmoderator/' + eventId + '/' + participantId + '/' + checked;
    return await firstValueFrom(this.http.get(url));
  }
  public async getPrepTimesAsModerator(): Promise<PRM.PrepTimeEntry[]> {
    const url = '/api/preptime/getasmoderator/';
    return await firstValueFrom(this.http.get<PRM.PrepTimeEntry[]>(url));
  }
  public async getPrepTimeEvents() {
    const url = '/api/preptime/getprepevents';
    return await firstValueFrom(this.http.get<PRM.Event[]>(url));
  }
}