<div
  class="fixed-top text-right"
  style="top: 90px; right: 20px; left: 20px"
>
  <i
    class="ri-close-circle-fill cursor-pointer"
    (click)="this.closeEditor()"
    [ngStyle]="{ color: layoutService.activeColor.backgroundColor }"
  ></i>
</div>

<video
  *ngIf="!isVimeoVideo"
  controls
  controlsList="nodownload"
  disablePictureInPicture
  [src]="'/api/document/get/' + videoId | secure | async"
>
  Your browser does not support the video tag.
</video>
<iframe
  title="Vimeo"
  alt="Vimeo"
  *ngIf="isVimeoVideo"
  [src]="vimeoUrl"
  width="100%"
  height="99%"
  frameborder="0"
  allow="autoplay; fullscreen; picture-in-picture"
  allowfullscreen
></iframe>