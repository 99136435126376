import { Injectable } from '@angular/core';
import Konva from 'konva';
import { Pencil } from './konva-paintbox/konva-paintbox.component';

@Injectable({
  providedIn: 'root'
})
export class ShapeService {
  private currentPencil: Pencil;

  setPencil(p: Pencil) {
    this.currentPencil = p;
  }

  circle() {
    return new Konva.Circle({
      x: 100,
      y: 100,
      radius: 70,
      fill: 'red',
      stroke: 'black',
      strokeWidth: 20,
      draggable: true
    });
  }

  line(pos, mode: string = 'brush') {
    const points = []
    if (!!pos.x && !!pos.y) {
      points.push(pos.x)
      points.push(pos.y)
    }
    return new Konva.Line({
      stroke: this.currentPencil.color,
      opacity: this.currentPencil.opacity,
      strokeWidth: this.currentPencil.thickness,
      lineJoin: 'round',
      globalCompositeOperation: mode === 'brush' ? 'source-over' : 'destination-out',
      points: points,
      draggable: false
    });
  }

  rectangle() {
    return new Konva.Rect({
      x: 20,
      y: 20,
      width: 100,
      height: 50,
      fill: 'green',
      stroke: 'black',
      strokeWidth: 4,
      draggable: true
    });
  }
}