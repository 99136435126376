<div *ngIf="isVideoRecording && is2ndDevice">
    <div
        *ngIf="!isUploading"
        class="text-center"
    >
        <div
            class="btn btn-danger text-center btn-sm mt-3"
            (click)="stopAndUpload()"
            i18n="@@uploadVideoRecording"
        >Aufnahme hochladen</div>
    </div>
</div>

<div [ngClass]="{'d-none':isUploading || !isVideoRecording, 'mt-3': is2ndDevice}">
    <video
        #videoElement
        [muted]="'muted'"
        style="max-width: 900px;"
        class="w-100 m-auto"
        playsinline
        [src]="(!isVideoRecording && videoBlobUrl) ? videoBlobUrl : null"
    ></video>
</div>

<table
    width="100%"
    height="100%"
>
    <tr style="height: 0;">
        <td class="p-3">
            <div *ngIf="!isVideoRecording && !videoBlobUrl && !isUploading">
                <p
                    i18n="@@stopVideoRecordingInformationIos"
                    *ngIf="is2ndDevice"
                >
                    Bitte drücken Sie zur Beendigung des Videos auf den “Aufnahme hochladen” - Button am oberen
                    Bildschirmrand.
                    Starten Sie die Aufnahme, indem Sie auf das Kamerasymbol klicken.
                </p>
                <p
                    i18n="@@stopVideoRecordingInformation"
                    *ngIf="!is2ndDevice"
                >Nach dem Stoppen der Aufnahme startet der Upload automatisch.
                </p>
            </div>
            <div
                *ngIf="this.isUploading"
                class="text-center"
            >
                <img
                    alt="Upload"
                    *ngIf="is2ndDevice"
                    src="assets/icons/video-upload-line.svg"
                    height="50px"
                >
                <img
                    alt="Upload"
                    *ngIf="!is2ndDevice"
                    src="assets/icons/video-upload-line-black.svg"
                    height="50px"
                >
                <h1>{{uploadProgress}}%</h1><br>
                <small *ngIf="retryCount > 0 && this.retryCount != this.maxRetrys">
                    <b>Status: </b>{{uploadStatus}} <span *ngIf="this.retryCount > 0">({{this.retryCount}} /
                        {{this.maxRetrys}})</span>
                </small>
            </div>
        </td>
    </tr>
    <tr>
        <td class="p-3">
            <div *ngIf="!isVideoRecording && !videoBlobUrl && !isUploading">
                <div
                    class="toller-button"
                    *ngIf="this.is2ndDevice"
                >
                    <div
                        class="toller-button-inhalt"
                        (click)="startVideoRecording()"
                    >
                        <img
                            alt="Aufnahme starten"
                            src="assets/icons/vidicon-fill.svg"
                            style="height: 40vw;"
                        >
                    </div>
                </div>
                <div *ngIf="!this.is2ndDevice">
                    <div
                        class="btn btn-danger btn-sm"
                        (click)="startVideoRecording()"
                        i18n="@@videoStartRecording"
                    >Aufnahme starten</div>
                </div>
            </div>
            <div *ngIf="isVideoRecording && !is2ndDevice">
                <div *ngIf="!isUploading">
                    <div
                        class="btn btn-danger text-center btn-sm"
                        (click)="stopAndUpload()"
                    >
                        <span
                            class="text-center"
                            i18n="@@stopAndUploadVideoRecording"
                        >
                            Aufnahme stoppen und hochladen
                        </span>
                    </div>
                </div>
            </div>
            <div
                *ngIf="this.isUploading"
                class="text-center"
            >
                <div *ngIf="uploadProgress == 100">
                    <div class="result-icon">
                        <img
                            alt="Aufnahme abgeschlossen"
                            src="assets/icons/emotion-happy-line.svg"
                            [ngStyle]="{'max-width': !is2ndDevice ? '200px':'none'}"
                        >
                    </div>
                    <small i18n="@@videoSuccessfullyUploaded">Erfolgreich hochgeladen!</small>
                </div>
                <div *ngIf="this.retryCount == this.maxRetrys">
                    <div class="result-icon">
                        <img
                            src="assets/icons/emotion-sad-line.svg"
                            [ngStyle]="{'max-width': !is2ndDevice ? '200px':'none'}"
                        >
                    </div>
                    <small i18n="@@videoUploadFailed">Hochladen fehlgeschlagen!</small><br>
                    <div
                        class="btn btn-primary btn-sm"
                        (click)="retryUpload()"
                        i18n="@@retryAgain"
                    >Erneut versuchen</div>
                </div>
            </div>
        </td>
    </tr>
</table>