import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginNoPrivacyPolicyComponent } from './login-no-privacy-policy/login-no-privacy-policy.component';
import { DocumentviewResolver } from './shared/resolver/DocumentviewResolver';
import { IntroductionViewResolver } from './shared/resolver/IntroductionViewResolver';
import { ObservationFormResolver } from './shared/resolver/ObservationFormResolver';
import { PackContentResolver } from './shared/resolver/PackContentResolver';
import { PageAnnotationResolver } from './shared/resolver/PageAnnotationResolver';
import { ParticipantSummaryNewResolver } from './shared/resolver/ParticipantSummaryNewResolver';
import { ParticipantVideoResolver } from './shared/resolver/ParticipantVideoResolver';
import { RatingResolver } from './shared/resolver/RatingResolver';
import { RouteResolver } from './shared/resolver/RouteResolver';
import { SurveyResultResolver } from './shared/resolver/SurveyResultResolver';
import { VideoResolver } from './shared/resolver/VideoResolver';
import { SeconddeviceAuthComponent } from './shared/seconddeviceauth/seconddeviceauth.component';

const appRoutes: Routes = [
  {
    path: 'connect2nddevice',
    component: SeconddeviceAuthComponent,
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'pv44',
    pathMatch: 'full',
    component: LoginNoPrivacyPolicyComponent
  },
  {
    path: 'observer',
    resolve: {
      pack: PackContentResolver
    },
    loadChildren: () => {
      return import('./observer-view/observer-view.module').then(m => m.ObserverViewModule);
    }
  },
  {
    path: 'participant',
    resolve: {
      pack: PackContentResolver
    },
    loadChildren: () => {
      return import('./parti-view/parti-view.module').then(m => m.PartiViewModule);
    }
  },
  {
    path: '',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { useHash: true, enableTracing: false })
  ],
  exports: [RouterModule],
  providers: [
    PackContentResolver,
    RouteResolver,
    DocumentviewResolver,
    RatingResolver,
    PageAnnotationResolver,
    VideoResolver,
    ParticipantSummaryNewResolver,
    ObservationFormResolver,
    IntroductionViewResolver,
    ParticipantVideoResolver,
    SurveyResultResolver
  ]
})
export class AppRoutingModule {
}
