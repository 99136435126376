import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { PreptimeService } from '../PreptimeService';

@Component({
  selector: 'app-preptime-modal',
  templateUrl: './preptime-modal.component.html',
  styleUrls: ['./preptime-modal.component.css']
})
export class PreptimeModalComponent implements OnInit {
  @Input() event: PRM.Event;
  public onClose: Subject<boolean>;
  constructor(public bsModalRef: BsModalRef, private prepService: PreptimeService, private router: Router) {
  }

  ngOnInit(): void {
    this.onClose = new Subject();
  }
  startPrepTime() {
    this.prepService.startPrepTime(this.event.id);
    this.onClose.next(true);
    this.bsModalRef.hide();
  }

}
