import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';

@Pipe({
  name: 'timezone'
})
export class TimezonePipe implements PipeTransform {
  constructor() {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.extend(advancedFormat);

  }

  transform(inputTime: string, ...args: any[]): string {
    if (args.length != 5) return "Wrong arguments for pipe"
    const startDate: string = args[0] as string;
    const day: number = args[1] as number;
    const targetTimezone: string = args[2] as string;
    const inputTimezone: string = args[3] as string;
    const showTimezone: boolean = args[4] ?? false

    const parsedDate = dayjs(startDate + ' ' + inputTime).add(day - 1, 'days').tz(inputTimezone, true).tz(targetTimezone);
    let displayFormat: string = "HH:mm";

    if (!targetTimezone.toLowerCase().includes("europe")) {
      displayFormat = "hh:mm A";
    }
    if (showTimezone) {
      displayFormat += " (z)";
    }
    return parsedDate.format(displayFormat);
  }
}