import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { SocketService } from 'src/app/shared/SocketService';
import { ModPrepTimeService } from './mod-prep-time.service';

@Component({
  selector: 'app-preptimes',
  templateUrl: './preptimes.component.html',
  styleUrls: ['./preptimes.component.css']
})
export class PreptimesComponent {
  pack: PRM.IpadPackage;
  events: any;
  entries: PRM.PrepTimeEntry[];
  prepTimeblocks: any[] = [];
  prepEvents: PRM.Event[] = [];
  participantIdToPrepEvents: Map<string, PRM.Event[]>;
  participantIdToParticipantname: Map<string, string>;
  timeblockIdToTimeblockname: Map<string, string>;
  prepEntryMap: Map<string, PRM.PrepTimeEntry>;

  constructor(private service: ModPrepTimeService, private aRoute: ActivatedRoute, private socket: SocketService) {
    aRoute.data.subscribe(data => {
      this.participantIdToPrepEvents = new Map<string, PRM.Event[]>();
      this.pack = data.pack.pack;
      this.entries = data.pack.preptimes;
      this.prepEvents = data.pack.prepevents.sort((a: PRM.Event, b: PRM.Event) => {
        const dayDiff = a.day - b.day;
        if (dayDiff !== 0) return dayDiff;
        return a.start.localeCompare(b.start);
      });
      this.prepTimeblocks = this.prepEvents.map(pe => pe.timeblock);
      this.participantIdToParticipantname = new Map(this.pack.allparticipants.map(p => [p.id, p.fullNameWithIndex]));
      this.timeblockIdToTimeblockname = new Map(this.prepTimeblocks.map(t => [t.id, t.name]));
      this.prepEntryMap = new Map(this.entries.map(e => [e.eventid + '' + e.participantid, e]));
      this.pack.allparticipants.forEach(p => {
        this.participantIdToPrepEvents.set(p.id, this.prepEvents.filter(e => e.resourceId === p.id));
      });
    });
    this.socket.socket.on('preptimesupdate', this.updatePrepEntries.bind(this));
  }

  private async updatePrepEntries() {
    const newEntries: PRM.PrepTimeEntry[] = await this.service.getPrepTimesAsModerator();
    this.entries = newEntries;
    this.prepEntryMap = new Map(this.entries.map(e => [e.eventid + '' + e.participantid, e]));
  }

  public compareDate(end: string) {
    return new Date(end) < new Date();
  }

  public switchChanged(checked: boolean, participantId: string, eventId: string) {
    this.service.setPrepTimeState(eventId, participantId, checked);
  }
}