import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthRoutingModule } from '../auth/auth-routing.module';
import { LoginNoPrivacyPolicyComponent } from './login-no-privacy-policy.component';


@NgModule({
  declarations: [ LoginNoPrivacyPolicyComponent ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class LoginNoPrivacyPolicyModule {
}
