import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from '../app.component';
import { DocumentContentRendererComponent } from '../observer-view/document-content-renderer/document-content-renderer.component';
import { DocumentViewComponent } from '../observer-view/document-view/document-view.component';
import { SettingsComponent } from '../observer-view/settings/settings.component';
import { VideoShareComponent } from '../observer-view/video-share/video-share.component';
import { DocumentAccessGuard } from '../shared/DocumentAccess.guard';
import { DisableVideoFeedbackGuard } from '../shared/disableVideoFeedback.guard';
import { DocumentContentRendererSingleDocoldComponent } from '../shared/document-content-renderer-single-docold/document-content-renderer-single-docold.component';
import { DocumentContentRendererSingleVideoComponent } from '../shared/document-content-renderer-single-video/document-content-renderer-single-video.component';
import { IntroductionListComponent } from '../shared/introduction-list/introduction-list.component';
import { DocumentviewResolver } from '../shared/resolver/DocumentviewResolver';
import { InitPrepTimeServiceResolver } from '../shared/resolver/InitPrepTimeServiceResolver';
import { IntroductionViewResolver } from '../shared/resolver/IntroductionViewResolver';
import { PackContentResolver } from '../shared/resolver/PackContentResolver';
import { PageAnnotationResolver } from '../shared/resolver/PageAnnotationResolver';
import { ParticipantVideoResolver } from '../shared/resolver/ParticipantVideoResolver';
import { SurveyResultResolver } from '../shared/resolver/SurveyResultResolver';
import { VideoResolver } from '../shared/resolver/VideoResolver';
import { SeconddeviceComponent } from '../shared/seconddevice/seconddevice.component';
import { VideoComponent } from '../shared/video/video.component';
import { PartiViewComponent } from './parti-view.component';
import { SurveyComponent } from './survey/survey.component';


const routes: Routes = [

  {
    path: '',
    component: PartiViewComponent,
    resolve: {
      init: InitPrepTimeServiceResolver
    },
    children: [
      {
        path: 'introduction',
        component: AppComponent,
        children: [
          {
            path: 'docs',
            component: IntroductionListComponent,
            resolve: {
              pack: PackContentResolver
            },
          },
          {
            path: 'docs/video/:selectedvideo',
            component: DocumentContentRendererSingleVideoComponent,
            resolve: {
              video: VideoResolver
            },
          },
          {
            path: 'docs/:selecteddoc/:page',
            component: DocumentContentRendererSingleDocoldComponent,
            resolve: {
              pack: IntroductionViewResolver
            }
          },
        ]
      },
      {
        path: 'documentview',
        redirectTo: 'documentview/0',
        pathMatch: 'full'
      },

      {
        path: 'documentview/:day',
        component: DocumentViewComponent,
        resolve: {
          pack: DocumentviewResolver
        },

        children: [
          {
            path: ':selectedevent',
            redirectTo: ':selectedevent/docs',
            pathMatch: 'full'
          },
          {
            path: ':selectedevent/docs',
            component: DocumentContentRendererComponent,
            resolve: {
              pack: DocumentviewResolver
            },
          },

          {
            path: ':selectedevent/docs/video/:selectedvideo',
            component: DocumentContentRendererSingleVideoComponent,
            resolve: {
              video: VideoResolver
            },
          },
          {
            path: ':selectedevent/docs/:selecteddoc/:page/',

            canActivate: [DocumentAccessGuard],
            component: DocumentContentRendererSingleDocoldComponent,
            resolve: {
              pack: DocumentviewResolver
            },

          },

          {
            path: ':selectedevent/docs/:selecteddoc/:page/:userid',
            component: DocumentContentRendererSingleDocoldComponent,
            resolve: {
              pack: PageAnnotationResolver
            },
          },
          {
            path: ':selectedevent/selfestimation',
            component: VideoComponent,
            resolve: {
              pack: DocumentviewResolver,
              videos: ParticipantVideoResolver
            }
          },
          {
            path: ':selectedevent/selfrecording',
            canActivate: [DisableVideoFeedbackGuard],
            component: VideoComponent,
            resolve: {
              pack: DocumentviewResolver,
              videos: ParticipantVideoResolver
            }
          },


          {
            path: ':selectedevent/survey/:surveyid',
            component: SurveyComponent,
            resolve: {
              pack: DocumentviewResolver,
              surveyresult: SurveyResultResolver
            }
          }
        ]
      },
      {
        path: 'settings',
        component: SettingsComponent,
        resolve: {
          pack: PackContentResolver
        },
      },
      {
        path: 'videoshare',
        component: VideoShareComponent,
        resolve: {
          pack: PackContentResolver
        },
      }
    ]
  },
  {
    path: '2nddevice/:tbid',
    canActivate: [DisableVideoFeedbackGuard],
    component: SeconddeviceComponent,
    resolve: {
      pack: DocumentviewResolver,
      videos: ParticipantVideoResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PartiRoutingModule { }
