import { HttpClient } from '@angular/common/http';
import { Pipe, type PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { firstValueFrom } from 'rxjs';

@Pipe({
  name: 'secure'
})
export class SecurePipe implements PipeTransform {
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  transform(url: string): Promise<SafeUrl> {
    const blob = firstValueFrom(this.http.get(url, { responseType: 'blob' })).then(blob => {
      return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
    });
    return blob;
  }
}