import { ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { RatingService } from '../../observer-view/rating-scale/rating.service';

@Injectable()
export class ObservationFormResolver {
  constructor(private ratingService: RatingService) { }

  async resolve(route: ActivatedRouteSnapshot) {
    const result = await this.ratingService.fetchObervationFormResult(route.params.timeblockid, route.params.userid);
    return { ...result };
  }
}