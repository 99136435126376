<div
  class="row p-0 m-0"
  *ngIf="pack && pack.disableVideofeedback === 'normal' && feedbackForVideos != null && feedbackForVideos.length > 0"
>
  <div
    class="col  p-4 overflow-auto"
    style="background-color: #ffffff"
    [style.height]="'calc(100vh - '+layoutService.headerHeight +')'"
  >
    <div
      *ngFor="let feedbackVid of feedbackForVideos"
      class="row mb-5"
    >
      <div class="  col-12  col-sm-5">
        <video
          controls
          width="100%"
          [src]="videoService.playVideo(feedbackVid._id)"
          controlsList="
          nodownload"
          disablePictureInPicture
        ></video>
      </div>
      <div class="col">
        <table>
          <tr>
            <td>
              <app-user-image-and-name-display
                [participant]="feedbackVid.participantObject"
                [showName]="false"
              ></app-user-image-and-name-display>
            </td>
            <td>
              {{feedbackVid.participantObject.fullNameWithIndex}}
              <div class="small">
                {{this.dayjs(feedbackVid.uploadDate).locale(this.pack.lang).fromNow()}}
              </div>
            </td>

          </tr>
        </table>

        <ng-container *ngIf="feedbackVid.metadata.invitationmessage">
          <table class="my-3">
            <tr>
              <td>
                <i
                  class="ri-message-2-fill mr-2"
                  [appLayout]="layoutService.activeColor"
                  style="transform: scaleX(-1)"
                  [appLayoutColorFlip]="true"
                ></i>
              </td>
              <td>{{feedbackVid.metadata.invitationmessage}}
              </td>
            </tr>
          </table>

        </ng-container>
        <div><span i18n="@@participantFeedbackComment">Mein Kommentar</span>:</div>

        <textarea
          class="form-control"
          rows="5"
          [(ngModel)]="feedbackVid.ratingComment"
        ></textarea>
        <div>
          <button
            [appLayout]="layoutService.baseColorButtons"
            class="btn btn-sm mt-2"
            (click)="saveRating(feedbackVid)"
            [ngClass]="{'disabled': feedbackVid.check}"
            i18n="@@globalSend"
          >Senden</button>
          <i
            *ngIf="feedbackVid.check"
            class="ml-3 ri-check-line"
            style="color: green; vertical-align: middle"
          ></i>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template
  [ngIf]="pack && pack.disableVideofeedback !== 'normal' || pack && (feedbackForVideos== null || feedbackForVideos.length === 0)"
>
  <div class="no-videos-container">
    <span i18n="@@noVideoFeedbacksAvailable">Es sind derzeit keine Video Feedbacks verfügbar</span>
  </div>
</ng-template>