import {Injectable} from '@angular/core';
import relativeLuminance from 'relative-luminance';

@Injectable({
  providedIn: 'root'
})

export class WcagService {
  private hexChars = '';
  private match3or4Hex = ``;
  private match6or8Hex = ``;

  constructor() {

    this.hexChars = 'a-f\\d';
    this.match3or4Hex = `#?[${this.hexChars}]{3}[${this.hexChars}]?`;
    this.match6or8Hex = `#?[${this.hexChars}]{6}([${this.hexChars}]{2})?`;


  }

  public hexRgb(hex): number[] {
    hex = hex.replace(/^#/, '');
    let alpha = 255;

    if (hex.length === 8) {
      alpha = parseInt(hex.slice(6, 8), 16);
      hex = hex.substring(0, 6);
    }

    if (hex.length === 4) {
      alpha = parseInt(hex.slice(3, 4).repeat(2), 16);
      hex = hex.substring(0, 3);
    }

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    const num = parseInt(hex, 16);
    // tslint:disable:no-bitwise
    const red = num >> 16;
    const green = (num >> 8) & 255;
    const blue = num & 255;
    return [red, green, blue, alpha];
  }

  /**
   * Get the contrast ratio between two relative luminance values
   * @param number a luminance value
   * @param number b luminance value
   * @returns number contrast ratio
   * @example
   * luminance(1, 1); // = 1
   */
  public luminance(a, b): number {
    const l1 = Math.max(a, b);
    const l2 = Math.min(a, b);
    return (l1 + 0.05) / (l2 + 0.05);
  }

  /*
  * @example
  * rgb([0, 0, 0], [255, 255, 255]); // = 21
  */
  public rgb(a, b): number {
    return this.luminance(relativeLuminance(a), relativeLuminance(b));
  }

  /*
  * hex('#000', '#fff'); // = 21
  */
  public hex(a: string, b: string): number {
    return this.rgb(this.hexRgb(a), this.hexRgb(b));

  }

  /**
   * Get a textual score from a numeric contrast value
   * @param number contrast
   * @returns string score
   * @example
   * score(10); // = 'AAA'
   */
  public score(contrast): string {
    if (contrast >= 7) {
      return 'AAA';
    }

    if (contrast >= 4.5) {
      return 'AA';
    }

    if (contrast >= 3) {
      return 'AA Large';
    }

    return 'Fail';
  }
}
