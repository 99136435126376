<span
  *ngIf="layoutService.nameComponents"
  class="alert alert-info"
>participantsummary.component.html</span>

<div class="row mb-5">
  <div class="col p-0">
    <div
      class="pt-4 col-12"
      [appLayout]="layoutService.baseColorSurface"
    >
      <div class="row p-0 mb-3">
        <div class="col mx-4">
          <table class="mb-5">
            <tr>
              <td class="pr-5">
                <app-user-image-and-name-display [participant]="participant"></app-user-image-and-name-display>
              </td>

              <td class="pr-5">
                <div class="d-flex">
                  <i
                    class="ri-notification-badge-fill pr-2"
                    style="line-height: 24px"
                    [appLayout]="layoutService.textColor50"
                  ></i>
                  <span style="line-height: 24px">{{ pack.name }}</span>
                </div>
              </td>

              <td style="height: 50px"></td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row pb-2">
        <div class="col text-white mx-4 my-2">
          <h4 i18n>Bewertung</h4>
        </div>
        <div class="col text-white mx-4 my-2 text-right">
          <h4 (click)="showFieber = !showFieber"></h4>
          <div
            class="btn-group switcher"
            role="group"
            *ngIf="!showFieber"
          >
            <button
              (click)="showFieber = false"
              type="button"
              class="btn btn-secondary"
              [appLayout]="layoutService.activeColor"
            >
              <i class="ri-table-line"></i>
            </button>
            <button
              (click)="showFieber = true"
              type="button"
              class="btn btn-secondary"
              [appLayout]="layoutService.baseColorButtons"
            >
              <i class="ri-line-chart-line"></i>
            </button>
          </div>
          <div
            class="btn-group switcher"
            role="group"
            *ngIf="showFieber"
          >
            <button
              (click)="showFieber = false"
              type="button"
              class="btn btn-secondary"
              [appLayout]="layoutService.baseColorButtons"
            >
              <i class="ri-table-line"></i>
            </button>
            <button
              (click)="showFieber = true"
              type="button"
              class="btn btn-secondary"
              [appLayout]="layoutService.activeColor"
            >
              <i class="ri-line-chart-line"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="row pb-2">
        <div class="col mx-4 my-2">
          <ng-container *ngTemplateOutlet="fieberkurve"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END TOPPER-->

<div class="row text-left">

  <div
    class="col-12 px-5"
    *ngFor="let event of this.events"
  >
    <app-summary-timeblock
      [event]="event"
      [mycomments]="mycomments"
    ></app-summary-timeblock>

    <div
      class="mt-5 mx-n5 mb-5"
      style="border-bottom: 1px solid #000"
    ></div>
  </div>

  <div class="row text-left pt-2">
    <div
      class="col-6"
      *ngIf="totalRating && totalRating.strength"
    >
      <div class="card mb-2">
        <div
          i18n
          class="card-header"
        >Stärken</div>
        <div class="card-body">
          {{ totalRating.strength }}
        </div>
      </div>
    </div>
    <div
      class="col-6"
      *ngIf="totalRating && totalRating.learningArea"
    >
      <div class="card mb-2">
        <div
          i18n
          class="card-header"
        >Entwicklungsfelder</div>
        <div class="card-body">
          {{ totalRating.learningArea }}
        </div>
      </div>
    </div>
    <div
      class="col-12"
      *ngIf="totalRating && totalRating.devmeasures"
    >
      <div class="card mb-2">
        <div
          i18n
          class="card-header"
        >Entwicklungsmaßnahmen</div>
        <div class="card-body">
          {{ totalRating.devmeasures }}
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #fieberkurve>
  <table
    class="fieberkurve w-100 mb-4"
    #fieberTable
  >
    <tr style="height:52px">
      <td
        [ngStyle]="
        {'width': layoutService.fieberKurveDimensinCellWidth  + 'px',
       'border-left-style': 'none'
      }"
        class="cursor-default"
      ></td>
      <!-- HEADER FÜR TIMEBLOCK -->
      <ng-container *ngIf="!showFieber">
        <td
          class="p-3 text-center cursor-default text-break"
          *ngFor="let event of this.events"
          [appLayout]="layoutService.baseColorButtons"
        >
          {{ event.timeblock.name }}
        </td>
      </ng-container>
      <!-- HEADER FÜR KURVE -->
      <ng-container *ngIf="this.showFieber">
        <td
          class="p-3 text-center cursor-default"
          [colSpan]="getScaleForStep1(pack.scale).length"
          [rowSpan]="ratingSummary.dimensions.length+1"
          style="padding: 0px !important; background-color: transparent !important; border-left-style:none;"
          id="canvasContainer"
        >
          <canvas
            id="chartCanvas"
            #thechart="base-chart"
            baseChart
            class="chart"
            [datasets]="getchartData()"
            [options]="getChartconfig()"
            type="line"
            [height]="80+(ratingSummary.dimensions.length) * 40"
            [width]="800"
            style="background-color:#232E55; padding-top: 30px; width: 100%;"
          >
          </canvas>
        </td>
      </ng-container>
      <td
        i18n
        [appLayout]="layoutService.baseColorButtons"
        class="p-3 text-center cursor-default text-break"
        [ngStyle]="{'width': layoutService.fieberKurveGesamtCellWidth + 'px'}"
      >
        Gesamt
      </td>
    </tr>
    <tr *ngFor="let dimension of ratingSummary.dimensions; let i = index">

      <td
        class="text-white pl-0 pr-3 py-0"
        [ngStyle]="{
          'border': '1px solid ' + layoutService.baseColorButtons.backgroundColor,
          'width': layoutService.fieberKurveDimensinCellWidth + 'px',
          'border-left-style':'none !important',
          'border-left':'0px'
      }"
        style="border-left: 0px;height: 52px;overflow: hidden; border-left-style:none !important;"
      >
        {{ dimension.name }}
      </td>
      <ng-container *ngIf="!showFieber">
        <!-- CONTENT OHNE FIEBER-->

        <td
          *ngFor="let event of this.events"
          class="dimensionTotal pt-3 pb-3 text-center"
          [ngStyle]="{'border': '1px solid ' + layoutService.baseColorButtons.backgroundColor}"
          [ngClass]="{
              disabledcell: !getIsDisabled(event.timeblock._id, dimension)
            }"
        >
          <div
            *ngIf="!getIsDisabled(event.timeblock._id, dimension)"
            class="striped"
            style="width:50%;margin:0px auto"
          >
            &nbsp;
          </div>

          <div
            *ngIf="getScaleValue(event.timeblock._id, dimension.id) != null"
            [ngStyle]="{'background-color': layoutService.scoreColors[0].backgroundColor}"
            style="border-radius: 25px; margin:0px auto; width:50%;"
          >

            {{ getScaleValue(event.timeblock._id, dimension.id) }}
          </div>
        </td>
      </ng-container>
      <!-- FIEBER KURVE CONTENT -->

      <td
        class="dimensionTotal pt-3 pb-3 text-center"
        [ngStyle]="{'border': '1px solid ' + layoutService.baseColorButtons.backgroundColor}"
        style="border-right: 0px"
      >
        <div
          *ngIf="getDimensionTotalValue(dimension) !== null"
          [ngStyle]="{'background-color': layoutService.scoreColors[0].backgroundColor}"
          style="border-radius: 25px; margin:0px auto; width:50%;"
        >
          {{ getDimensionTotalValue(dimension) | number: '1.1-2' }}
        </div>
      </td>
    </tr>
  </table>
</ng-template>