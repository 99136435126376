import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { io, Socket } from "socket.io-client";
export type PrmSocket = Socket<any, any>

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  public error$: Subject<any> = new Subject();
  private static socket_: PrmSocket
  private static isConnected: boolean = false;

  private static wasDisconnected: boolean = false;

  constructor(public toastr: ToastrService) {
    if (/Android|iPhone|iPod/i.test(navigator.userAgent)) {
      toastr.toastrConfig.positionClass = 'd-none';
    }
  }

  public get socket(): PrmSocket {
    if (!SocketService.socket_) {
      SocketService.socket_ = io('//');
      SocketService.socket_.on("error", (data) => {
        this.error$.next(data);
        console.error("Error recieved from server", data)
      })
      SocketService.socket_.on("connect", () => {
        if (SocketService.wasDisconnected) {
          window.location.reload();
        }
        SocketService.isConnected = true
        this.toastr.success("Connected")

      })
      SocketService.socket_.on("disconnect", () => {
        SocketService.isConnected = false
        SocketService.wasDisconnected = true
        this.toastr.error("Server Disconnected")

      })
      SocketService.socket_.on("death", () => {
        localStorage.removeItem('webapptoken');
        localStorage.removeItem('prmwebapplasturl');
        window.location.reload();
      })
    }
    return SocketService.socket_;
  }

  public get connected(): boolean {
    return SocketService.isConnected
  }
}