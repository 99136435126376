import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { PRM } from '@reflact/prmacweb';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UploadState, UploadxOptions, UploadxService } from 'ngx-uploadx';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { VideoService } from 'src/app/shared/video/video.service';

@Component({
  selector: 'app-moduploadvideo',
  templateUrl: './moduploadvideo.component.html',
  styleUrls: ['./moduploadvideo.component.css'],
  providers: [UploadxService]
})
export class ModuploadvideoComponent implements OnInit {
  @Input() public participant: PRM.IpadParticipant;
  @Input() public ev: PRM.Event;
  @Input() public pack: PRM.IpadPackage;

  public modalRef?: BsModalRef;
  public videoid: string = "AAA"
  public uploadProgress: number;
  public uploadStatus: string;
  public isUploading = false;
  public selected: boolean = false;
  public fileName = '';

  public uploadxOptions: UploadxOptions = {
    allowedTypes: 'video/mp4',
    concurrency: 2,
    endpoint: '/api/upload/participantvideoasobserver',

    metadata: {
      ipadid: "",
      observerpin: "",
      participantid: "",
      videotype: "selfRecording",
      timeblockid: ""
    }
  };

  constructor(private authService: AuthService, private http: HttpClient, public videoService: VideoService, private modalService: BsModalService, public uploadService: UploadxService) { }

  ngOnInit(): void {
    this.uploadxOptions.metadata["observerpin"] = this.pack.me.pin
    this.uploadxOptions.metadata["participantid"] = this.participant.id
    this.uploadxOptions.metadata["ipadid"] = this.authService.getAuthData().iPadId
    this.uploadxOptions.metadata["timeblockid"] = this.ev.tbid
    this.updateExistingVideoFile()


    this.uploadService.init({}).subscribe((state: UploadState) => {
      this.uploadProgress = state.progress || 0
      this.uploadStatus = state.status;
      if (this.uploadStatus == 'complete') {
        this.updateExistingVideoFile()
        this.isUploading = false;
      }
    })
  }

  public async deleteVideo() {

    await firstValueFrom(this.http.delete("/api/participantvideo/" + this.videoid));

    this.modalRef.hide()
    this.updateExistingVideoFile();
  }

  public async updateExistingVideoFile() {

    const existingVideo: any = await firstValueFrom(this.http.get("/api/participantvideoasobserver/" + this.ev.tbid + "/" + this.participant.id));
    if (existingVideo != null) {
      this.videoid = existingVideo._id
    } else {
      this.videoid = ""
    }
  }

  public onState(e) { }

  public onStateChanged(newState: UploadState) {
    if (newState.status == "uploading") this.isUploading = true;
  }

  public openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
  }
}