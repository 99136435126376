<!-- navbar -->
<app-header
  brand=""
  brandlink=""

> </app-header>
<div class="container-fluid overflow-auto" id="scrollingTag" style="border:0px solid red; " >
  <div class="row">
    <div class="col p-0">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
