<div
  class="login-light pb-5 mb-5"
  style="overflow-y: scroll"
>
  <form
    #authForm="ngForm"
    (ngSubmit)="onSubmit(authForm)"
    class="pt-2"
  >
    <div
      class="form-row mt-0"
      style="margin: 10px; "
    >
      <div class="col-auto text-center">
        <img
          alt="Profil M"
          src="assets/ProfilM_Logo.png"
          width="50%"
          style="padding: 10px"
        />
      </div>
    </div>

    <div class="form-group">
      <div class="row">
        <div
          class="col-12 alert alert-danger"
          *ngIf="err && (err.status === 401 || err.status === 403)"
        >
          <span
            *ngIf="err.status === 401"
            i18n
          >Anfrage wurde nicht akzeptiert.</span>
          <span
            *ngIf="err.status === 403"
            i18n
          >Bitte geben Sie einen gültigen Pin ein</span>
        </div>
        <div
          class="col-12 alert alert-warning"
          *ngIf="this.waitingForLoginValidation"
        >
          <span
            i18n
            *ngIf="!this.factor2mail"
          >Freischaltung bei Moderator angefragt. Bitte warten.</span>
          <span
            i18n
            *ngIf="this.factor2mail"
          >Ein Code für die Zugangsfreischaltung wurde an deine E-Mail-Adresse verschickt.</span>
        </div>
      </div>
      <p
        style="color:#000; font-weight: bold;"
        class="text-center mb-0 pb-2 "
        i18n="@@authPinText"
      >Individuellen Pin eingeben</p>

      <table class="m-auto mb-4">
        <tr>
          <td>
            <input
              pattern="[0-9]*"
              inputmode="numeric"
              id="pin1"
              #pin1
              name="pin1"
              type="number"
              class="form-control pin"
              maxlength="1"
              minlength="1"
              autocomplete="off"
              required
              ngModel
              (keyup)="processKeyUp($event, pin1, pin2)"
              (paste)="onPaste($event)"
            />
          </td>
          <td>
            <input
              inputmode="numeric"
              id="pin2"
              #pin2
              name="pin2"
              type="number"
              class="form-control pin"
              maxlength="1"
              minlength="1"
              autocomplete="off"
              required
              ngModel
              (keyup)="processKeyUp($event, pin1, pin3)"
              (paste)="onPaste($event)"
            />
          </td>
          <td>
            <input
              inputmode="numeric"
              id="pin3"
              #pin3
              name="pin3"
              type="number"
              class="form-control pin"
              maxlength="1"
              minlength="1"
              autocomplete="off"
              required
              ngModel
              (keyup)="processKeyUp($event, pin2, pin4)"
              (paste)="onPaste($event)"
            />
          </td>
          <td>
            <input
              inputmode="numeric"
              id="pin4"
              #pin4
              name="pin4"
              type="number"
              class="form-control pin"
              maxlength="1"
              minlength="1"
              autocomplete="off"
              required
              ngModel
              (keyup)="processKeyUp($event, pin3, pin5)"
              (paste)="onPaste($event)"
            />
          </td>
          <td>
            <input
              inputmode="numeric"
              id="pin5"
              #pin5
              name="pin5"
              type="number"
              class="form-control pin"
              maxlength="1"
              minlength="1"
              autocomplete="off"
              required
              ngModel
              (keyup)="processKeyUp($event, pin4, pin6)"
              (paste)="onPaste($event)"
            />
          </td>
          <td>
            <input
              inputmode="numeric"
              id="pin6"
              #pin6
              name="pin6"
              type="number"
              class="form-control pin"
              maxlength="1"
              minlength="1"
              autocomplete="off"
              required
              ngModel
              (keyup)="processKeyUp($event, pin5, login)"
              (paste)="onPaste($event)"
            />
          </td>
        </tr>
      </table>
    </div>

    <div
      class="row m-auto"
      style="max-width: 310px;"
    >
      <div class="col-auto">
        <i
          class="ri-checkbox-blank-line"
          style="cursor: pointer; color:#000"
          *ngIf="!dsgvo"
          (click)="dsgvo = !dsgvo"
        ></i>
        <i
          class="ri-checkbox-line"
          style="cursor: pointer; color:#000"
          *ngIf="dsgvo"
          (click)="dsgvo = !dsgvo"
        ></i>
      </div>
      <div class="col-auto">
        <div
          class="small "
          style="
          color:#000;
            position: relative;
            left: 30px;
            top: -30px;
            margin-bottom: -30px;
          "
        >
          Hiermit bestätige ich, dass ich die
          <a
            href="https://www.profil-m.de/ac-suite-datenschutz/"
            target="_blank"
          >Datenschutzhinweise</a>
          akzeptiere.
          <br>I confirm that I agree to the<br>
          <a
            href="https://www.profil-m.de/data-protection-information-for-the-use-of-the-ac-suite/?lang=en"
            target="_blank"
          >terms of privacy policy</a>.
        </div>
      </div>
    </div>
    <div class="form-group ">
      <button
        id="login"
        #login
        i18n
        class="btn btn-outline-primary btn-block  mx-auto  py-1 mt-4"
        style="max-width:150px"
        type="submit"
        [disabled]="this.getFullPin().length < 6|| !dsgvo || this.factor2mail || this.waitingForLoginValidation"
      >
        Login</button>
    </div>
  </form>
</div>
<div
  class="position-absolute"
  style="bottom: 5px; left: 5px;"
>
  <small>
    Frontend: {{ versions?.frontend }} | Backend: {{ versions?.backend }}
  </small>
</div>