import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { first } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { PreptimeService } from '../PreptimeService';
import { PreptimeModalComponent } from '../preptime-modal/preptime-modal.component';
import { LayoutService } from '../services/layout.service';
import { VimeoService } from '../services/vimeo.service';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.css']
})
export class DocumentListComponent {
  @Input() public documents: PRM.Document[] = [];
  @Input() public docsPerRow: 3 | 4 | 6 = 4;
  @Input() selectedEvent: PRM.Event;

  bsModalRef: BsModalRef;

  constructor(
    public layoutService: LayoutService,
    public authService: AuthService,
    private router: Router,
    private prepService: PreptimeService,
    private modalService: BsModalService,
    public vimeoService: VimeoService,
    public http: HttpClient) {
  }

  public getDownloadLink(docid: string, eventid: string) {
    eventid = ((this.selectedEvent != null && this.selectedEvent._id !== '') ? eventid : 'introduction')
    let participantid = (this.selectedEvent?.resourceId != null) ? this.selectedEvent.resourceId : "-1"
    // eventid of documents in introduction view is always 'introduction' to persist notes created in documents
    // while in introduction
    let c = 0;
    return '/api/downloadPdf/' + docid + '/' + eventid + '/' + participantid;
  }

  public isAccessable() {
    if (!this.router.url.includes('participant') || !this.selectedEvent || !this.selectedEvent.timeblock.prepTime) {
      return true;
    }
    const prepEnd = new Date(this.prepService.getEndtimeForEventId(this.selectedEvent.id).endtime);
    return ((prepEnd.getTime() - new Date().getTime() - this.prepService.getDiff()) / 1000) >= 0;
  }

  public docsAreLocked() {
    if (!this.selectedEvent) {
      return false;
    }
    if (!this.prepService.hasEventId(this.selectedEvent.id)) {
      return false;
    }
    const prepEnd = new Date(this.prepService.getEndtimeForEventId(this.selectedEvent.id).endtime);
    return ((prepEnd.getTime() - new Date().getTime() - this.prepService.getDiff()) / 1000) < 0;
  }

  public exitClick(ev) {
    ev.stopPropagation();
  }

  public documentClick(docElement: HTMLElement) {
    if (this.router.url.includes('participant') && this.selectedEvent && !this.prepService.hasEventId(this.selectedEvent.id) && !this.isAccessable()) {
      this.bsModalRef = this.modalService.show(PreptimeModalComponent, { initialState: { event: this.selectedEvent } });
      this.bsModalRef.content.onClose.subscribe(async result => {
        if (result) {
          this.prepService.mapChanged.pipe(first()).subscribe(r => {
            docElement.click();
          });
        }
      });
    }
  }

  getRouterlinkToSingleDocView(doc: PRM.IpadDocument) {
    if (!this.isAccessable()) {
      return [];
    }
    if (doc.contentType === 'application/pdf') {
      const r = [doc._id, '0'];
      if (doc.metadata.participantInstanceEnabled) {
        if (doc.metadata.participantObject) {
          r.push(doc.metadata.participantObject.id);
        }
      }
      return r;
    } else if (doc.contentType === 'video/mp4' || (doc.contentType === 'text/plain' && doc.metadata.type === 'vimeo')) {
      return ['video', doc._id];
    }
  }

}
