import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(private authService: AuthService, private http: HttpClient) { }

  public emailSelfestimation(email: string) {
    const url = '/api/survey/emailselfestimation/' + email;
    return firstValueFrom(this.http.get(url));
  }

  public anonymiseParticipantData() {
    const url = '/api/package/anonymiseParticipantData';
    return firstValueFrom(this.http.get(url));
  }

  async changeAuthAccess(packid: string, access: 'liveauth' | 'disabled') {
    const url = `/api/changeauthbymoderator/${packid}`;

    return firstValueFrom(this.http.post(url, { accessValidation: access }))
  }
}