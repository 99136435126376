import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { LayoutService } from './shared/services/layout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'prm-ac-web';

  constructor(
    private router: Router,
    private authService: AuthService,
    public layoutService: LayoutService,
    public ar: ActivatedRoute
  ) {
    this.router.events.subscribe((ev) => {


      if (ev instanceof NavigationEnd) {
        const splittedUrl = ev.urlAfterRedirects.split('/');
        if (!splittedUrl.includes('2nddevice') &&
          !splittedUrl.includes('connect2nddevice') &&
          !splittedUrl.includes('auth') &&
          ev.urlAfterRedirects !== '/' &&
          (!splittedUrl.includes('docs') || splittedUrl.indexOf('docs') >= splittedUrl.length - 1)
          && (!splittedUrl.includes('docsold') || splittedUrl.indexOf('docsold') >= splittedUrl.length - 1)


        ) {
          this.layoutService.currentRoute = ev.urlAfterRedirects;
          // NICHT IM DOC VIEWER
          this.layoutService.hideSidebar = false;
          this.authService.setPrmWebAppLastUrl(ev.urlAfterRedirects);
        } else {


          // IM DOC VIEWER
          this.layoutService.hideSidebar = true;
        }
      }
    });
  }


}
