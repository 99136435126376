import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { LayoutService } from '../../shared/services/layout.service';
import { SurveyService } from './survey.service';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.css']
})
export class SurveyComponent {
  allparticipants: PRM.Participant[];
  selectedeventobject: PRM.Event;
  survey: PRM.Survey;


  constructor(public aRoute: ActivatedRoute, private surveyService: SurveyService, public layoutService: LayoutService) {
    aRoute.data.subscribe((data) => {
      this.allparticipants = data.pack.pack.allparticipants;
      this.selectedeventobject = data.pack.selectedeventobject;
      this.survey = this.selectedeventobject.timeblock.surveys[this.selectedeventobject.timeblock.surveys.findIndex(s => s.surveyid === aRoute.snapshot.params.surveyid)];
      const index = data.surveyresult.findIndex(r => r.surveyid === this.survey.surveyid);
      if (index > -1) {
        const surveyresult = data.surveyresult[index];
        for (const comp of this.survey.components) {
          if (surveyresult.value[comp.id] != null) {
            comp.value = surveyresult.value[comp.id];
          }
        }
      }
    });
    this.initialValueForSliders();
  }

  public onChange() {
    const value = {};
    for (const comp of this.survey.components) {
      if (comp.value != null) {
        value[comp.id] = comp.value;
      }
    }
    this.surveyService.sendSurveyResults(this.survey.surveyid, this.survey.timeblockids[0], value);
  }

  private initialValueForSliders() {
    for (const comp of this.survey.components) {
      if (comp.type === 'slider') {
        if (comp.value == null) {
          comp.value = comp.props.minValue;
        }
      }
    }
  }

  public calculatePositionPercentage(min: number, max: number, current: number) {
    if (min < 0) {
      max = max + Math.abs(min);
      current = current + Math.abs(min);
      min = min + Math.abs(min);
    } else if (min > 0) {
      max = max - min;
      current = current - min;
      min = 0;
    }
    const percentage = current * (100 / (max - min));
    return percentage;
  }
}