import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { PackService } from 'src/app/shared/services/PackService';
import { PreptimeService } from '../../shared/PreptimeService';
import { LayoutService } from '../../shared/services/layout.service';

@Component({
  selector: 'app-document-tab-buttons',
  templateUrl: './document-tab-buttons.component.html',
  styleUrls: ['./document-tab-buttons.component.css']
})
export class DocumentTabButtonsComponent implements OnInit {
  @Input() public allparticipants: PRM.Participant[] = null;
  @Input() public aRoute: ActivatedRoute;
  @Input() public timeblockId: string;
  @Input() public selectedEvent: PRM.Event;
  public timerConfig = {};
  public surveys: PRM.Survey[];
  pack: PRM.IpadPackage;
  public startDate: string = "";
  public packTimezone: string = "Europe/Berlin";
  public userTimezone: string = "Europe/Berlin";

  constructor(
    public router: Router,
    public layoutService: LayoutService,
    public preptimeService: PreptimeService,
    public packService: PackService,
  ) {
    this.fetchPackage().then((data) => {
      this.startDate = data.startDate;
      if (data.timezone) this.packTimezone = data.timezone
      if (data.me["timezone"]) this.userTimezone = data.me["timezone"];
      this.pack = data
    });
  }

  ngOnInit(): void {
    this.surveys = this.selectedEvent.timeblock.surveys;
  }

  docsAreLocked() {
    if (!this.preptimeService.hasEventId(this.selectedEvent.id)) {
      return false;
    }
    const prepEnd = new Date(this.preptimeService.getEndtimeForEventId(this.selectedEvent.id).endtime);
    const prepServer = new Date(this.preptimeService.getEndtimeForEventId(this.selectedEvent.id).servertime);
    const seconds = (prepEnd.getTime() - new Date().getTime() - (prepServer.getTime() - new Date().getTime())) / 1000;
    this.timerConfig = { leftTime: seconds };
    return ((prepEnd.getTime() - new Date().getTime() - this.preptimeService.getDiff()) / 1000) < 0;
  }

  async fetchPackage(): Promise<PRM.IpadPackage> {
    return await this.packService.getCurrentPackage();
  }

  isParticipantView() {
    return this.router.url.includes('participant');
  }
}
