import { Component, Input } from '@angular/core';
import { KonvaCanvasComponent } from '../konva-canvas/konva-canvas.component';
import { Pencil } from '../konva-canvas/konva-paintbox/konva-paintbox.component';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-konva-tool-selector',
  templateUrl: './konva-tool-selector.component.html',
  styleUrls: ['./konva-tool-selector.component.css']
})
export class KonvaToolSelectorComponent {
  @Input() public pencil: Pencil;
  @Input() public activePencil: Pencil;
  @Input() public colors;
  @Input() public sizes;
  @Input() public canvas: KonvaCanvasComponent;

  constructor(public layoutService: LayoutService) { }

  thisToolIsActive() {
    return (this.pencil.icon === this.activePencil.icon);
  }
}