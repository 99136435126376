import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirm-delete-video',
  templateUrl: './confirm-delete-video.component.html',
  styleUrls: ['./confirm-delete-video.component.css']
})
export class ConfirmDeleteVideoComponent {

  @Output() resultEvent = new EventEmitter<boolean>()
  public accepted = false;
  public role: string = ""
  constructor(private modalRef: BsModalRef) {
  }

  accept() {
    this.accepted = true;
    this.modalRef.hide();
  }

  deny() {
    this.modalRef.hide();
  }
}