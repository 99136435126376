<div
  class="login-dark pb-5 mb-5"
  style="overflow-y: scroll"
>
  <form
    #authForm="ngForm"
    (ngSubmit)="onSubmit(authForm)"
  >
    <div
      class="form-row"
      style="margin: 10px"
    >
      <div
        class="col-auto text-center"
        style="padding: 5px; opacity: 1; filter: blur(0px)"
      >
        <div class="bg-white border rounded shadow">
          <img
            alt="Profil M"
            src="assets/ProfilM_Logo.png"
            width="100%"
            height="100%"
            style="padding: 10px"
          />
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="row">
        <div
          class="col-12 alert alert-danger"
          *ngIf="err && (err.status === 401 || err.status === 403)"
        >
          <span
            *ngIf="err.status === 401"
            i18n
          >Anfrage wurde nicht akzeptiert.</span>
          <span
            *ngIf="err.status === 403"
            i18n
          >Bitte geben Sie einen gültigen Pin ein</span>
        </div>
        <div
          class="col-12 alert alert-warning"
          *ngIf="this.waitingForLoginValidation"
        >
          <span i18n>Freischaltung bei Moderator angefragt. Bitte warten.</span>
        </div>
        <input
          #pincode
          maxlength="6"
          class="form-control ml-2 mb-4"
          style="
            color: #000;
            background-color: #fff;
            letter-spacing: 10px;
            text-align: center;
            border-radius: 10px;
          "
          placeholder="PIN"
        />
      </div>
      <div class="row p-2">
        <div class="col text-center">
          <div
            class="btn-secondary btn"
            (click)="punschPin(1)"
          >1</div>
        </div>
        <div class="col text-center">
          <div
            class="btn-secondary btn"
            (click)="punschPin(2)"
          >2</div>
        </div>
        <div class="col text-center">
          <div
            class="btn-secondary btn"
            (click)="punschPin(3)"
          >3</div>
        </div>
      </div>
      <div class="row p-2">
        <div class="col text-center">
          <div
            class="btn-secondary btn"
            (click)="punschPin(4)"
          >4</div>
        </div>
        <div class="col text-center">
          <div
            class="btn-secondary btn"
            (click)="punschPin(5)"
          >5</div>
        </div>
        <div class="col text-center">
          <div
            class="btn-secondary btn"
            (click)="punschPin(6)"
          >6</div>
        </div>
      </div>
      <div class="row p-2">
        <div class="col text-center">
          <div
            class="btn-secondary btn"
            (click)="punschPin(7)"
          >7</div>
        </div>
        <div class="col text-center">
          <div
            class="btn-secondary btn"
            (click)="punschPin(8)"
          >8</div>
        </div>
        <div class="col text-center">
          <div
            class="btn-secondary btn"
            (click)="punschPin(9)"
          >9</div>
        </div>
      </div>
      <div class="row p-2">
        <div class="col text-center"></div>
        <div class="col text-center">
          <div
            class="btn-secondary btn"
            (click)="punschPin(0)"
          >0</div>
        </div>
        <div class="col text-center">
          <div
            class="pt-2"
            (click)="punschPin(-1)"
          >
            <i class="ri-delete-back-2-line"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <button
        id="login"
        #login
        i18n
        class="btn btn-primary btn-block"
        type="submit"
        [disabled]="pincode.value.length < 6"
      >
        Login
      </button>
    </div>
  </form>

</div>