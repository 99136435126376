<span *ngIf="layoutService.nameComponents" class="alert alert-info">dayselectiontabs.component.html</span>
<div class="col p-0 position-sticky fixed-top daySelection">
  <div i18n class="text-center">Tag</div>
  <ng-container *ngFor="let d  of this.eventdays">
    <div class="row m-auto activehack"
         [style.backgroundColor]="layoutService.activeColor.backgroundColor"
         [routerLink]="['/',viewForRole,viewForRouter,d.day ]"
         [appLayout]="layoutService.activeColor"
         routerLinkActive="active"
         style="width: 50px;
                height: 50px;

                border-radius: 50%"
    >
      <div class="col align-self-center text-center" style="cursor: pointer">
        {{d.day * 1 + 1}}
      </div>

    </div>
  </ng-container>

</div>

