import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { PackService } from '../services/PackService';
import { LayoutService } from '../services/layout.service';
import Participant = PRM.Participant;

@Injectable()
export class DocumentviewResolver {
  packData: PRM.IpadPackage;
  allparticipants: PRM.IpadParticipant[];
  allDcos: PRM.Document[];
  eventsOnthisDay;
  mergedEventsOnthisDay;
  dimensionsForTimeBlock;
  selectedeventobject: PRM.Event;
  selectedDocObjectx: PRM.Document;

  constructor(protected packService: PackService, public layoutService: LayoutService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.packData = await this.packService.getCurrentPackage();
    this.allparticipants = this.packData.allparticipants;
    this.allDcos = [];
    this.eventsOnthisDay = [];
    this.mergedEventsOnthisDay = [];
    this.dimensionsForTimeBlock = [];
    this.selectedeventobject = null;
    this.selectedDocObjectx = null;
    let day = null;

    if (route.params.day) {
      day = route.params.day;
    }
    if (route.params.selectedevent) {
      day = route.parent.params.day;
    }

    if (day === null) {
      this.packData.eventdays.forEach((eventday) => {
        this.mergeForDay(eventday.day, route);
      });
    } else {
      this.mergeForDay(day, route);
    }

    const ret = {
      selectedDay: day,
      pack: this.packData,
      selectedeventobject: this.selectedeventobject,
      dayEvents: this.mergedEventsOnthisDay,
      allDocuments: this.allDcos,
      selecteddoc: route.params.selecteddoc,
      selectedDocObject: this.selectedDocObjectx,
      page: route.params.page,
      dimensionsForTimeBlock: this.dimensionsForTimeBlock
    };
    return ret;
  }

  mergeForDay(day, route) {
    const index = this.packData.eventdays.findIndex(ed => ed.day + '' === day + '');
    if (index > -1) {
      this.eventsOnthisDay = this.packData.eventdays[index].events;
      let currentEventIndex = 1;
      this.eventsOnthisDay.forEach((ev: PRM.Event) => {
        if (!ev.timeblock) {
          return;
        }
        ev.participantId = [];
        ev.participantId.push(ev.resourceId);
        for (let i = currentEventIndex; i < this.eventsOnthisDay.length; i++) {
          const ev2: PRM.Event = this.eventsOnthisDay[i];
          if (ev.team === ev2.team && ev.start === ev2.start && ev.end === ev2.end) {
            if (ev.participantId.findIndex(id => id === ev2.resourceId) === -1) {
              ev.participantId.push(ev2.resourceId);
            }
            if (ev2.timeblock.documents) {
              if (!ev.timeblock.documents) {
                ev.timeblock.documents = [];
              }
              ev2.timeblock.documents.forEach((ev2doc) => {
                const docIndex = ev.timeblock.documents.findIndex(doc => doc._id === ev2doc._id);
                if (docIndex === -1) {
                  ev.timeblock.documents.push(ev2doc);
                } else if (docIndex > -1 && ev2doc.metadata.participantInstanceEnabled === true) {
                  if (ev.timeblock.documents.findIndex(doc => doc === ev2doc) === -1) {
                    const ev2PartiIndex = this.allparticipants.findIndex(parti => parti.id === ev2.resourceId);
                    if (ev2PartiIndex > -1) {
                      ev2doc.metadata.participantObject = this.allparticipants[ev2PartiIndex];
                    }
                    ev.timeblock.documents.push(ev2doc);
                  }
                }
              });
            }
            ev2._id = 'null';
          }
        }
        currentEventIndex++;
        if (ev._id !== 'null') {
          this.mergedEventsOnthisDay.push(ev);
        }
      });
      this.mergedEventsOnthisDay.forEach((ev) => {
        if (ev._id + '' === '' + route.params.selectedevent) {
          ev.documents.forEach(doc => {
            if (doc.metadata.participantInstanceEnabled && ev.resourceId != "-1") {
              const partiIndex = this.allparticipants.findIndex(parti => parti.id === ev.resourceId);
              if (partiIndex > -1) {
                doc.metadata.participantObject = this.allparticipants[partiIndex]
              }
            }
          });
          this.selectedeventobject = ev;
          if (ev.documents) {
            this.allDcos = this.allDcos.concat(ev.documents);
          }
          if (ev.timeblock.documents) {

            for (const id of ev.participantId) {
              const indexParticipant = this.allparticipants.findIndex(participant => participant.id === id);
              for (const document of ev.timeblock.documents) {
                if (document.metadata.participantInstanceEnabled === true) {
                  if (!document.metadata.participantObject) {
                    document.metadata.participantObject = this.allparticipants[indexParticipant];
                  }
                }
              }
            }

            this.allDcos = this.allDcos.concat(ev.timeblock.documents);
          }
          this.allDcos = this.allDcos.filter((value) => {
            return value != null;
          });
          this.allDcos.forEach((doc) => {
            if (doc._id + '' === route.params.selecteddoc + '') {
              this.selectedDocObjectx = doc;
            }
          });

          this.packData.dimensions.forEach((dimension) => {
            const timeBlockDimension = { ...dimension };
            timeBlockDimension.anker = [];
            dimension.anker.forEach((anker) => {
              if (anker.timeblockids.indexOf(ev.timeblock._id) !== -1) {
                timeBlockDimension.anker.push(anker);
              }
            });

            if (timeBlockDimension.anker.length > 0) {
              this.dimensionsForTimeBlock.push(timeBlockDimension);
            }
          });
        }
      });
    }
  }

}
