import { EventEmitter, Injectable, Output } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

export interface ColorPair {
  backgroundColor: any;
  foregroundColor: any;
}

@Injectable({
  providedIn: "root",
})
export class LayoutService {
  currentRoute: string;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() public onHideSidebar: EventEmitter<any> = new EventEmitter();
  private hideSidebarBol = false;
  public headerHeight = "74px";
  public headerHeightInt = 74;
  public fieberKurveGesamtCellWidth: number = 100;
  public fieberKurveDimensinCellWidth: number = 250;
  //

  public activeColor: ColorPair = {
    backgroundColor: "#f6a600",
    foregroundColor: "white",
  };

  public baseColorButtons: ColorPair = {
    backgroundColor: "#33588c",
    foregroundColor: "white",
  };

  public baseColorSurface: ColorPair = {
    backgroundColor: "#232e55",
    foregroundColor: "white",
  };

  //
  public gray: ColorPair = {
    backgroundColor: "#d3d5dd",
    foregroundColor: "black",
  };
  public textColor: ColorPair = {
    backgroundColor: "transparent",
    foregroundColor: "#001835",
  };
  //
  public gray50: ColorPair = {
    backgroundColor: "#e9eaee",
    foregroundColor: "black",
  };
  public gray25: ColorPair = {
    backgroundColor: "#f4f4f6",
    foregroundColor: "#001835",
  };
  public textColor50: ColorPair = {
    backgroundColor: "transparent",
    foregroundColor: "#7f8b9a",
  };
  public nameComponents = false; // NUR EINE DEBUGGING OPTION FÜRS FRONTEND
  // public scoreColor1: ColorPair = {
  //   backgroundColor: "#00A23F",
  //   foregroundColor: "white",
  // };
  // public scoreColor2: ColorPair = {
  //   backgroundColor: "#919E2D",
  //   foregroundColor: "white",
  // };
  // public scoreColor3: ColorPair = {
  //   backgroundColor: "#E79B37",
  //   foregroundColor: "white",
  // };
  // public scoreColor4: ColorPair = {
  //   backgroundColor: "#F56020",
  //   foregroundColor: "white",
  // };
  // public scoreColor5: ColorPair = {
  //   backgroundColor: "#E8151C",
  //   foregroundColor: "white",
  // };

  public scoreColor1: ColorPair = {
    backgroundColor: "#234F7E",
    foregroundColor: "white",
  };
  public scoreColor2: ColorPair = {
    backgroundColor: "#234F7E",
    foregroundColor: "white",
  };
  public scoreColor3: ColorPair = {
    backgroundColor: "#234F7E",
    foregroundColor: "white",
  };
  public scoreColor4: ColorPair = {
    backgroundColor: "#234F7E",
    foregroundColor: "white",
  };
  public scoreColor5: ColorPair = {
    backgroundColor: "#234F7E",
    foregroundColor: "white",
  };
  public scoreColors: ColorPair[] = [
    this.scoreColor1,
    this.scoreColor2,
    this.scoreColor3,
    this.scoreColor4,
    this.scoreColor5,
  ];

  constructor(private router: Router) {
    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd && ev.urlAfterRedirects) {
        this.currentRoute = ev.urlAfterRedirects;
      }
    });
  }

  public set hideSidebar(newval) {
    setTimeout(() => {
      this.hideSidebarBol = newval;
    });
    if (newval) {
      this.onHideSidebar.emit();
    }
  }

  public get hideSidebar() {
    return this.hideSidebarBol;
  }
}
