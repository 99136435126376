import { Directive, ElementRef } from '@angular/core';
import { WcagService } from './wcag.service';

@Directive({
  selector: '[appWcag]'
})
export class WcagDirective {
  constructor(el: ElementRef, wcagService: WcagService) {
    const bg = el.nativeElement.style.backgroundColor.toString();
    const bgRGB = bg.substring(4, bg.length - 1).split(' ').join('').split(',');
    bgRGB.forEach((e, i) => {
      bgRGB[i] = e * 1;
    });
  }
}