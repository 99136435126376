import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LayoutService } from '../services/layout.service';
import { PreptimeService } from '../PreptimeService';

@Component({
  selector: 'app-preptime-countdown',
  templateUrl: './preptime-countdown.component.html',
  styleUrls: ['./preptime-countdown.component.css']
})

export class PreptimeCountdownComponent implements OnInit {
  @Input() endTime: string;
  public isVisible = true;
  @Input() url: string;
  @Input() emitDone: EventEmitter<string>;
  @Output() done: EventEmitter<string> = new EventEmitter<string>();
  timerConfig: object = {};

  constructor(public router: Router, public layoutService: LayoutService, public preptimeService: PreptimeService) { }

  ngOnInit(): void {
    const l = (new Date(this.endTime).getTime() - new Date().getTime() - this.preptimeService.getDiff()) / 1000;
    console.log("TimeLeft" + l)
    this.timerConfig = { leftTime: l };
    this.emitDone.subscribe(doneUrl => {
      if (this.url === doneUrl) {
        if (this.router.url.includes(this.url)) {
          this.router.navigate(this.url.split('/'));
        }
      }
    });
  }

  public countdownEvent($event: any) {
    if ($event.action === 'done') {
      setTimeout(() => {
        this.done.emit('done');
        if (this.router.url.includes(this.url)) {
          this.router.navigate(this.url.split('/'));
        }
      }, 1500);
    }
  }
}