import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, Renderer2, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UploadState } from 'ngx-uploadx';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { SocketService } from '../SocketService';
import { LayoutService } from '../services/layout.service';
import { VideoRecordingService } from '../video-recording.service';
import { ConfirmDeleteVideoComponent } from './../confirm-delete-video/confirm-delete-video.component';
import { VideoService } from './video.service';


export interface UploadedFileMetadata {
  mimeType: string;
  size: number;
  videolength?: number;
  name: string;
  lastModified: number;
  ipadid: string;
  participantpin: string;
  timeblockid: string;
  videotype: string;
}
export interface FfmpegStatusMessage {
  conversionPercent: number;
  filemeta: UploadedFileMetadata;
  status?: string
}

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css'],
  providers: [ToastrService]
})
export class VideoComponent implements AfterViewInit {
  @ViewChild('recording') recording: ElementRef;
  @ViewChild('authRequestModal') authRequestModal: TemplateRef<any>;
  @ViewChild('addCommentModal') addCommentModal: TemplateRef<any>;
  pack: PRM.IpadPackage;
  realPack: any;
  allparticipants: PRM.Participant[];
  selectedeventobject: PRM.ParticipantEvent;
  participantVideo: PRM.ParticipantVideo;
  recorderState: string;
  isRecorded = false;
  ratingComment = {};
  videoType: string;
  uploadProgress: number;
  conversionProgress: number;

  uploadStatus: string = "idle";
  conversionStatus: string = "idle";

  modalRef?: BsModalRef;
  permitAuth: boolean;
  public recordingSrc = ""

  constructor(
    public toastr: ToastrService,
    private rd: Renderer2,
    public aRoute: ActivatedRoute,
    private service: VideoService,
    public authService: AuthService,
    public http: HttpClient,
    public socketService: SocketService,
    public layoutService: LayoutService,
    public modalService: BsModalService,
    public videoRecordingService: VideoRecordingService
  ) {

    this.socketService.socket.on('videouploadcomplete', this.uploadCompleted.bind(this));
    this.socketService.socket.on('secondDeviceAuthRequest', this.secondDeviceAuthRequest.bind(this));

    this.socketService.socket.on('ffmpegstatus', this.onConversionStatus.bind(this));
    this.socketService.socket.on('receivedVideoFeedback', this.receivedFeedback.bind(this));
    this.socketService.socket.on('participantVideoDeleted', this.videoDeleted.bind(this))

    aRoute.data.subscribe((data) => {
      this.pack = data.pack.pack;
      this.realPack = data.pack;
      this.allparticipants = data.pack.pack.allparticipants;
      if (this.allparticipants.findIndex(p => p.id === data.pack.pack.me.id) > -1) {
        this.allparticipants.splice(this.allparticipants.findIndex(p => p.id === data.pack.pack.me.id), 1);
      }
      this.selectedeventobject = data.pack.selectedeventobject;
      const allvideos = data.videos.videos;
      const index = allvideos.findIndex(
        v =>
          v.metadata.timeblockid === this.selectedeventobject.tbid &&
          v.metadata.videotype.toLowerCase() === aRoute.snapshot.url[1].path
      );
      if (index > -1) {
        this.participantVideo = allvideos[index];
        this.isRecorded = true;

        this.recorderState = 'inactive';
        if (this.participantVideo.feedbacks) {
          for (const feedback of this.participantVideo.feedbacks) {
            if (feedback.ratingComment) {
              this.ratingComment[feedback.fromparticipantid] = feedback.ratingComment;
            } else {
              this.ratingComment[feedback.fromparticipantid] = '';
            }
          }
        }
      }
    });
    const urlsegment = this.aRoute.snapshot.url[1].path.toLowerCase();
    if (urlsegment === 'selfestimation') {
      this.videoType = 'selfEstimation';
    } else if (urlsegment === 'selfrecording') {
      this.videoType = 'selfRecording';
    }
    this.onStateChanged(this.service.events);
  }

  async ngAfterViewInit() {
    if (this.participantVideo) {
      this.recordingSrc = this.service.playVideo(this.participantVideo._id)
    }
  }

  async videoDeleted(videoInfo: any) {
    console.log("videoInfo", videoInfo)
    console.log("this.participantVideo._id", this.participantVideo)

    if (this.participantVideo._id == videoInfo._id) {
      this.participantVideo = undefined;
      //this.deleteRecording();
      this.toastr.warning($localize`:@@videoRemovedWaring:Video wurde entfernt!`)
    }
  }

  getQrCodeUrl() {
    let selectedObjectId = this.realPack.selectedeventobject._id;
    return window.location.protocol + "//" + window.location.host + window.location.pathname + "#/connect2nddevice?id=" + selectedObjectId;
  }

  openConfirmDeleteRecord() {
    const modal = this.modalService.show(ConfirmDeleteVideoComponent, { backdrop: false, focus: false, keyboard: true })
    modal.onHide.subscribe(m => {
      if (modal.content.accepted) {
        this.deleteRecording();
      }
    })
  }

  deleteRecording() {
    if (this.participantVideo) {
      this.service.deleteParticipantVideo(this.participantVideo._id);
      const reqBody = {
        participantTbid: this.selectedeventobject.tbid,
        packageId: this.selectedeventobject.packageid,
        participantPin: this.authService.getAuthData().pin,
        firstname: this.pack.me.firstname,
        lastname: this.pack.me.lastname,
        device: (this.pack.secondDevice) ? 'second_device' : 'primary_device',
        action: 'deleted_recording'
      };
      this.videoRecordingService.createVideoRecordingHistoryEntry(reqBody).then();
    }
    this.isRecorded = false;
    this.recorderState = undefined;
    this.participantVideo = undefined;
  }

  async changeInvitationStatusForParticipant(event, peerparticipantid: string) {
    this.participantVideo = await this.service.inviteAndDeleteParticipantForFeedback(this.participantVideo._id, peerparticipantid, event.target.checked);
  }

  public onStateChanged(stateEvent: Observable<UploadState>): void {
    stateEvent.subscribe(state => {
      this.uploadProgress = state.progress;
      this.uploadStatus = state.status;
      if (state.status === 'complete') {
        this.uploadProgress = undefined;

      }
    });
  }

  public onConversionStatus(data: FfmpegStatusMessage) {
    if (data.filemeta.timeblockid == this.selectedeventobject.tbid) {
      this.conversionProgress = Math.min(data.conversionPercent, 1);
      this.conversionStatus = 'converting';
      console.log("Update Conversion Status: " + this.conversionStatus + this.conversionProgress + "%")
      if (data.status == "conversiondone") {
        this.conversionStatus = 'idle';
        this.isRecorded = true;
      }
    }
  }

  public async collectComment(message: string) {
    this.modalService.hide(this.modalRef.id)
    await this.service.updateInvitationMessage(this.participantVideo._id, message);
    delay(100);
    window.location.reload();
  }

  public closeCommentModal() {
    this.modalService.hide(this.modalRef.id);
    delay(100);
    window.location.reload();
  }

  public submitModal(check: boolean) {
    this.modalService.onHide.emit(check)
    // #Logging1
    const reqBody = {
      participantTbid: this.selectedeventobject.tbid,
      packageId: this.selectedeventobject.packageid,
      participantPin: this.authService.getAuthData().pin,
      firstname: this.pack.me.firstname,
      lastname: this.pack.me.lastname,
      device: 'primary_device',
      action: (check) ? 'second_device_accepted' : 'second_device_declined'
    };

    this.videoRecordingService.createVideoRecordingHistoryEntry(reqBody).then();
    this.modalService.hide(this.modalRef.id)
  }

  private secondDeviceAuthRequest(usefuldata: any, callback: any) {
    this.modalRef = this.modalService.show(this.authRequestModal, { ignoreBackdropClick: true, keyboard: false });
    this.modalService.onHide.subscribe(result => {
      if (result === true || result === false) {
        callback(result)
      }
    });
  }

  private uploadCompleted(data) {
    this.participantVideo = data;
    if (data.uploader == null || data.uploader.role == "participant") {
      this.modalRef = this.modalService.show(this.addCommentModal, { ignoreBackdropClick: true, keyboard: false });
    }
  }

  private receivedFeedback(data: { videoid: string, peerparticipantid: string, ratingComment: string }) {
    if (this.participantVideo._id === data.videoid) {
      this.ratingComment[data.peerparticipantid] = data.ratingComment;
    }
  }
}