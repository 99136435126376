import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { AuthService } from '../../auth/auth.service';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-introduction-list',
  templateUrl: './introduction-list.component.html',
  styleUrls: ['./introduction-list.component.css']
})
export class IntroductionListComponent {
  introDocs: PRM.Document[];

  constructor(private aRoute: ActivatedRoute, public authService: AuthService, public layoutService: LayoutService) {
    aRoute.data.subscribe((data) => {
      this.introDocs = data.pack.introDocs;
    });
  }

  public getRouterlinkToSingleDocView(doc: PRM.IpadDocument) {
    if (doc.contentType === 'application/pdf') {
      const r = [doc._id, '0'];
      if (doc.metadata.participantInstanceEnabled) {
        r.push(doc.metadata.participantObject.id);
      }
      return r;
    } else if (doc.contentType === 'video/mp4') {
      return ['video', doc._id];
    }
  }
}