import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from '../app.component';
import { SurveyComponent } from '../parti-view/survey/survey.component';
import { ParticipantsummaryComponent } from '../participantsummary/participantsummary.component';
import { DocumentContentRendererSingleDocoldComponent } from '../shared/document-content-renderer-single-docold/document-content-renderer-single-docold.component';
import { DocumentContentRendererSingleVideoComponent } from '../shared/document-content-renderer-single-video/document-content-renderer-single-video.component';
import { IntroductionListComponent } from '../shared/introduction-list/introduction-list.component';
import { IsSavedGuard } from '../shared/is-saved.guard';
import { IsModeratorGuard } from '../shared/ismoderator.guard';
import { DocumentviewResolver } from '../shared/resolver/DocumentviewResolver';
import { IntroductionViewResolver } from '../shared/resolver/IntroductionViewResolver';
import { ObservationFormResolver } from '../shared/resolver/ObservationFormResolver';
import { PackContentResolver } from '../shared/resolver/PackContentResolver';
import { PageAnnotationResolver } from '../shared/resolver/PageAnnotationResolver';
import { ParticipantSummaryNewResolver } from '../shared/resolver/ParticipantSummaryNewResolver';
import { RatingResolver } from '../shared/resolver/RatingResolver';
import { SurveyResultResolver } from '../shared/resolver/SurveyResultResolver';
import { VideoResolver } from '../shared/resolver/VideoResolver';
import { ModPrepTimeResolver } from '../shared/resolver/mod-prep-time.resolver';
import { ResultconferenceGuard } from '../shared/resultconference.guard';
import { DocumentContentRendererComponent } from './document-content-renderer/document-content-renderer.component';
import { DocumentViewComponent } from './document-view/document-view.component';
import { ObserverConferenceComponent } from './observer-conference/observer-conference.component';
import { ObserverViewComponent } from './observer-view.component';
import { PreptimesComponent } from './preptimes/preptimes.component';
import { RatingScaleComponent } from './rating-scale/rating-scale.component';
import { SettingsComponent } from './settings/settings.component';
import { UploadVideosComponent } from './upload-videos/upload-videos.component';
import { VideoShareComponent } from './video-share/video-share.component';

const routes: Routes = [
  {
    path: '',
    component: ObserverViewComponent,
    canActivateChild: [IsSavedGuard],
    children: [
      {
        path: 'introduction',
        component: AppComponent,
        children: [
          {
            path: 'docs',
            component: IntroductionListComponent,
            resolve: {
              pack: PackContentResolver
            },
          },
          {
            /* INTRO DOCS **/
            path: 'docs/video/:selectedvideo',
            component: DocumentContentRendererSingleVideoComponent,
            resolve: {
              video: VideoResolver
            },
          },
          {
            path: 'docs/:selecteddoc/:page',
            component: DocumentContentRendererSingleDocoldComponent,
            resolve: {
              pack: IntroductionViewResolver
            }
          }
        ]
      },
      {
        path: 'share',
        component: VideoShareComponent,
        resolve: {
          pack: PackContentResolver
        },
      },
      {
        path: 'settings',
        component: SettingsComponent,
        resolve: {
          pack: PackContentResolver
        },
      },
      {
        path: 'conference',
        canActivate: [ResultconferenceGuard],
        component: ObserverConferenceComponent,
        children: [
          {
            path: ':participantid',
            component: ParticipantsummaryComponent,
            resolve: {
              participantSummary: ParticipantSummaryNewResolver,
              annotations: PageAnnotationResolver
            },
          },
          {
            path: ':participantid/documentview/:day',
            component: ParticipantsummaryComponent,
            resolve: {
              pack: DocumentviewResolver,
              participantSummary: ParticipantSummaryNewResolver
            },
            children: [
              {
                path: ':selectedevent/docs/:selecteddoc/:page/:userid',
                component: DocumentContentRendererSingleDocoldComponent,
                resolve: {
                  pack: PageAnnotationResolver
                },
              },
            ]
          }


        ],
        resolve: {
          pack: PackContentResolver
        },

      },
      {
        path: 'preptimes',
        canActivate: [IsModeratorGuard],
        component: PreptimesComponent,
        resolve: {
          pack: ModPrepTimeResolver
        },
      },
      {
        path: 'videoupload',
        component: UploadVideosComponent,
        resolve: {
          pack: PackContentResolver
        },
      },
      {
        path: 'documentview',
        redirectTo: 'documentview/0',
        pathMatch: 'full'
      },
      {
        path: 'documentview/:day',
        component: DocumentViewComponent,
        resolve: {
          pack: DocumentviewResolver
        },

        children: [
          {
            path: ':selectedevent',
            redirectTo: ':selectedevent/docs',
            pathMatch: 'full'
          },
          {
            path: ':selectedevent/docs',
            component: DocumentContentRendererComponent,
            resolve: {
              pack: DocumentviewResolver
            },
          },
          {
            path: ':selectedevent/rating/:userid/:timeblockid',
            component: RatingScaleComponent,
            resolve: {
              pack: DocumentviewResolver,
              //rating: RatingResolver,
              //observationForm: ObservationFormResolver
            },
          },
          {
            path: ':selectedevent/docs/video/:selectedvideo',
            component: DocumentContentRendererSingleVideoComponent,
            resolve: {
              video: VideoResolver
            },
          },
          {
            path: ':selectedevent/docs/:selecteddoc/:page/',
            component: DocumentContentRendererSingleDocoldComponent,
            resolve: {
              pack: DocumentviewResolver

            },
            data: {
              debug: true,
              hideSidebar: true
            }
          },
          {
            path: ':selectedevent/docs/:selecteddoc/:page',
            component: DocumentContentRendererSingleDocoldComponent,
            resolve: {
              pack: PageAnnotationResolver,

            },

            data: {
              hideSidebar: true
            }
          },
          {
            path: ':selectedevent/docsold/:selecteddoc/:page/:userid',
            component: DocumentContentRendererSingleDocoldComponent,
            resolve: {
              pack: PageAnnotationResolver
            },
          },
          {
            path: ':selectedevent/docs/:selecteddoc/:page/:userid',
            component: DocumentContentRendererSingleDocoldComponent,
            resolve: {
              pack: PageAnnotationResolver
            },
          },
          {
            path: ':selectedevent/survey/:surveyid',
            component: SurveyComponent,
            resolve: {
              pack: DocumentviewResolver,
              surveyresult: SurveyResultResolver
            }
          }
        ]
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ObserverRoutingModule {

}
