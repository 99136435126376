
<ng-template #noDocs>
  <h5
    class="text-left col-12 px-4 my-4"
    i18n
  >Keine Dokumente</h5>
</ng-template>

<div class=" row text-left">
  <div class="col-12  ">
    <app-document-tab-buttons
      [selectedEvent]="selectedeventobject"
      [allparticipants]="allparticipants"
      [aRoute]="aRoute"
    ></app-document-tab-buttons>

    <app-document-list
      [documents]="documents"
      [selectedEvent]="selectedeventobject"
    ></app-document-list>
  </div>
