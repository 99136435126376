<i class="ri-checkbox-blank-circle-fill d-inline-block "
   [style.color]="active? layoutService.activeColor.backgroundColor : layoutService.baseColorButtons.backgroundColor"

   style="font-size: 2.5em !important;width: 1em;"

>
  <i [class]="icon" [style.color]="layoutService.baseColorButtons.foregroundColor"
     [style.opacity]="disabled? 0.3 : 1"
     style="font-size: 0.5em !important;position: relative; left:-1.5em; top:-0.3em"


  ></i>


</i>

