import { Component, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PRM } from '@reflact/prmacweb';
import { Chart, ChartOptions } from 'chart.js';
import { AuthService } from '../auth/auth.service';
import { LayoutService } from '../shared/services/layout.service';
import Event = PRM.Event;

@Component({
  selector: 'app-participantsummary',
  templateUrl: './participantsummary.component.html',
  styleUrls: ['./participantsummary.component.css'],
})

export class ParticipantsummaryComponent {
  public events: any;
  public showFieber = false;
  public ratingSummary: any;
  public mycomments: any;
  public counter = Array;
  public totalRating: any;
  public participant: PRM.IpadParticipant;
  public pack: PRM.IpadPackage;

  constructor(
    public aRoute: ActivatedRoute,
    public authService: AuthService,
    public layoutService: LayoutService
  ) {
    aRoute.data.subscribe((data) => {
      this.pack = data.participantSummary.pack;

      this.pack.allparticipants.forEach((p) => {
        if (p.id === aRoute.snapshot.params.participantid) {
          this.participant = p;
        }
      });

      const uniqueTBEvents: PRM.Event[] = []
      for (const ev of (data.participantSummary.events as PRM.Event[])) {
        if (uniqueTBEvents.find(uev => uev.tbid == ev.tbid) == null) {
          uniqueTBEvents.push(ev)
        }

      }
      this.events = uniqueTBEvents
      this.ratingSummary = data.participantSummary.ratingSummary;
      this.totalRating = data.participantSummary.totalRating;
      this.mycomments = this.annotationsTodocArray(data.participantSummary.docannotations);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.showFieber) {
      const canvasContainer = document.getElementById('canvasContainer') as HTMLTableCellElement;
      let canvasHeight = 80 + (this.ratingSummary.dimensions.length) * 40;
      canvasContainer.innerHTML = '<canvas id="chartCanvas" width="800" height="' + canvasHeight + '" style="background-color:#232E55; padding-top: 30px; width: 100%;"></canvas>';
      new Chart('chartCanvas', {
        type: 'line',
        data: {
          datasets: this.getchartData(),
        },
        options: this.getChartconfig()
      });
    }
  }

  public getScaleForStep1(scale: PRM.ScaleEntry[]) {
    let min = 1000
    let max = -1000
    for (const entry of scale) {
      min = Math.min(min, entry.value)
      max = Math.max(max, entry.value)
    }
    min = Math.floor(min)
    max = Math.ceil(max)
    const scaleValues: number[] = []
    while (min <= max) {
      scaleValues.push(min++);
    }
    return scaleValues
  }

  public getDimensionTotalValue(dimension: PRM.Dimension) {
    let sumvalues = 0;
    let countValues = 0;
    if (this.getScaleValue('-1', dimension.id) !== '') {
      return parseFloat(this.getScaleValue('-1', dimension.id));
    } else {
      this.events.forEach((ev: Event) => {
        const sv = this.getScaleValue(ev.timeblock._id, dimension.id);
        if (sv !== '') {
          sumvalues += parseFloat(sv);
          countValues++;
        }
      }, this);

      if (countValues !== 0) {
        return sumvalues / countValues;
      }
    }
  }

  public getchartData() {
    const resultData = [];
    this.pack.dimensions.forEach((dimension, index) => {
      let xValue = this.getDimensionTotalValue(dimension);
      if (Number(xValue)) {
        xValue = Number(xValue);
      }
      resultData.push({
        x: xValue,
        y: this.pack.dimensions.length - index + 0.5,
      });
    }, this);

    resultData.reverse();
    const r = [
      {
        pointBorderWidth: 5,
        pointRadius: 10,
        borderColor: '#fff',
        pointBackgroundColor: (context) => {
          return this.layoutService.scoreColors[0].backgroundColor;
        },
        fill: false,
        data: resultData,
        lineTension: 0,
      },
    ];
    return r;
  }

  public getScaleSorted() {
    const scale = Object.assign([], this.pack.scale);
    scale.sort((a, b) => {
      return a.value - b.value;
    });
    return scale;
  }

  public getChartconfig() {
    const minval = parseFloat(this.getMinAndMaxValue().min);
    const maxval = parseFloat(this.getMinAndMaxValue().max);
    const dimensionscount = this.ratingSummary.dimensions.length;

    const chartOptions: ChartOptions = {
      layout: {},
      datasets: {
        line: {
          spanGaps: true,
        }
      },
      clip: { left: 30, right: 30, top: 30, bottom: 30 },
      responsive: true,
      maintainAspectRatio: false,
      animation: {
        duration: 0,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false
        }
      },

      scales: {

        x:
        {

          type: 'linear',
          position: 'top',
          grid: {
            offset: true,
            color: this.layoutService.baseColorButtons.backgroundColor,
            tickLength: 0
          },
          ticks: {
            color: "#FFF",
            stepSize: 1,
            font: {
              size: 14,

            },

            display: true,
          },
          min: minval,
          max: maxval,
        },
        y:
        {
          grid: {
            tickLength: 0,
            color: 'rgba(20,100,10,0)',
          },
          ticks: {
            display: false,
          },
          min: 1.5,
          max: dimensionscount + 1
        },

      }
    };
    return chartOptions;
  }

  public annotationsTodocArray(annotations) {
    const r = [];
    annotations.forEach((annot) => {
      if (r[annot.docid] === undefined) {
        r[annot.docid] = [];
      }
      r[annot.docid][annot.page] = annot.value;
    });
    return r;
  }

  public getScaleValue(timeBlockId: string, dimensionId: string) {
    let scaleValue = '';
    const rating = this.ratingSummary.ratings.find(
      (rating) =>
        rating.timeblockid === timeBlockId && rating.dimensionid === dimensionId
    );
    if (rating) {
      if (rating.total != null) {
        scaleValue = rating.total;
      } else if (rating.hostTotal != null) {
        scaleValue = rating.hostTotal;
      }
    }
    return scaleValue;
  }

  public getIsDisabled(timeblockId: string, dimension: PRM.Dimension) {
    let foundOne = false;
    dimension.anker.forEach((anker) => {
      if (anker.timeblockids.includes(timeblockId)) {
        foundOne = true;
      }
    });
    return foundOne;
  }

  public getMinAndMaxValue() {
    const value = {
      min: undefined,
      max: undefined,
    };
    this.ratingSummary.scale.forEach((scale) => {
      if (scale.value < value.min || value.min === undefined) {
        value.min = scale.value;
      }
      if (scale.value > value.max || value.max === undefined) {
        value.max = scale.value;
      }
    });
    return value;
  }
}