import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public router: Router, private authService: AuthService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const webAppToken = this.authService.getWebAppToken();
    if (webAppToken == null || request.url.indexOf('vimeo.com') > 0) {
      return next.handle(request);
    }
    const authReq = request.clone({ setHeaders: { webapptoken: webAppToken } });
    return next.handle(authReq);
  }
}