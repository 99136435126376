import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { VideoService } from '../video/video.service';

@Injectable()
export class ParticipantVideoResolver {
  constructor(private service: VideoService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const allvideos = await this.service.getAllParticipantVideos();
    return allvideos;
  }
}
