<div appHeightWatcher>
    <div *ngFor="let array of participantIdToPrepEvents | keyvalue">
        <b>{{participantIdToParticipantname.get(array.key)}}</b>
        <ul class="list-group">
            <li
                *ngFor="let event of array.value"
                class="list-group-item"
            >
                <span>{{ timeblockIdToTimeblockname.get(event.tbid)}} ({{event.start.split(':').splice(0, 2).join(':')}}
                    Uhr
                    bis {{event.end.split(':').splice(0, 2).join(':')}} Uhr) </span>
                <ui-switch
                    #switch
                    id="switch"
                    class="float-right"
                    [checked]="prepEntryMap.has(event.id + '' + array.key) && compareDate(prepEntryMap.get(event.id + '' + array.key).end)"
                    (change)="switchChanged($event, array.key, event.id)"
                ></ui-switch>
                <label
                    class="float-right"
                    style="margin-top: 0.5%; margin-right: 1%"
                    for="switch"
                >Sperre</label>
                <span
                    class="badge badge-pill badge-info prepTimeBadge"
                    [ngClass]="{'badge-danger': switch.checked}"
                    *ngIf="prepEntryMap.has(event.id + '' + array.key)"
                > Vorbereitungszeit bis {{prepEntryMap.get(event.id + '' + array.key).end | date: 'h:mm'}}Uhr</span>
                <span
                    class="badge badge-pill badge-info prepTimeBadge"
                    *ngIf="!prepEntryMap.has(event.id + '' + array.key)"
                    i18n
                > Vorbereitungszeit noch nicht begonnen</span>
            </li>
        </ul>
        <br>
        <br>
    </div>
</div>