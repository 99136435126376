<ng-template
    #content
    let-modal
>
    <div
        class="modal-header"
        i18n="@@timezoneGuessHeader"
    >Info</div>
    <div class="modal-body">
        <p i18n="@@timezoneGuessDescription">
            Die Zeitzone Ihres Browsers "{{this.guessedTimezone}}" weicht von der in der AC-Suite für Sie hinterlegte
            Zeitzone "{{this.userTimezone}}" ab.
            Möchten Sie in die Zeitzone "{{this.guessedTimezone}}" wechseln? Ihr Zeitplan wird dann in dieser Zeitzone
            angezeigt.
        </p>
    </div>
    <div class="modal-footer">
        <button
            class="btn btn-primary"
            i18n="@@timezoneGuessUpdate"
            (click)="this.saveTimezone()"
        >
            Zeitzone übernehmen
        </button>
        <button
            class="btn btn-secondary"
            (click)="this.cancel()"
            i18n="@@timezoneGuessClose"
        >
            Abbrechen
        </button>
    </div>
</ng-template>