import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PackService } from '../services/PackService';

@Injectable()
export class PackContentResolver {

  constructor(private packService: PackService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const pack = await this.packService.getCurrentPackage();

    return pack;
  }
}

