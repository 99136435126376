import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-seconddeviceauth',
  templateUrl: './seconddeviceauth.component.html',
  styleUrls: ['./seconddeviceauth.component.css']
})

export class SeconddeviceAuthComponent implements OnInit, OnDestroy {
  public subscriptions: Subscription[] = []
  public isIOSChrome: boolean = false
  public viewState: string = "initial";

  constructor(private route: ActivatedRoute, private httpClient: HttpClient, public router: Router, public authService: AuthService) {
    if (/CriOS/i.test(navigator.userAgent) &&
      /iphone|ipod|ipad/i.test(navigator.userAgent)) {
      this.isIOSChrome = true;
    }
  }

  ngOnInit() {
    this.subscriptions.push(this.route.queryParams
      .subscribe(params => {
        this.requestAuth(params.id)
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe())
  }

  async requestAuth(peid) {

    const result = await firstValueFrom(this.httpClient.get<{ status: "ok" | string, token: string, tbid: string }>("/api/seconddevice/auth/" + peid));

    if (result.status == "forbidden") {
      this.viewState = "forbidden";
    }
    if (result.status == "ok") {
      this.viewState = "ok";
      this.authService.setWebAppToken(result.token)
      this.router.navigate(["/", "participant", "2nddevice", result.tbid])
    }
  }
}