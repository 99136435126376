import { Component, Input } from '@angular/core';
import { LayoutService } from '../services/layout.service';

@Component({
  selector: 'app-ri-button',
  templateUrl: './ri-button.component.html',
  styleUrls: ['./ri-button.component.css']
})
export class RiButtonComponent {
  @Input() public icon;
  @Input() public disabled = false;
  @Input() public active = false;

  constructor(public layoutService: LayoutService) { }
}