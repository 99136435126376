<div class="row">
    <div class="col"><span i18n="@@moduploadTBInfo">Tag {{ev?.day + 1}} - {{ev?.start.split(':').splice(0,
            2).join(':')}}
            bis {{ev?.end.split(':').splice(0, 2).join(':')}} - {{ ev?.timeblock.name}}</span>
    </div>

    <div class="col-auto">
        <input
            #fileUpload
            class="ms-2 d-none"
            type="file"
            [uploadx]="uploadxOptions"
            (state)="onStateChanged($event)"
        />
        <ng-container *ngIf="this.videoid.length == 0">

            <span
                *ngIf="this.isUploading"
                i18n="@@moduploadUploading"
            >
                Hochladen...{{(uploadProgress / 100) | percent: "1.2-2"}}
            </span>
            <div
                class="btn btn-normal btn-sm"
                i18n="@@moduploadUpload"
                (click)="fileUpload.click()"
            >Hochladen</div>
        </ng-container>

        <ng-container *ngIf="this.videoid.length > 5">
            <div
                class="btn btn-normal btn-sm mr-2"
                i18n="@@moduploadPreview"
                (click)="openModal(videoPreviewModal)"
            >Vorschau</div>
            <div
                class="btn btn-danger btn-sm"
                i18n="@@moduploadDelete"
                (click)="openModal(confirmDeleteModal)"
            >Löschen</div>
        </ng-container>
    </div>
</div>
<ng-content></ng-content>

<ng-template #videoPreviewModal>
    <div class="modal-header">
        {{this.participant?.firstname}} {{this.participant?.lastname}}
    </div>
    <div class="modal-body">
        <video
            *ngIf="this.videoid.length > 5"
            controls
            width="100%"
            [src]="videoService.playVideo(this.videoid)"
            controlsList="nodownload"
            disablePictureInPicture
        ></video>
    </div>
</ng-template>

<ng-template #confirmDeleteModal>
    <div class="modal-header">
        <h4 i18n="@@moduploadConfirmDelete">Löschen bestätigen</h4>
    </div>
    <div class="modal-body">
        <div i18n="@@moduploadConfirmDeleteText">
            Wollen Sie die Aufnahme wirklich löschen? Diese Entscheidung ist <b>unwiderruflich</b>.
            <br>
            <b>{{participant.firstname}} {{participant.lastname}}</b>
            <br>
            Tag {{ev?.day + 1}} - {{ev?.start.split(':').splice(0, 2).join(':')}}
            bis {{ev?.end.split(':').splice(0, 2).join(':')}} - {{ ev?.timeblock.name}}
        </div>
        <video
            *ngIf="this.videoid.length > 5"
            controls
            width="100%"
            [src]="videoService.playVideo(this.videoid)"
            controlsList="
            nodownload"
            disablePictureInPicture
        ></video>
    </div>
    <div class="modal-footer">
        <div
            class="btn btn-secondary"
            (click)="this.modalRef.hide()"
            i18n="@@globalNo"
        >Nein</div>
        <div
            class="btn btn-primary"
            (click)="deleteVideo()"
            i18n="@@globalYes"
        >Ja</div>
    </div>
</ng-template>