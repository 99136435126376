import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ModPrepTimeService } from 'src/app/observer-view/preptimes/mod-prep-time.service';
import { PackService } from '../services/PackService';

@Injectable({
  providedIn: 'root'
})
export class ModPrepTimeResolver {
  constructor(private service: ModPrepTimeService, private packService: PackService) { }
  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    const preptimes = await this.service.getPrepTimesAsModerator();
    const pack = await this.packService.getCurrentPackage();
    const prepevents = await this.service.getPrepTimeEvents();
    return { pack, preptimes, prepevents };
  }
}