<div
    class="modal-body"
    i18n="@@confirmDeleteVideo"
>Wollen Sie die Aufnahme wirklich löschen? Diese Entscheidung ist <b>unwiderruflich</b>.
</div>
<div class="modal-footer">
    <div
        class="btn btn-secondary"
        (click)="deny()"
        i18n="@@globalNo"
    >Nein</div>
    <div
        class="btn btn-primary"
        (click)="accept()"
        i18n="@@globalYes"
    >Ja</div>
</div>