import { PRM } from '@reflact/prmacweb';
import IpadPackage = PRM.IpadPackage;
import Participant = PRM.Participant;
import Observer = PRM.Observer;
import ScaleEntry = PRM.ScaleEntry;

export class Pack {

  public ipadPack: IpadPackage;

  constructor(ipadPackage: IpadPackage) {
    this.ipadPack = ipadPackage;
    this.parsePack();
  }

  public getIPadPack(): IpadPackage {
    return this.ipadPack;
  }

  private parsePack(): void {
    const tnStr = this.ipadPack.lang == "de" ? "TN " : "P ";
    for (let i = 0; i < this.ipadPack.allparticipants.length; i++) {
      const p: PRM.IpadParticipant = this.ipadPack.allparticipants[i];
      p.fullNameWithIndex = tnStr + (i + 1) + " " + p.firstname + " " + p.lastname
    }
  }
}
